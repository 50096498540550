import React from "react";

const HiddenEye = ({ className }) => {
  return (
    <svg className={className || ""} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9552 6.29388C13.3451 6.10773 12.6927 6 12 6C6.90909 6 4 11.8182 4 11.8182C4 11.8182 4.71676 13.2517 6.06135 14.6956M18.0062 9.01385C19.3072 10.4326 20 11.8182 20 11.8182C20 11.8182 17.0909 17.6364 12 17.6364C11.1769 17.6364 10.4108 17.4843 9.70489 17.2293" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path className="fill" d="M13.2265 9.97332C13.4688 9.60891 13.9712 9.50472 14.2599 9.83352C14.3757 9.96534 14.48 10.1074 14.5716 10.2583C14.8006 10.6359 14.9437 11.0594 14.9907 11.4986C15.0376 11.9379 14.9872 12.382 14.8431 12.7995C14.6989 13.2171 14.4646 13.5978 14.1567 13.9145C13.8489 14.2312 13.475 14.4763 13.0617 14.6322C12.6485 14.7881 12.2059 14.8511 11.7656 14.8167C11.3252 14.7823 10.8978 14.6513 10.5138 14.433C10.3604 14.3458 10.2154 14.2456 10.0803 14.1336C9.74348 13.8543 9.83335 13.3491 10.1907 13.0966L10.3279 12.9997C10.6095 12.8007 10.997 12.8849 11.2968 13.0553V13.0553C11.4785 13.1586 11.6807 13.2206 11.8891 13.2369C12.0974 13.2531 12.3068 13.2233 12.5023 13.1496C12.6979 13.0758 12.8747 12.9599 13.0204 12.81C13.1661 12.6601 13.2769 12.48 13.3451 12.2825C13.4133 12.0849 13.4372 11.8748 13.415 11.667C13.3928 11.4592 13.3251 11.2588 13.2167 11.0801V11.0801C13.0378 10.7853 12.9426 10.4003 13.1335 10.1131L13.2265 9.97332Z" fill="#2C2C2C"/>
      <path d="M5.99999 17.9308L17.9308 5.99999" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path className="fill" d="M9.79578 12.041C9.36041 12.0851 8.96105 11.7629 9.03192 11.3311C9.0651 11.1289 9.11895 10.93 9.19305 10.7376C9.3625 10.2974 9.63308 9.90331 9.98295 9.58703C10.3328 9.27076 10.7521 9.0412 11.2071 8.91688C11.406 8.86252 11.6093 8.82895 11.8138 8.81627C12.2506 8.7892 12.5309 9.21894 12.4433 9.64768L12.4181 9.77111C12.3445 10.1317 11.9798 10.3485 11.6248 10.4455V10.4455C11.4096 10.5043 11.2112 10.6129 11.0456 10.7626C10.8801 10.9122 10.7521 11.0987 10.6719 11.3069V11.3069C10.5397 11.6504 10.2873 11.9913 9.92113 12.0284L9.79578 12.041Z" fill="#2C2C2C"/>
    </svg>
  );
};

export default HiddenEye;
