import React from "react";

const EmployeeIcon = ({ color = "var(--second-text)" }) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Iconly/Light/2 User">
      <g id="2 User">
        <path id="Stroke 1" d="M12.5154 8.18476C13.8811 8.18476 14.9881 7.07776 14.9881 5.71201C14.9881 4.34701 13.8811 3.23926 12.5154 3.23926" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 3" d="M13.6523 10.8721C14.0603 10.8998 14.4653 10.9583 14.8651 11.0468C15.4193 11.1571 16.0868 11.3843 16.3238 11.8816C16.4753 12.2003 16.4753 12.5716 16.3238 12.8903C16.0876 13.3876 15.4193 13.6148 14.8651 13.7288" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 5" fillRule="evenodd" clipRule="evenodd" d="M7.69374 11.4053C10.4612 11.4053 12.8252 11.8245 12.8252 13.4993C12.8252 15.1748 10.4762 15.6083 7.69374 15.6083C4.92624 15.6083 2.56299 15.1898 2.56299 13.5143C2.56299 11.8388 4.91124 11.4053 7.69374 11.4053Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 7" fillRule="evenodd" clipRule="evenodd" d="M7.69376 9.01425C5.86826 9.01425 4.40576 7.551 4.40576 5.7255C4.40576 3.90075 5.86826 2.4375 7.69376 2.4375C9.51926 2.4375 10.9825 3.90075 10.9825 5.7255C10.9825 7.551 9.51926 9.01425 7.69376 9.01425Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
  </svg>
);

export default EmployeeIcon;
