import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { getNameAbbreviation, wrapAvatarUrl } from "helpers/common";

import AvatarComponent from "shared/AvatarComponent";
import StyledTooltip from "shared/StyledTooltip";

import Location from "assets/iconComponents/Location";
import Website from "assets/iconComponents/Website";
import LinkedInIcon from "assets/iconComponents/LinkedInIcon";
import EmployeeIcon from "assets/iconComponents/EmployeeIcon";
import CalendarIcon from "assets/iconComponents/CalendarIcon";
import FolderIcon from "assets/iconComponents/FolderIcon";

import "./styles.scss";

const getDetailsItems = drawerData => {
  const companyLocation = drawerData?.data?.locations?.find(it => it?.isPrimary) || drawerData?.data?.locations?.[0];
  const industry = drawerData?.data?.industries?.find(it => it.isPrimary) || drawerData?.data?.industries?.[0];
  return ([
    { icon: <Location color="var(--second-text)" />, value: companyLocation?.address },
    { icon: <CalendarIcon color="var(--second-text)" />, label: "Foundation Year", value: drawerData?.data?.foundationYear || "- - -" },
    { icon: <Website color="var(--second-text)" />, value: drawerData?.data?.website || "- - -", isUrl: !!drawerData?.data?.website },
    { icon: <EmployeeIcon color="var(--second-text)" />, label: "Employee Count", value: drawerData?.data?.employeeCount?.number || "- - -" },
    { icon: <LinkedInIcon color="var(--second-text)" />, value: drawerData?.data?.linkedinUrl || "- - -", isUrl: !!drawerData?.data?.linkedinUrl },
    { icon: <FolderIcon color="var(--second-text)" />, label: "Industry", value: industry?.name || "- - -" }
  ])
};

const CompanyHero = ({ isLoading }) => {
  const drawerData = useSelector(state => state.detailsDrawer);
  const [detailsItems, setDetailsItems] = useState(getDetailsItems());
  const [heroImgError, setHeroImgError] = useState(false);
  const [heroImgLoaded, setHeroImgLoaded] = useState(false);
  
  useEffect(() => {
    if (drawerData?.data) {
      setDetailsItems(getDetailsItems(drawerData));
    }
  }, [drawerData]);

  useEffect(() => {
    return () => {
      setHeroImgError(false);
      setHeroImgLoaded(false);
    }
  }, []);

  return (
    <div className="company-hero-wrapper">
      <div className="hero-box">
        <AvatarComponent
          url={drawerData?.data?.logo || wrapAvatarUrl(drawerData?.data?.website)}
          shortName={getNameAbbreviation(drawerData?.data?.companyName)}
          isLoading={isLoading}
        />
        <div className="default-hero" />
        {drawerData.isCompany && drawerData?.data?.heroImage && !heroImgError && (
          <div className={`hero-img-box ${heroImgLoaded ? "show" : ""}`}>
            <img
              src={drawerData?.data?.heroImage}
              alt=""
              onError={() => setHeroImgError(true)}
              onLoad={() => setHeroImgLoaded(true)}
            />
          </div>
        )}
      </div>
      {isLoading ? (
        <>
          <div className="company-name-loader" />
          <div className="company-details-box">
            {new Array(6).fill(0).map((_, i) => (
              <div key={i} className="company-details-item">
                <div className="icon-loader" />
                <div className="value-loader" />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="company-name">{drawerData?.data?.companyName}</div>
          <div className="company-details-box">
            {detailsItems?.map((val, i) => (
              <div key={i} className="company-details-item">
                <StyledTooltip title={val.label}>
                  <span>
                    {val.icon}
                  </span>
                </StyledTooltip>
                <StyledTooltip isOverflowContent title={val.value}>
                  <div
                    className={`details-item-value ${val.isUrl ? "link-item" : ""}`}
                    onClick={() => {
                      if (val.isUrl) {
                        window.open(val.value, "_blank");
                      }
                    }}
                  >
                    {val.value}
                  </div>
                </StyledTooltip>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
};

export default CompanyHero;
