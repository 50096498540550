import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";

import { AdvancedSearchService } from "services/AdvancedSearchService";
import { convertFiltersForRequest, jsonToBase64 } from "helpers/searchHelper";
import { setSavedSearches } from "redux/actions/filters";

import StyledTextField from "shared/StyledTextField";
import CustomCheckbox from "shared/CustomCheckbox";
import ModalWrapper from "shared/ModalWrapper";
import SubmitButton, { submitStatusValues } from "shared/SubmitButton";

import "./styles.scss";

const SaveSearchModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const requestFilter = useSelector(state => state.filters.searchRequestData.filter);
  const savedSearches = useSelector(state => state.filters.savedSearches);

  const [saveSearchLoading, setSaveSearchLoading] = useState(false);
  const [name, setName] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [errors, setErrors] = useState({});
  const [saveStatus, setSaveStatus] = useState(submitStatusValues.default);

  const closeModal = () => {
    onClose();
    setName("");
    setIsFavorite(false);
    setErrors({});
    setSaveStatus(submitStatusValues.default);
  };

  const handleSaveSearch = async () => {
    const searchQuery = jsonToBase64(convertFiltersForRequest(requestFilter));

    if (savedSearches?.some(val => val.name.trim() === name.trim())) {
      return setErrors({ ...errors, name: "Search with the same name already exists." });
    }

    setSaveSearchLoading(true);
    setSaveStatus(submitStatusValues.loading);
    try {
      await AdvancedSearchService.saveSearch({
        name,
        searchQuery,
        isFavorite
      });
      const searches = await AdvancedSearchService.getSavedSearches();
      dispatch(setSavedSearches(searches));
      setSaveStatus(submitStatusValues.success);

      setTimeout(() => {
        closeModal();
      }, 2000)
    } catch (e) {
      console.log(e);
      setErrors({ ...errors, res: e?.response?.data?.message || "Something went wrong" });
      setSaveStatus(submitStatusValues.default);
    } finally {
      setSaveSearchLoading(false);
    }
  };

  const saveText = {
    [submitStatusValues.default]: "Save search",
    [submitStatusValues.loading]: "Saving",
    [submitStatusValues.success]: "Saved",
  }

  return (
    <ModalWrapper
      title="Save a new search"
      isLoading={saveSearchLoading}
      open={open}
      onClose={closeModal}
    >
      <div className="save-search-form-wrapper">
        <StyledTextField
          label="Search name"
          placeholder="Type name here..."
          value={name}
          onChange={e => {
            setName(e.target.value);
            setErrors({ ...errors, name: "" });
          }}
          error={errors.name}
        />
        <CustomCheckbox
          label="Pin saved search"
          checked={isFavorite}
          onChange={val => setIsFavorite(val)}
        />
        {errors?.res && <div className="error-box">{errors?.res}</div>}
        <div className="btn-box">
          <Button onClick={onClose} className="cancel-btn">
            Cancel
          </Button>
          <SubmitButton
            className="save-btn"
            status={saveStatus}
            onClick={handleSaveSearch}
            disabled={!name.trim()}
          >
            {saveText[saveStatus]}
          </SubmitButton>
        </div>
      </div>
    </ModalWrapper>
  )
};

export default SaveSearchModal;
