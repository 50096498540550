import React from "react";

const Location = ({ color = "var(--main-text)" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.17932 7.79385C3.19013 4.61341 5.77714 2.04392 8.95758 2.05472C12.138 2.06553 14.7075 4.65254 14.6967 7.83298V7.8982C14.6576 9.96559 13.5032 11.8765 12.088 13.3699C11.2787 14.2104 10.3748 14.9545 9.39454 15.5873C9.13242 15.8141 8.74362 15.8141 8.4815 15.5873C7.02012 14.6361 5.73752 13.4352 4.69236 12.0395C3.76083 10.8224 3.23195 9.34518 3.17932 7.81342L3.17932 7.79385Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <ellipse cx="8.93806" cy="7.90425" rx="1.84565" ry="1.84565" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default Location;
