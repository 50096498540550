import React from "react";

const CompanyFilterIcon = ({ color = "var(--second-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6723_49657)">
      <path d="M10.35 7.03516L16.2967 8.21641C16.776 8.31766 17.1 8.72266 17.1 9.20191V16.0869C17.1 16.6472 16.6477 17.0994 16.0875 17.0994H10.0125C10.2015 17.0994 10.35 16.9509 10.35 16.7619V16.4244H16.0875C16.2697 16.4244 16.425 16.2759 16.425 16.0869V9.20191C16.425 9.04666 16.317 8.90491 16.1617 8.87116L10.35 7.72366V7.03516Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M14.0622 10.3496C14.2512 10.3496 14.3997 10.4981 14.3997 10.6871C14.3997 10.8761 14.2512 11.0246 14.0622 11.0246H12.7122C12.5232 11.0246 12.3747 10.8761 12.3747 10.6871C12.3747 10.4981 12.5232 10.3496 12.7122 10.3496H14.0622Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M14.0622 12.375C14.2512 12.375 14.3997 12.5235 14.3997 12.7125C14.3997 12.9015 14.2512 13.05 14.0622 13.05H12.7122C12.5232 13.05 12.3747 12.9015 12.3747 12.7125C12.3747 12.5235 12.5232 12.375 12.7122 12.375H14.0622Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M14.0622 14.3994C14.2512 14.3994 14.3997 14.5479 14.3997 14.7369C14.3997 14.9259 14.2512 15.0744 14.0622 15.0744H12.7122C12.5232 15.0744 12.3747 14.9259 12.3747 14.7369C12.3747 14.5479 12.5232 14.3994 12.7122 14.3994H14.0622Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M10.3503 16.762C10.3503 16.951 10.2018 17.0995 10.0128 17.0995C9.82382 17.0995 9.67532 16.951 9.67532 16.762V16.4245V7.31201C9.67532 7.21076 9.72257 7.11626 9.79682 7.04876C9.87782 6.98801 9.97907 6.96101 10.0803 6.98126L10.3503 7.03526V7.72376V16.4245V16.762Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M9.67469 16.4248V16.7623C9.67469 16.9513 9.82319 17.0998 10.0122 17.0998H7.31219C7.50119 17.0998 7.64969 16.9513 7.64969 16.7623V16.4248H9.67469Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M7.98717 4.27441C8.17617 4.27441 8.32467 4.42292 8.32467 4.61192C8.32467 4.80092 8.17617 4.94942 7.98717 4.94942H6.63717C6.44817 4.94942 6.29967 4.80092 6.29967 4.61192C6.29967 4.42292 6.44817 4.27441 6.63717 4.27441H7.98717Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M8.32467 6.63731C8.32467 6.82631 8.17617 6.97481 7.98717 6.97481H6.63717C6.44817 6.97481 6.29967 6.82631 6.29967 6.63731C6.29967 6.44831 6.44817 6.2998 6.63717 6.2998H7.98717C8.17617 6.2998 8.32467 6.44831 8.32467 6.63731Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M7.98717 8.32422C8.17617 8.32422 8.32467 8.47272 8.32467 8.66171C8.32467 8.85071 8.17617 8.99921 7.98717 8.99921H6.63717C6.44817 8.99921 6.29967 8.85071 6.29967 8.66171C6.29967 8.47272 6.44817 8.32422 6.63717 8.32422H7.98717Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M7.98717 10.3496C8.17617 10.3496 8.32467 10.4981 8.32467 10.6871C8.32467 10.8761 8.17617 11.0246 7.98717 11.0246H6.63717C6.44817 11.0246 6.29967 10.8761 6.29967 10.6871C6.29967 10.4981 6.44817 10.3496 6.63717 10.3496H7.98717Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M4.94999 10.6871C4.94999 10.8761 4.80149 11.0246 4.61249 11.0246H3.26249C3.07349 11.0246 2.92499 10.8761 2.92499 10.6871C2.92499 10.4981 3.07349 10.3496 3.26249 10.3496H4.61249C4.80149 10.3496 4.94999 10.4981 4.94999 10.6871Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M4.61249 4.27441C4.80149 4.27441 4.94999 4.42292 4.94999 4.61192C4.94999 4.80092 4.80149 4.94942 4.61249 4.94942H3.26249C3.07349 4.94942 2.92499 4.80092 2.92499 4.61192C2.92499 4.42292 3.07349 4.27441 3.26249 4.27441H4.61249Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M4.61249 6.2998C4.80149 6.2998 4.94999 6.44831 4.94999 6.63731C4.94999 6.82631 4.80149 6.97481 4.61249 6.97481H3.26249C3.07349 6.97481 2.92499 6.82631 2.92499 6.63731C2.92499 6.44831 3.07349 6.2998 3.26249 6.2998H4.61249Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M4.61249 8.32422C4.80149 8.32422 4.94999 8.47272 4.94999 8.66171C4.94999 8.85071 4.80149 8.99921 4.61249 8.99921H3.26249C3.07349 8.99921 2.92499 8.85071 2.92499 8.66171C2.92499 8.47272 3.07349 8.32422 3.26249 8.32422H4.61249Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M6.97531 13.3875C6.97531 13.1985 6.82006 13.05 6.63781 13.05H4.61281C4.42381 13.05 4.27531 13.1985 4.27531 13.3875V16.425H3.60031V13.3875C3.60031 12.8273 4.05256 12.375 4.61281 12.375H6.63781C7.19806 12.375 7.65031 12.8273 7.65031 13.3875V16.425H6.97531V13.3875Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M4.27531 16.4248H6.97531H7.65031V16.7623C7.65031 16.9513 7.50181 17.0998 7.31281 17.0998H3.93781C3.74881 17.0998 3.60031 16.9513 3.60031 16.7623V16.4248H4.27531Z" fill={color} stroke={color} strokeWidth="0.6"/>
      <path d="M2.08124 1.03463L9.49949 2.16188C9.99224 2.24288 10.35 2.66138 10.35 3.16088V7.03538L10.08 6.98138C9.97875 6.96113 9.87749 6.98812 9.7965 7.04887C9.72225 7.11637 9.67499 7.21088 9.67499 7.31213V3.16088C9.67499 2.99213 9.55349 2.85038 9.39149 2.82338L1.97324 1.70288C1.95299 1.69613 1.93274 1.69613 1.91249 1.69613C1.83149 1.69613 1.75724 1.72313 1.69649 1.77713C1.61549 1.84463 1.57499 1.93238 1.57499 2.03363V16.0871C1.57499 16.2761 1.73024 16.4246 1.91249 16.4246H3.59999V16.7621C3.59999 16.9511 3.74849 17.0996 3.93749 17.0996H1.91249C1.35224 17.0996 0.899994 16.6474 0.899994 16.0871V2.03363C0.899994 1.73663 1.02824 1.45313 1.25774 1.26413C1.48724 1.06838 1.78424 0.987376 2.08124 1.03463Z" fill={color} stroke={color} strokeWidth="0.6"/>
    </g>
    <defs>
      <clipPath id="clip0_6723_49657">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default CompanyFilterIcon;
