import React, { useState } from "react";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import { searchTypes } from "utils/searchFilters";
import { companyTableColumns, contactTableColumns, getRowOptions } from "utils/tableColumns";
import { getContactName, downloadFile } from "helpers/common";
import { AdvancedSearchService } from "services/AdvancedSearchService";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { useAnimateItemHook } from "hooks/animateItem";
import { getMessageDelay } from "helpers/chatHelper";

import CustomTable from "shared/CustomTable";
import TagsManagerPopover from "shared/TagsManagerPopover";

import Logo from "assets/iconComponents/Logo";
import RefreshIcon from "assets/iconComponents/RefreshIcon";
import TypingJSON from "assets/lottie/typingLoader.json";

import "./styles.scss";

const MessageItem = ({ onRefresh, data, currentChatHistory }) => {
  const [styles] = useAnimateItemHook(getMessageDelay(currentChatHistory, data));
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.me.user);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openTagPopover, setOpenTagPopover] = useState(null);
  const [tagItemsIds, setTagItemsIds] = useState([]);

  const isMe = data?.type === "user";

  const handleExport = async (itemsIds) => {
    const item = data?.content?.data?.data?.find(val => [val.contactId, val.companyId].includes(itemsIds[0]));
    const itemType = data?.content?.meta?.["$type"] === searchTypes.contact ? "contacts" : "companies";
    const name = data?.content?.meta?.["$type"] === searchTypes.contact ? getContactName(item) : item?.companyName;
    const res = await AdvancedSearchService.exportCsv(itemType, itemsIds);
    await downloadFile(res, itemsIds?.length > 1 ? itemType : name);
  };

  const handleTag = (itemsIds, currentTarget) => {
    setOpenTagPopover(currentTarget);
    setTagItemsIds(itemsIds);
  };

  const actionEvents = {
    "export": handleExport,
    "tag": handleTag
  };

  const emptySearchLabel = {
    [searchTypes.contact]: "Unfortunately, no contacts were found for your request.",
    [searchTypes.company]: "Unfortunately, no companies were found for your request.",
    "undefined": "Unfortunately, there are no search results for your request."
  };
  
  return (
    <div
      style={styles}
      className={`message-item-wrapper ${isMe ? "my-message" : ""} ${!!data?.error ? "error" : ""}`}
    >
      {isMe ? (
        <div className="message-item-head">
          <div className="default-avatar">
            {userInfo?.firstName && userInfo?.lastName ? `${userInfo?.firstName[0]}${userInfo?.lastName[0]}` : userInfo?.email?.[0]}
          </div>
          <div className="message-text-content">
            <div className="message-title">You</div>
            <div className="message-body">{data?.content}</div>
          </div>
        </div>
      ) : (
        <>
          <div className="message-item-head">
            <Logo className="logo" />
            {data?.loading ? (
              <Lottie
                className="loader"
                loop
                autoplay
                animationData={TypingJSON}
              />
            ) : (
              <>
                <div className="message-text-content">
                  <div className="message-title">First Intel</div>
                  {!!data?.error && <div className="message-body">{data?.error}</div>}
                  {!!data?.content?.message && (
                    <div className="message-body">
                      <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                        {data?.content?.message}
                      </Markdown>
                    </div>
                  )}
                  {!data?.content?.data?.data && !data?.error && !data?.content?.message && (
                    <div className="message-body">
                      I can't provide information for your request, please try rephrasing it.
                    </div>
                  )}
                  {data?.content?.data?.data && !data?.content?.data?.data?.length && !data?.error && !data?.content?.message && (
                    <div className="message-body">
                      {emptySearchLabel[`${data?.content?.meta?.["$type"]}`]}
                    </div>
                  )}
                </div>
                {!!data?.error && (
                  <Button className="regenerate-btn" onClick={onRefresh}>
                    <RefreshIcon />
                    Regenerate response
                  </Button>
                )}
              </>
            )}
          </div>
          {!!data?.content?.data?.data?.length && data?.content?.meta?.["$type"] && (
            <>
              <CustomTable
                columns={data?.content?.meta?.["$type"] === searchTypes.contact ? contactTableColumns : companyTableColumns}
                data={data?.content?.data}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                itemIdKey={data?.content?.meta?.["$type"] === searchTypes.contact ? "contactId" : "companyId"}
                popoverOptions={getRowOptions(data?.content?.meta?.["$type"])?.map(val => ({ ...val, action: actionEvents[val.value] }))}
                onRowClick={({ companyId, contactId }) => {
                  if (companyId || contactId) {
                    dispatch(setDrawerState({
                      data: {},
                      mainRequestFinished: false,
                      open: true,
                      id: companyId || contactId,
                      isCompany: !!companyId
                    }));
                  }
                }}
              />
              <TagsManagerPopover
                state={openTagPopover}
                setState={setOpenTagPopover}
                tagType={data?.content?.meta?.["$type"]}
                itemId={tagItemsIds[0]}
              />
            </>
          )}
        </>
      )}
    </div>
  )
};

export default MessageItem;
