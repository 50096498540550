import React from "react";

const ExportIcon = ({ color = "var(--primary-color)" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.62826 12.6824L9.62826 3.28125" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.9443 10.3965L9.62824 12.6825L7.31215 10.3965" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3079 6.97656H14.049C15.6653 6.97656 16.9751 8.26403 16.9751 9.85366V13.6669C16.9751 15.2518 15.6685 16.5362 14.0562 16.5362H5.20798C3.59164 16.5362 2.28109 15.2479 2.28109 13.6591L2.28109 9.84507C2.28109 8.26091 3.58846 6.97656 5.20003 6.97656H5.94824" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default ExportIcon;
