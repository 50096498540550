import React from "react";

const StartThreadIcon = ({ color = "var(--primary-inner-color)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="fa-regular:edit">
      <g id="Group">
        <path id="Vector" d="M8.99995 6.50586V8.30917M8.99995 8.30917V10.1125M8.99995 8.30917H10.8M8.99995 8.30917H7.19995" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
        <path id="Vector_2" d="M10.2 13.1177C12.4626 13.1177 13.5942 13.1177 14.2968 12.4132C15 11.7093 15 10.5756 15 8.30883C15 6.04207 15 4.90839 14.2968 4.20449C13.5942 3.5 12.4626 3.5 10.2 3.5H7.8C5.5374 3.5 4.4058 3.5 3.7032 4.20449C3 4.90839 3 6.04207 3 8.30883C3 10.5756 3 11.7093 3.7032 12.4132C4.095 12.8063 4.72 12.9237 5.5 13" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" d="M10.2 13.1177C9.45843 13.1177 8.64123 13.4182 7.89543 13.8059C6.69663 14.4293 6.09723 14.7413 5.80203 14.5423C5.50683 14.3439 5.38783 14.2317 5.50003 13" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      </g>
    </g>
  </svg>
);

export default StartThreadIcon;
