import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { useParams } from "react-router-dom";

import StyledTextField from "shared/StyledTextField";

import TypingJSON from "assets/lottie/typingLoader.json";
import SendIcon from "assets/iconComponents/SendIcon";

import WelcomeState from "./WelcomeState";
import MessageItem from "./MessageItem";
import "./styles.scss";

const ChatInput = React.memo(({ onSubmit, currentChatHistory }) => {
  const { conversationId } = useParams();
  const [fieldValue, setFieldValue] = useState("");
  const disabledSend = !fieldValue.trim() || currentChatHistory?.some(val => val.loading);

  const sendMessage = (str) => {
    onSubmit({ str: (str || fieldValue || "").trim() });
    setFieldValue("");
  };

  useEffect(() => {
    setFieldValue("");
  }, [conversationId]);

  return (
    <StyledTextField
      className="message-text-field"
      multiline
      value={fieldValue}
      onChange={e => setFieldValue(e.target.value)}
      placeholder="Start message First Intel AI"
      onEndBtnClick={() => sendMessage()}
      endBtnLabel={<SendIcon />}
      disabledEndBtn={disabledSend}
      onKeyDown={e => {
        if (!e?.shiftKey && e?.code === "Enter" && !disabledSend) {
          e.preventDefault();
          e.stopPropagation();
          sendMessage();
        }
      }}
    />
  )
})

const AIChat = ({ onSubmit, currentChatHistory, isLoading }) => {

  useEffect(() => {
    const container = document.querySelector(".page-content-scroll-box");
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [currentChatHistory]);

  return (
    <div className="ai-chat-wrapper">
      <div className="messages-box">
        {isLoading ? (
          <Lottie
            className="chat-loader"
            loop
            autoplay
            animationData={TypingJSON}
          />
        ) : (
          <>
            {currentChatHistory?.length ? (
              currentChatHistory?.map((val, i) => (
                <MessageItem
                  key={i}
                  data={val}
                  currentChatHistory={currentChatHistory}
                  onRefresh={() => onSubmit({ str: currentChatHistory?.[i -1]?.content, isRefresh: true })}
                />
              ))
            ) : (
              <WelcomeState onPromptSelect={str => onSubmit({ str })} />
            )}
          </>
        )}
      </div>
      <div className="message-field-box">
        <ChatInput onSubmit={onSubmit} currentChatHistory={currentChatHistory} />
      </div>
    </div>
  )
};

export default AIChat;
