import axios from "axios";
import { AdvancedSearchService } from "./AdvancedSearchService";
import { Tags } from "./Tags";
import { ApiService } from "./ApiService";

export class SearchProfileDetails {
  static async getCompanyExecutives(id) {
    return ApiService._requestAsync({
      url: `/companies/${id}/company-executives`,
      method: "GET"
    });
  }

  static async getCompanyManagementLevels(id) {
    return ApiService._requestAsync({
      url: `/companies/${id}/employee-management-levels`,
      method: "GET"
    });
  }

  static async getCompanyOrgStructure({ id, page, department, managementLevel }) {
    return ApiService._requestAsync({
      url: `/companies/${id}/organization-structure${page ? `?limit=5&page=${page}&department=${encodeURIComponent(department)}&managementLevel=${encodeURIComponent(managementLevel)}` : ""}`,
      method: "GET"
    });
  }

  static async getCompanyEmployee(id, page, name) {
    return ApiService._requestAsync({
      url: `/companies/${id}/employees?limit=18&page=${page}&name=${name || ""}`,
      method: "GET"
    });
  }

  static async getSimilarCompanies(id) {
    return ApiService._requestAsync({
      url: `/companies/${id}/similar`,
      method: "GET"
    });
  }

  static async getCompanyEmployeeByLocation(id) {
    return ApiService._requestAsync({
      url: `/companies/${id}/employees-number-in-region`,
      method: "GET"
    });
  }

  static async getCompanyNews({ q, qInTitle, domains }) {
    return ApiService._requestAsync({
      url: `/news${q ? `?q=${q}` : ""}${qInTitle ? `?qInTitle=${qInTitle}` : ""}${domains ? `&domains=${domains}` : ""}`,
      method: "GET"
    });
  }

  static async getCompanyAvatar(url) {
    return axios({ url: `https://logo.clearbit.com/${url}` }).then(() => `https://logo.clearbit.com/${url}`);
  }

  static async getRevealedDetails(contactId) {
    return ApiService._requestAsync({
      url: `/contacts/${contactId}/reveal-profile-details`,
      method: "GET"
    });
  }
}
