import React, { useState, useRef } from "react";
import { Button } from "@mui/material";

import LegendItem from "shared/LegendItem";
import StyledTooltip from "shared/StyledTooltip";
import DefaultPopover, { PopoverItem } from "shared/DefaultPopover";

import PinnedIcon from "assets/iconComponents/PinnedIcon";
import SearchIcon from "assets/iconComponents/SearchIcon";
import DropDownArrow from "assets/iconComponents/DropDownArrow";
import ThreeDots from "assets/iconComponents/ThreeDots";
import DeleteIcon from "assets/iconComponents/DeleteIcon";

import "./styles.scss";

const SavedSearchItem = ({ search, onClick, isActive, onPin, onDelete }) => {
  const listRef = useRef();
  const [height, setHeight] = useState(0);
  const [openPopover, setOpenPopover] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const filtersCount = search?.items?.reduce((acc, curr) => curr?.items?.length + acc, 0);

  const handleAction = async (action, e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenPopover(null);
    setIsLoading(true);
    await action();
    setIsLoading(false);
  };

  return (
    <div
      className={`saved-search-item ${height ? "opened" : ""} ${isActive ? "active" : ""} ${isLoading ? "loading" : ""}`}
      onClick={onClick}
    >
      <div className="search-item-head">
        {search?.isFavorite ? <PinnedIcon /> : <SearchIcon color="var(--second-text)" />}
        <div className="search-name">
          <StyledTooltip isOverflowContent title={search?.name}>
            <span>{search?.name}</span>
          </StyledTooltip>
        </div>
        <Button
          className="popover-btn"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setOpenPopover(e.currentTarget);
          }}
        >
          <ThreeDots />
        </Button>
        <DefaultPopover state={openPopover} setState={setOpenPopover}>
          <PopoverItem
            label={search?.isFavorite ? "Unpin Search" : "Pin Search"}
            icon={<PinnedIcon />}
            onClick={e => handleAction(onPin, e)}
          />
          <PopoverItem label="Delete Search" icon={<DeleteIcon />} isDelete onClick={e => handleAction(onDelete, e)} />
        </DefaultPopover>
      </div>
      <div
        className="filters-list-head"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setHeight(!!height ? 0 : `${listRef.current.clientHeight}px`)
        }}
      >
        <DropDownArrow className="arrow" />
        {filtersCount} filter{filtersCount > 1 ? "s" : ""}
      </div>
      <div style={{ height }} className="saved-filters-list">
        <div ref={listRef} className="saved-filters-list-content">
          {search?.items?.map(item => (
            <div className="filter-section-box" key={item.key}>
              <LegendItem label={`${item.label}:`} className="section-item" />
              {item?.items.map((filter, index) => (
                <LegendItem key={index} label={filter.value} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
};

export default SavedSearchItem;
