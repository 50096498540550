import React from "react";

const DisplayEye = ({ className }) => {
  return (
    <svg className={className || ""} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 11.8182C4 11.8182 6.90909 6 12 6C17.0909 6 20 11.8182 20 11.8182C20 11.8182 17.0909 17.6364 12 17.6364C6.90909 17.6364 4 11.8182 4 11.8182Z" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 14C13.205 14 14.1818 13.0232 14.1818 11.8182C14.1818 10.6132 13.205 9.63636 12 9.63636C10.795 9.63636 9.81818 10.6132 9.81818 11.8182C9.81818 13.0232 10.795 14 12 14Z" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default DisplayEye;
