import React from "react";

import PersonIcon from "assets/iconComponents/PersonIcon";
import ExploreIcon from "assets/iconComponents/ExploreIcon";
import TrendsIcon from "assets/iconComponents/TrendsIcon";

export const prompts = [
  {
    label: "Explore",
    icon: <ExploreIcon />,
    items: [
      { text: "Create a list of 20 companies with more than 50 employees" },
      { text: "I want to talk to 10 sports industry executives who are currently working in the sports industry, give me a list of them" },
    ]
  },
  {
    label: "Trends",
    icon: <TrendsIcon />,
    items: [
      { text: "Give me contacts with management level no lower than senior" },
      { text: "Find the contacts of 10 clothing industry Owners for potential franchise opportunities and experience sharing." },
    ]
  },
  {
    label: "For you",
    icon: <PersonIcon />,
    items: [
      { text: "I need to reach out to 10 beauty business Owners, please provide a list" },
      { text: "Tell me everything you know about the gaming industry and how I can use it in my newly created music business with investments" },
    ]
  }
];