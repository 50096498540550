import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "./styles.scss";

const StyledTextField = (
  {
    label,
    error,
    value,
    onChange,
    type,
    startIcon,
    endBtnLabel,
    onEndBtnClick,
    disabledEndBtn,
    inputProps,
    className,
    isSkeletonLoader,
    endBtnProps,
    ...props
  }
) => {
  return (
    <div className={`styled-text-field-wrapper ${className || ""}`}>
      {label && (
        <div className="text-field-label">
          {isSkeletonLoader ? <div className="label-loader" /> : label}
        </div>
      )}
      {isSkeletonLoader ? (
        <div className="field-loader">
          <div className="field-loader-content">
            <div className="field-loader-text" />
          </div>
        </div>
      ) : (
        <TextField
          {...props}
          type={type}
          className="text-field"
          value={value || ""}
          onChange={onChange}
          helperText={typeof error === "string" ? error : null}
          error={!!error}
          onKeyDown={e => {
            if (props.onKeyDown) {
              props.onKeyDown(e);
            }
            if (type === "number" && ['e', 'E', '+', '-'].includes(e.key)) {
              e.preventDefault();
            }
          }}
          InputProps={{
            ...(startIcon ? {
              startAdornment: (
                <InputAdornment position="start">
                  {startIcon}
                </InputAdornment>
              )
            } : {}),
            ...(onEndBtnClick ? {
              endAdornment: (
                <InputAdornment position="end" className={disabledEndBtn ? "disabled" : ""}>
                  <IconButton {...(endBtnProps || {})} onClick={onEndBtnClick} disabled={disabledEndBtn}>
                    {endBtnLabel}
                  </IconButton>
                </InputAdornment>
              )
            } : {}),
            ...inputProps
          }}
        />
      )}
    </div>
  )
};

export default StyledTextField;
