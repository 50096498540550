import React from "react";

const TagsFilterIcon = ({ color = "var(--second-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.74868 13.9741L4.30017 16.4112C3.94499 16.5958 3.50742 16.4651 3.31161 16.116V16.116C3.25496 16.0079 3.2244 15.888 3.22237 15.7659V4.96728C3.22237 2.90778 4.62969 2.08398 6.65486 2.08398H11.4123C13.3757 2.08398 14.8448 2.85286 14.8448 4.82998V15.7659C14.8448 15.9607 14.7674 16.1476 14.6296 16.2853C14.4919 16.4231 14.3051 16.5005 14.1102 16.5005C13.986 16.4985 13.8638 16.468 13.7533 16.4112L9.27729 13.9741C9.11235 13.885 8.91362 13.885 8.74868 13.9741Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.27728 6.99266H11.7487" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default TagsFilterIcon;
