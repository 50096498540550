import React, { useRef, useState } from "react";

import { getNameAbbreviation } from "helpers/common";

import AvatarComponent from "shared/AvatarComponent";
import CustomCheckBox from "shared/CustomCheckbox";

import "./styles.scss";
import { Button } from "@mui/material";
import DefaultPopover, { PopoverItem } from "shared/DefaultPopover";
import ThreeDots from "assets/iconComponents/ThreeDots";

const SimpleSearchItem = ({
  avatar,
  name,
  icon,
  subtitle,
  onChange,
  checked,
  onTitleClick,
  onSubtitleClick,
  popoverOptions
}) => {
  const buttonRef = useRef();
  const [open, setOpen] = useState(null);
  return (
    <div className="drawer-simple-search-item-wrapper">
      <div className="search-item-content">
        {onChange && (
          <CustomCheckBox
            checked={checked}
            onChange={onChange}
            isSimpleCheckbox
          />
        )}
        <AvatarComponent url={avatar} shortName={getNameAbbreviation(name)} />
        <div className="info-wrapper">
          <div className="search-item-info">
            <div className={`item-title ${onTitleClick ? "clickable" : ""}`} onClick={onTitleClick}>
              {name}
            </div>
            <div className={`item-subtitle ${onSubtitleClick ? "clickable" : ""}`} onClick={onSubtitleClick}>
              {icon}
              <span>{subtitle}</span>
            </div>
          </div>
        </div>
      </div>
      {!!popoverOptions?.length && (
        <Button className="three-dots-btn" onClick={e => setOpen(e.currentTarget)} ref={buttonRef}>
          <ThreeDots />
        </Button>
      )}
      <DefaultPopover state={open} setState={setOpen}>
        {popoverOptions?.map((val, i) => (
          <PopoverItem
            label={val.label}
            key={i}
            onClick={() => {
              val.action(buttonRef);
              setOpen(null);
            }}
            icon={val.icon}
          />
        ))}
      </DefaultPopover>
    </div>
  );
};

export default SimpleSearchItem;
