import { ApiService } from "./ApiService";

export class InitService {
  static async initAsync() {
    return ApiService._requestAsync({
      url: "/init",
      method: "POST"
    });
  }
}
