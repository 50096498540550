import React from "react";

const DeleteIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4933 7.10156C14.4933 7.10156 14.0861 12.1528 13.8498 14.2806C13.7373 15.2968 13.1096 15.8923 12.0813 15.9111C10.1246 15.9463 8.1656 15.9486 6.2096 15.9073C5.22035 15.8871 4.6031 15.2841 4.49285 14.2858C4.2551 12.1393 3.8501 7.10156 3.8501 7.10156" stroke="var(--error-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.531 4.68066H2.8125" stroke="var(--error-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.0804 4.68023C12.4917 4.68023 11.9847 4.26398 11.8692 3.68723L11.6869 2.77523C11.5744 2.35448 11.1934 2.06348 10.7592 2.06348H7.58443C7.15018 2.06348 6.76918 2.35448 6.65668 2.77523L6.47443 3.68723C6.35893 4.26398 5.85193 4.68023 5.26318 4.68023" stroke="var(--error-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default DeleteIcon;
