import { Auth0Client } from "@auth0/auth0-spa-js";
import { jwtDecode } from "jwt-decode";

export class DataSysAuth0Client {
  static _accessToken = null;

  constructor() {
    DataSysAuth0Client._auth0Client = null;
  }

  static async isAuthenticatedAsync() {
    return DataSysAuth0Client._auth0Client.isAuthenticated();
  }

  static getUserAsync() {
    return DataSysAuth0Client._auth0Client.getUser();
  }

  static logoutAsync() {
    localStorage.clear();
    DataSysAuth0Client._accessToken = null;
    return DataSysAuth0Client._auth0Client.logout({
      returnTo: window.location.origin
    });
  }

  static loginWithRedirectAsync(email) {
    return DataSysAuth0Client._auth0Client.loginWithRedirect({
      redirect_uri: window.location.origin,
      fragment: email ? `signUpEmail=${email}` : ""
    });
  }

  static async initAsync(domain, clientId, audience, onRedirectCallback = () => {}) {
    if (DataSysAuth0Client._auth0Client) {
      return;
    }

    if (!domain || !clientId) {
      throw new Error("Domain or ClientId does not provided");
    }

    const options = {
      domain,
      client_id: clientId,
      redirect_uri: window.location.origin
    };

    if (audience) {
      options.audience = audience;
    }

    DataSysAuth0Client._auth0Client = new Auth0Client(options);

    if (DataSysAuth0Client._hasAuthParams()) {
      try {
        const { appState } = await DataSysAuth0Client._auth0Client.handleRedirectCallback();
        onRedirectCallback(appState);
      } catch (e) {
        DataSysAuth0Client.initAsync(domain, clientId, audience, onRedirectCallback);
      }
    } else {
      await DataSysAuth0Client._auth0Client.checkSession();
    }
  }

  static async getTokenSilentlyAsync() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (DataSysAuth0Client._isTokenValid()) {
        return resolve(this._accessToken);
      }

      try {
        DataSysAuth0Client._accessToken = await this._auth0Client.getTokenSilently();
        return resolve(this._accessToken);
      } catch (e) {
        return reject(e);
      }
    });
  }

  static _isTokenValid() {
    try {
      const accessToken = jwtDecode(this._accessToken);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      return accessToken.exp >= currentTimestamp;
    } catch (e) {
      return false;
    }
  }

  static _hasAuthParams() {
    const CODE_RE = /[?&]code=[^&]+/;
    const ERROR_RE = /[?&]error=[^&]+/;
    // Auth0 verification code interacts with snd email confirmation query params, so we check pathname exactly.
    return (
      (!window.location.pathname.includes("/email-confirm") && CODE_RE.test(window.location.search)) ||
      ERROR_RE.test(window.location.search)
    );
  }
}
