import React, { useState, useEffect, useCallback, useRef } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import { debounce } from "lodash";

import SelectIcon from "assets/iconComponents/SelectIcon";
import CloseIcon from "assets/iconComponents/CloseIcon";

import LegendItem from "shared/LegendItem";

import "./styles.scss";

const FilterAccordion = ({ label, children, selected, onRemoveSelected, isLoading, ...props }) => {
  const selectedRef = useRef();
  const [open, setOpen] = useState(false);
  const [selectedHeight, setSelectedHeight] = useState(0);
  const iconColor = open ? "var(--primary-color)" : "var(--second-text)";

  // eslint-disable-next-line
  const debonceSetSelectedHeight = useCallback(debounce(setSelectedHeight, 300), [])

  useEffect(() => {
    if (!open && !isLoading) {
      if (!selectedHeight) {
        debonceSetSelectedHeight(selected?.length ? `${selectedRef?.current?.clientHeight}px` : 0);
      } else {
        setSelectedHeight(selected?.length ? `${selectedRef?.current?.clientHeight}px` : 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, isLoading]);

  return (
    <div className={`filter-accordion-wrapper ${open && !isLoading ? "opened" : ""}`}>
      {isLoading ? (
        <div className="accordion-skeleton-loader">
          <div className="icon-loader" />
          <div className="label-loader-wrapper">
            <div className="label">{label}</div>
            <div className="label-loader" />
          </div>
          <SelectIcon className="loader-arrow" color="var(--second-text)" />
        </div>
      ) : (
        <>
          <Accordion
            expanded={open}
            onChange={() => {
              setOpen(!open);
              if (!open) {
                setSelectedHeight(0);
                debonceSetSelectedHeight(0);
              } else {
                debonceSetSelectedHeight(`${selectedRef?.current?.clientHeight}px`);
              }
            }}
            className="filter-accordion"
          >
            <AccordionSummary
              expandIcon={<SelectIcon color={iconColor} />}
              className="head-box"
            >
              <props.icon color={iconColor} />
              {label}
              {!!selected?.length && (
                <div className="selected-length-box">
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRemoveSelected({ selected });
                    }}
                  >
                    <CloseIcon color={iconColor} />
                  </Button>
                  {selected?.length}
                </div>
              )}
            </AccordionSummary>
            <AccordionDetails className="filter-accordion-content-box">
              {children}
            </AccordionDetails>
          </Accordion>
        </>
      )}
      <div
        className={`selected-filters-box ${selectedHeight ? "show" : ""}`}
        style={{ height: selectedHeight }}
      >
        <div ref={selectedRef} className="selected-filters-content">
          {selected?.map((val, i) => (
            <React.Fragment key={i}>
              {!!val?.items?.length && (
                <>
                  <div className="selected-items-list">
                    <div className="label">{val.label}:</div>
                    {val?.items?.map((item, index) => (
                      <LegendItem
                        key={index}
                        label={item.value}
                        onRemove={() => onRemoveSelected({ sectionKey: val.sectionKey, key: val.key, item: item.item })}
                      />
                    ))}
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
};

export default FilterAccordion;
