import React from "react";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import "./styles.scss";

const StyledTabs = ({ tabs, value, onChange, isLoading, outline }) => {
  return (
    <div className={`styled-tabs-wrapper ${isLoading ? "loading" : ""} ${outline ? "outline" : ""}`}>
      <TabContext value={value}>
        <TabList onChange={(e, newValue) => onChange(newValue)}>
          {tabs?.map(val => (
            <Tab
              key={val.value}
              label={isLoading ? <div className="label-loader" /> : val.label}
              value={val.value}
              iconPosition="start"
              icon={val.getIcon ? (
                isLoading ? <div className="icon-loader" /> : val.getIcon(value === val.value ? "var(--primary-color)" : "var(--main-text)")
              ) : null}
            />
          ))}
        </TabList>
        {tabs?.map(val => (
          <React.Fragment key={val.value}>
            {val.content && <TabPanel value={val.value}>{val.content}</TabPanel>}
          </React.Fragment>
        ))}
      </TabContext>
    </div>
  )
};

export default StyledTabs;
