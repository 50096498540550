import moment from "moment";

const labelVariants = [
  { label: "Just now", condition: ({ minutesDiff }) => minutesDiff < 1 },
  { label: "A few minutes ago", condition: ({ minutesDiff }) => minutesDiff >= 1 && minutesDiff <= 5 },
  { label: "Today", condition: ({ minutesDiff, daysDiff }) => minutesDiff > 5 && daysDiff === 0 },
  { label: "Yesterday", condition: ({ daysDiff }) => daysDiff === 1 },
  { label: "Last 7 days", condition: ({ daysDiff }) => daysDiff > 0 && daysDiff <= 7 },
  { label: "Last 30 days", condition: ({ daysDiff }) => daysDiff > 7 && daysDiff <= 30 },
]

export const getConversationsList = (data, hasEmptyChat) => {
  const items = [
    ...(hasEmptyChat ? [{
      createdAt: moment().format(),
      id: "new",
      name: "New chat"
    }] : []),
    ...data,
  ];
  const time = { hour: 12, minute: 0, second: 0 };
  const resArr = [];
  items?.forEach(val => {
    const createdAt = moment(val.createdAt).format();
    const daysDiff = moment().set(time).diff(moment(createdAt).set(time), "days");
    const minutesDiff = moment().diff(moment(createdAt), "minutes");

    let sectionLabel = labelVariants?.find(label => label.condition({ minutesDiff, daysDiff }))?.label || moment(createdAt).format("YYYY");

    if (!resArr.some(val => val === sectionLabel)) {
      resArr.push(sectionLabel)
    }
    resArr.push(val);
  });
  return resArr;
}

export const getMessageDelay = (currentChatHistory, data) => {
  if (!currentChatHistory?.some(val => !val.id)) {
    let delay = 0;
    const maxDelay = 400;
    currentChatHistory?.forEach((val, i) => {
      if (val.id === data.id) {
        delay = maxDelay / (currentChatHistory?.length / i);
      }
    });
    return delay;
  }
  if (data?.type === "ai" && !data?.id) {
    return 200;
  }
  return 0;
}
