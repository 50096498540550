import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { initialSearchRequestData, searchTypes, searchTypesOptions } from "utils/searchFilters";
import { Tags } from "services/Tags";
import { companyTableColumns, contactTableColumns, getRowOptions } from "utils/tableColumns";
import { getContactName, downloadFile } from "helpers/common";
import { AdvancedSearchService } from "services/AdvancedSearchService";
import { jsonToBase64 } from "helpers/searchHelper";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { setOpenTaggedSidebar } from "redux/actions/ui";

import StyledTabs from "shared/StyledTabs";
import PageSideBar from "shared/PageSideBar";
import PageContent from "shared/PageContent";
import CustomTable from "shared/CustomTable";
import TagsManagerPopover from "shared/TagsManagerPopover";

import EmptyListIcon from "assets/iconComponents/EmptyListIcon";

import TagItem from "./TagItem";
import "./styles.scss";

const Tagged = () => {
  const dispatch = useDispatch();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(true);
  const [searchType, setSearchType] = useState(searchTypesOptions[0].value);
  const [pinnedTags, setPinnedTags] = useState({
    [searchTypes.company]: JSON.parse(localStorage.getItem("pinnedTags"))?.[searchTypes.company] || [],
    [searchTypes.contact]: JSON.parse(localStorage.getItem("pinnedTags"))?.[searchTypes.contact] || []
  })
  const [tags, setTags] = useState({ [searchTypes.company]: [], [searchTypes.contact]: [] });
  const [searchResult, setSearchResult] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("");
  const [openTagPopover, setOpenTagPopover] = useState(null);
  const [tagItemsIds, setTagItemsIds] = useState([]);
  const [prevRequestController, setPrevRequestController] = useState(null);
  const [searchError, setSearchError] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);
  const openSidebar = useSelector(state => state.ui.openTaggedSidebar);

  const handlePinTag = tag => {
    let res;
    if (pinnedTags[searchType]?.some(val => val.name === tag.name)) {
      res = {
        ...pinnedTags,
        [searchType]: pinnedTags[searchType]?.filter(val => val.name !== tag.name)
      }
    } else {
      res = {
        ...pinnedTags,
        [searchType]: [...pinnedTags[searchType], tag]
      }
    }
    setPinnedTags(res);
    localStorage.setItem("pinnedTags", JSON.stringify(res));
  };

  const onTagRemoved = async (tag) => {
    const res = await Tags.getTags(searchType).then(arr => arr?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1));
    setTags({
      ...tags,
      [searchType]: res
    });
    const pinned = {
      ...pinnedTags,
      [searchType]: pinnedTags[searchType]?.filter(val => val.name !== tag.name)
    };
    setPinnedTags(pinned);
    localStorage.setItem("pinnedTags", JSON.stringify(pinned));
  };

  const getSearchData = async (params) => {
    const { selected, page, order, sort, type } = params;
    const selectedRes = Object.keys(params || {}).includes("selected") ? selected : selectedTag;
    const requestType = type || searchType;

    if (prevRequestController) {
      prevRequestController.abort();
    }
    const controller = new AbortController();
    setPrevRequestController(controller);
    setSelectedItems([]);

    if (selectedRes) {
      setIsSearchLoading(true);
    }

    try {
      const key = requestType === searchTypes.contact ? "contact" : "company";
      const base64 = jsonToBase64({
        ...initialSearchRequestData.filter,
        [key]: { ...initialSearchRequestData.filter[key], tags: [selectedRes] }
      });
      if (selectedRes) {
        const res = await AdvancedSearchService.advancedSearch({
          q: base64,
          type: requestType,
          page: page || 1,
          limit: 30,
          sortBy: sort || sortBy,
          order: order || sortDir
        }, controller.signal);
        setSearchResult(res);
      } else {
        setSearchResult({ data: [] });
      }
    } catch (e) {
      setSearchError(e?.response?.data?.message || "Something went wrong");
    } finally {
      setIsSearchLoading(false);
    }
  };

  // eslint-disable-next-line
  const debounceGetSearchData = useCallback(debounce(getSearchData, 700), [prevRequestController, sortBy, sortDir, tags]);

  const getTags = async () => {
    const [company, contact] = await Promise.all([
      Tags.getTags(searchTypes.company).then(arr => arr?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)),
      Tags.getTags(searchTypes.contact).then(arr => arr?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1))
    ]);
    const tagsRes = {
      [searchTypes.company]: company,
      [searchTypes.contact]: contact
    };
    setTags(tagsRes);
    return tagsRes;
  };

  const getInitialData = async () => {
    const tagsRes = await getTags();
    setSelectedTag(tagsRes[searchType]?.[0]?.name);
    await getSearchData({ selected: tagsRes[searchType]?.[0]?.name });
    setIsPageLoading(false);
  };

  useEffect(() => {
    setIsPageLoading(true);
    getInitialData();
    // eslint-disable-next-line
  }, []);

  const handleExport = async (itemsIds) => {
    const item = searchResult?.data?.find(val => [val.contactId, val.companyId].includes(itemsIds[0]));
    const type = searchType === searchTypes.contact ? "contacts" : "companies";
    const name = searchType === searchTypes.contact ? getContactName(item) : item?.companyName;
    const res = await AdvancedSearchService.exportCsv(type, itemsIds);
    await downloadFile(res, itemsIds?.length > 1 ? type : name);
  };

  const handleTag = (itemsIds, currentTarget) => {
    setOpenTagPopover(currentTarget);
    setTagItemsIds(itemsIds);
  };

  const actionEvents = {
    "export": handleExport,
    "tag": handleTag
  };

  const handleSelectTag = tagName => {
    setSelectedTag(tagName);
    setIsSearchLoading(true);
    debounceGetSearchData({ selected: tagName, type: searchType });
  };

  const tagsUpdated = tags => {
    const idKey = searchType === searchTypes.contact ? "contactId" : "companyId";
    setSearchResult({
      ...searchResult,
      data: searchResult?.data?.map(val => {
        if (val[idKey] === tagItemsIds[0]) {
          return { ...val, tags: tags?.map(tag => ({ tagName: tag.name })) }
        }
        return val;
      })
    })
  };

  const tagsOptions = [
    ...tags[searchType]?.filter(tag => pinnedTags[searchType]?.some(val => val.name === tag.name)), // pinned
    ...tags[searchType]?.filter(tag => !pinnedTags[searchType]?.some(val => val.name === tag.name)) // not pinned
  ];


  const handleOpenSIdebar = () => {
    setTimeout(() => {
      dispatch(setOpenTaggedSidebar(true));
    }, 100)
  };

  const handleCloseSIdebar = () => {
    dispatch(setOpenTaggedSidebar(false));
  }

  return (
    <div className="tagged-page-wrapper">
      <PageSideBar
        title="Tagged"
        isLoading={isPageLoading}
        isOpen={openSidebar}
        onOpen={handleOpenSIdebar}
        onClose={handleCloseSIdebar}
      >
        <StyledTabs
          isLoading={isPageLoading}
          value={searchType}
          onChange={val => {
            setIsSearchLoading(true);
            setSearchType(val);
            setSelectedTag(tags[val]?.[0]?.name);
            getSearchData({ selected: tags[val]?.[0]?.name, type: val });
          }}
          tabs={searchTypesOptions?.map(tab => ({
            ...tab,
            content: (
              <div className="tagged-side-bar-content">
                <div className="tags-list">
                  {isPageLoading ? (
                    new Array(5).fill(0).map((_, i) => <TagItem key={i} isSkeletonLoading />)
                  ) : (
                    <>
                      {tags[searchType]?.length ? (
                        tagsOptions?.map((tag, i) => (
                          <TagItem
                            type={searchType}
                            key={i}
                            name={tag.name}
                            isActive={selectedTag === tag.name}
                            isPinned={pinnedTags[searchType]?.some(val => val.name === tag.name)}
                            onClick={() => handleSelectTag(tag.name)}
                            onRemoved={() => onTagRemoved(tag)}
                            onPin={() => handlePinTag(tag)}
                          />
                        ))
                      ) : (
                        <div className="empty-box">
                          <EmptyListIcon />
                          Tags list is empty
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )
          }))}
        />
      </PageSideBar>
      <PageContent>
        <CustomTable
          searchError={searchError}
          columns={searchType === searchTypes.contact ? contactTableColumns : companyTableColumns}
          isLoading={isPageLoading || isSearchLoading}
          data={searchResult}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          itemIdKey={searchType === searchTypes.contact ? "contactId" : "companyId"}
          sortBy={sortBy}
          sortDir={sortDir}
          onSort={(key, dir) => {
            setSortDir(dir);
            setSortBy(key);
            getSearchData({ sort: key, order: dir });
          }}
          onChangePage={page => getSearchData({ page })}
          popoverOptions={getRowOptions(searchType)?.map(val => ({ ...val, action: actionEvents[val.value] }))}
          onRowClick={({ companyId, contactId }) => {
            if (companyId || contactId) {
              dispatch(setDrawerState({
                data: {},
                mainRequestFinished: false,
                open: true,
                id: companyId || contactId,
                isCompany: !!companyId
              }));
            }
          }}
        />
        <TagsManagerPopover
          state={openTagPopover}
          setState={setOpenTagPopover}
          tagType={searchType}
          itemId={tagItemsIds[0]}
          onSuccess={tagsUpdated}
          onTagCreated={list => setTags({ ...tags, [searchType]: list?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1) })}
        />
      </PageContent>
    </div>
  )
};

export default Tagged;
