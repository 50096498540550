import React from "react";

const LeftArrow = ({ color = "var(--main-text)", className = "" }) => {
  return (
    <svg className={className} width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.83263 1.16737C5.05579 1.39052 5.05579 1.75233 4.83263 1.97549L1.80812 5L4.83263 8.02451C5.05579 8.24767 5.05579 8.60948 4.83263 8.83263C4.60948 9.05579 4.24767 9.05579 4.02451 8.83263L0.595939 5.40406C0.372783 5.1809 0.372783 4.8191 0.595939 4.59594L4.02451 1.16737C4.24767 0.944211 4.60948 0.944211 4.83263 1.16737Z" fill={color} stroke={color} strokeWidth="0.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LeftArrow;
