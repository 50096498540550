import { getCurrentTheme } from "helpers/common";
import { handleActions } from "redux-actions";
import { authorizeAsync, setThemeType, getMeInfo, setBurgerOpen } from "redux/actions/auth";

const InitialState = {
  isAuthLoading: true,
  isAuth: false,
  accountId: null,
  role: null,
  auth0UserId: null,
  userId: null,
  isBlocked: false,
  themeType: getCurrentTheme(localStorage?.getItem("theme") || "system"),
  burgerOpen: true,
};

const handleAuthSuccess = (state, { payload }) => ({
  ...state,
  isAuthLoading: false,
  isAuth: true,
  ...payload
});

const handleAuthFail = (state, err) => {
  if (err.payload.response.data.statusCode === 403) {
    return {
      ...state,
      isBlocked: true,
      isAuthLoading: false,
      isAuth: false
    };
  }
  return {
    ...state,
    isAuthLoading: false,
    isAuth: false
  };
};

const handleSetThemeType = (state, { payload }) => ({
  ...state,
  themeType: payload
});

const handleGetMeInfo = (state, { payload }) => ({
  ...state,
  ...payload
});

const handleSetBurgerOpen = (state, { payload }) => ({
  ...state,
  burgerOpen: payload
});

export default handleActions(
  {
    [authorizeAsync.success]: handleAuthSuccess,
    [authorizeAsync.fail]: handleAuthFail,
    [setThemeType]: handleSetThemeType,
    [getMeInfo]: handleGetMeInfo,
    [setBurgerOpen]: handleSetBurgerOpen,
  },
  {
    ...InitialState
  }
);
