import React from "react";

const StarsIcon = (props) => (
  <svg {...props} width="126" height="120" viewBox="0 0 126 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.25" d="M97.1951 68.1103C83.9834 65.5698 72.0829 59.2506 63.6113 50.3055C55.1397 41.3604 50.5257 30.1097 50.5257 18.6455V17.3005H49.5172V18.6455C49.5172 30.1097 44.8527 41.3604 36.4064 50.3055C27.96 59.2506 16.0342 65.5912 2.82256 68.1103L0.679443 68.5373L2.82256 68.9429C16.0342 71.4834 27.96 77.8026 36.4064 86.769C44.8527 95.7355 49.5172 106.965 49.5172 118.429V119.774H50.5257V118.429C50.5257 106.965 55.1901 95.7141 63.6365 86.769C72.0829 77.8026 84.0087 71.4834 97.2203 68.9429L99.3886 68.5159L97.1951 68.1103Z" fill="var(--second-text)"/>
    <path opacity="0.25" d="M124.243 25.2453C117.749 23.9906 111.899 20.8697 107.735 16.452C103.571 12.0342 101.303 6.47769 101.303 0.815768V0.15152H100.808V0.815768C100.808 6.47769 98.515 12.0342 94.3634 16.452C90.2118 20.8697 84.35 24.0012 77.8561 25.2453L76.8027 25.4562L77.8561 25.6565C84.35 26.9112 90.2118 30.0321 94.3634 34.4605C98.515 38.8888 100.808 44.4347 100.808 50.0967V50.7609H101.303V50.0967C101.303 44.4347 103.596 38.8782 107.748 34.4605C111.899 30.0321 117.761 26.9112 124.255 25.6565L125.321 25.4457L124.243 25.2453Z" fill="var(--second-text)"/>
  </svg>
);

export default StarsIcon;
