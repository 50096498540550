import React from "react";

const DropDownArrow = ({ className }) => {
  return (
    <svg className={className || ""} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.16737 1.16737C1.39052 0.944211 1.75233 0.944211 1.97549 1.16737L5 4.19188L8.02451 1.16737C8.24767 0.944211 8.60948 0.944211 8.83263 1.16737C9.05579 1.39052 9.05579 1.75233 8.83263 1.97549L5.40406 5.40406C5.1809 5.62722 4.8191 5.62722 4.59594 5.40406L1.16737 1.97549C0.944211 1.75233 0.944211 1.39052 1.16737 1.16737Z" fill="#BEC3ED" stroke="#BEC3ED" strokeWidth="0.4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default DropDownArrow;
