import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, InputAdornment, LinearProgress } from "@mui/material";

import { downloadFile } from "helpers/common";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { SearchProfileDetails } from "services/SearchDetails";
import { AdvancedSearchService } from "services/AdvancedSearchService";
import { searchTypes } from "utils/searchFilters";

import CustomCheckbox from "shared/CustomCheckbox";
import DefaultPopover, { PopoverItem } from "shared/DefaultPopover";
import StyledTextField from "shared/StyledTextField";
import SimpleSearchItem from "shared/SimpleSearchItem";
import TagsManagerPopover from "shared/TagsManagerPopover";

import TagIcon from "assets/iconComponents/TagIcon";
import WorkIcon from "assets/iconComponents/WorkIcon";
import SearchIcon from "assets/iconComponents/SearchIcon";
import ExportIcon from "assets/iconComponents/ExportIcon";
import DropDownArrow from "assets/iconComponents/DropDownArrow";
import NoOrgChartIcon from "assets/iconComponents/NoOrgChartIcon";

import "./styles.scss";

const JobTitleItem = ({ data, label, selected, setSelected, handleGetNextEmployees, department, totalCount, itemLoading, setActiveItemId, setOpenTagPopover }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [listContentHeight, setListContentHeight] = useState(0);
  const [shouldRender, setShouldRender] = useState(false);
  const [toggleDisabled, setToggleDisabled] = useState(false);

  const handleNext = () => {
    const page = currentPage + 1;
    setIsLoading(true);
    handleGetNextEmployees(page, department, label)
      .then(() => {
        setCurrentPage(state => state + 1);
        setTimeout(() => setListContentHeight(`${ref.current.clientHeight}px`), 100);
      })
      .finally(() => setIsLoading(false));
  };

  const handleExport = async (item) => {
    try {
      const name = `${item.firstName || ""} ${item.lastName || ""}`;
      const res = await AdvancedSearchService.exportCsv("contacts", [item.contactId]);
      await downloadFile(res, name);
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <div className={`job-title-item ${itemLoading ? "loader" : ""}`}>
      {itemLoading ? (
        <div className="level-item-box">
          <div className="show-hide-btn loader" />
          <CustomCheckbox label isLoading />
        </div>
      ) : (
        <>
          <div className="level-item-box">
            <Button
              className={`show-hide-btn ${listContentHeight ? "active" : ""}`}
              disabled={toggleDisabled}
              onClick={() => {
                setShouldRender(true);
                setToggleDisabled(true);
                setTimeout(() => {
                  setListContentHeight(listContentHeight ? 0 : ref.current.clientHeight);
                  setToggleDisabled(false);
                }, 100)
              }}
            />
            <CustomCheckbox
              label={label}
              checked={!data?.some(val => !selected?.some(vl => vl.contactId === val.contactId))}
              onChange={() => {
                if (!data?.some(val => !selected?.some(vl => vl.contactId === val.contactId))) {
                  return setSelected(selected?.filter(val => !data?.some(vl => vl.contactId === val.contactId)));
                }
                return setSelected([...selected, ...data]);
              }}
              isSimpleCheckbox
            />
          </div>
          <div className="list-content" style={{ height: listContentHeight }}>
            <div className="employees-box" ref={ref}>
              {shouldRender ? data?.map((val, i) => (
                <SimpleSearchItem
                  key={i}
                  avatar={val?.avatar}
                  checked={selected?.some(item => item.contactId === val.contactId)}
                  onChange={() => {
                    if (selected?.some(item => item.contactId === val.contactId)) {
                      return setSelected(selected?.filter(item => item.contactId !== val.contactId));
                    }
                    return setSelected([...selected, val]);
                  }}
                  name={`${val.firstName || ""} ${val.lastName || ""}`}
                  icon={<WorkIcon />}
                  subtitle={val?.jobTitle || "Job title unknown"}
                  onTitleClick={() => dispatch(setDrawerState({ data: {}, id: val?.contactId, mainRequestFinished: false, open: true, isCompany: false }))}
                  popoverOptions={[{
                    label: "Export",
                    value: "export",
                    icon: <ExportIcon color="var(--main-text)" />,
                    action: () => handleExport(val)
                  },
                  {
                    label: `Tag Contact`,
                    value: "tag",
                    icon: <TagIcon />,
                    action: (buttonRef) => {
                      setActiveItemId(val?.contactId);
                      setOpenTagPopover(buttonRef.current)
                    }
                  }]}
                />
              )) : null}
              {data?.length < totalCount && (
                <div className="load-more-box">
                  {isLoading ? (
                    <LinearProgress />
                  ) : (
                    <Button onClick={handleNext}>Load more</Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const OrgItem = ({ data, label, selected, setSelected, handleGetNextEmployees, isLoading, itemIndex, setActiveItemId, activeItemId, setOpenTagPopover, openTagPopover
}) => {
  const ref = useRef();
  const [height, setHeight] = useState("fit-content");
  const handleOpen = () => {
    if (height === "0") {
      setHeight(`${ref.current.clientHeight}px`);
      setTimeout(() => setHeight("fit-content"), 350);
    } else {
      setHeight(`${ref.current.clientHeight}px`);
      setTimeout(() => setHeight("0"), 50);
    }
  };
  const isChecked = !Object.keys(data || {})?.some(val => (
    data[val]?.items?.some(vl => !selected?.some(v => v.contactId === vl.contactId))
  ));
  return (
    <div className={`department-item ${height !== "0" ? "opened" : ""} ${isLoading ? "loader" : ""}`}>
      {isLoading ? (
        <>
          <div className="level-item-box">
            <div className="show-hide-btn loader" />
            <CustomCheckbox label isLoading />
          </div>
          <div className="list-content" style={{ height: "fit-content" }}>
            <div className="job-titles-box">
              {new Array(itemIndex % 2 === 0 ? 1 : 2).fill(0).map((v, i) => (
                <JobTitleItem key={i} itemLoading />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="level-item-box">
            <Button className={`show-hide-btn ${height !== "0" ? "active" : ""}`} onClick={handleOpen} />
            <CustomCheckbox
              label={label}
              checked={isChecked}
              onChange={() => {
                if (isChecked) {
                  return setSelected(selected?.filter(val => {
                    return !Object.keys(data)?.some(vl => (
                      data[vl]?.items?.some(v => v.contactId === val.contactId)
                    ))
                  }))
                }
                const items = [];
                Object.keys(data)?.forEach(val => {
                  data[val]?.items?.forEach(vl => items.push(vl));
                });
                return setSelected([...selected, ...items])
              }}
              isSimpleCheckbox
            />
          </div>
          <div className="list-content" style={{ height }}>
            <div className="job-titles-box" ref={ref}>
              {Object.keys(data || {})?.sort((a, b) => a < b ? -1 : 1)?.map((val, i) => (
                <JobTitleItem
                  data={data[val]?.items}
                  totalCount={data[val]?.totalCount}
                  key={i}
                  label={val}
                  selected={selected}
                  setSelected={setSelected}
                  handleGetNextEmployees={handleGetNextEmployees}
                  department={label}
                  setActiveItemId={setActiveItemId}
                  setOpenTagPopover={setOpenTagPopover}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const OrgChart = ({ isLoading }) => {
  const dispatch = useDispatch();
  const drawerData = useSelector(state => state.detailsDrawer);
  const [dataLoading, setDataLoading] = useState(true);

  const [openSelectedPopover, setOpenSelectedPopover] = useState(null);
  const [openDepartPopover, setOpenDepartPopover] = useState(null);
  const [nameOrJob, setNameOrJob] = useState("");
  const [selected, setSelected] = useState([]);
  const [department, setDepartment] = useState("All");
  const [isExportDisabled, setIsExportDisabled] = useState(false);

  const [openTagPopover, setOpenTagPopover] = useState(null);
  const [activeItemId, setActiveItemId] = useState(null);

  const data = drawerData?.data?.orgChart || {};

  const companyId = drawerData?.data?.companyId;

  const getData = async () => {
    setDataLoading(true);
    let companyOrgStructure = {};
    try {
      companyOrgStructure = await SearchProfileDetails.getCompanyOrgStructure({ id: companyId });
    } catch (e) {
      console.log(e);
    }
    dispatch(setDrawerState({
      data: {
        ...drawerData.data,
        orgChart: companyOrgStructure
      }
    }));
    setDataLoading(false);
  };

  useEffect(() => {
    setSelected([]);
    if (drawerData?.open && drawerData?.id && drawerData?.mainRequestFinished) {
      getData();
    }
    // eslint-disable-next-line
  }, [drawerData?.open, drawerData?.id, drawerData?.mainRequestFinished]);

  const filterData = val => {
    return Object.keys(data[val])?.filter(vl => {
      return data[val][vl]?.items?.filter(v => (
        `${v.firstName} ${v.lastName}`.toLowerCase()?.includes(nameOrJob?.toLowerCase()) ||
        v.jobTitle?.toLowerCase()?.includes(nameOrJob?.toLowerCase())
      ))?.length
    })?.length && (department !== "All" ? val === department : true)
  };

  const handleSelectDepartment = val => {
    setDepartment(val);
    setOpenDepartPopover(null);
  };

  const handleExportAll = () => {
    setIsExportDisabled(true);
    AdvancedSearchService.exportCsv("contacts", selected?.map(val => val.contactId)?.join(","))
      .then(blob => downloadFile(blob, "contacts"))
      .catch(e => console.log(e))
      .finally(() => setIsExportDisabled(false));
  };

  const handleGetNextEmployees = (page, department, managementLevel) => {
    return SearchProfileDetails.getCompanyOrgStructure({ id: drawerData.id, page, department, managementLevel })
      .then(res => {
        dispatch(setDrawerState({
          ...drawerData,
          data: {
            ...drawerData.data,
            orgChart: {
              ...data,
              [department]: {
                ...data[department],
                [managementLevel]: {
                  ...data[department][managementLevel],
                  items: [
                    ...data[department][managementLevel].items,
                    ...res?.data
                  ]
                }
              }
            }
          }
        }));
      });
  };

  return (
    <div className="drawer-org-chart-wrapper">
      {(isLoading || dataLoading) ? (
        <>
          <div className="org-chart-head">
            <div className="chart-head-content-box">
              {new Array(3).fill(0).map((v, i) => (
                <div key={i} className="select-btn">
                  <div className="loader" />
                </div>
              ))}
            </div>
            <div className="chart-head-content-box mobile-hidden">
              {new Array(2).fill(0).map((v, i) => (
                <div key={i} className="chart-head-action-btn">
                  <div className="loader" />
                </div>
              ))}
            </div>
          </div>
          <div className="org-chart-content">
            {new Array(6).fill(0).map((v, i) => (
              <OrgItem key={i} isLoading itemIndex={i} />
            ))}
          </div>
        </>
      ) : Object.keys(data)?.length > 0 ? (
        <>
          <div className={`head-wrapper ${!isLoading && selected?.length ? "opened" : ""}`}>
            <div className="org-chart-head">
              <div className="chart-head-content-box">
                <Button
                  onClick={e => setOpenSelectedPopover(e.currentTarget)}
                  className={`select-btn ${openSelectedPopover ? "active" : ""}`}
                >
                  <div className="select-result">
                    Selected: <span>{selected?.length}</span>
                  </div>
                  <DropDownArrow />
                </Button>
                <DefaultPopover
                  state={openSelectedPopover}
                  setState={setOpenSelectedPopover}
                >
                  <PopoverItem
                    label="Clear all"
                    onClick={() => {
                      setSelected([]);
                      setOpenSelectedPopover(null);
                    }}
                  />
                  <PopoverItem
                    label="Select all"
                    onClick={() => {
                      const items = [];
                      Object.keys(data)?.filter(filterData)?.forEach(val => {
                        Object.keys(data[val]).forEach(vl => {
                          data[val][vl]?.items?.forEach(v => items.push(v))
                        });
                      });
                      setSelected(items);
                      setOpenSelectedPopover(null);
                    }}
                  />

                </DefaultPopover>
                <Button
                  onClick={e => setOpenDepartPopover(e.currentTarget)}
                  className={`select-btn ${openDepartPopover ? "active" : ""}`}
                >
                  <div className="select-result">
                    Department: <span>{department}</span>
                  </div>
                  <DropDownArrow />
                </Button>
                <DefaultPopover className="head-select-popover" state={openDepartPopover} setState={setOpenDepartPopover}>
                  <PopoverItem label="All" onClick={() => handleSelectDepartment("All")} />
                  {Object.keys(data)?.map(val => (
                    <PopoverItem
                      key={val}
                      label={val}
                      onClick={() => handleSelectDepartment(val)}
                    />
                  ))}
                </DefaultPopover>
                <StyledTextField
                  className="job-search-field"
                  value={nameOrJob}
                  placeholder="Name or Job Title"
                  onChange={e => setNameOrJob(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="chart-head-content-box">
                <Button
                  className="chart-head-action-btn"
                  disabled={!selected?.length || isExportDisabled}
                  onClick={handleExportAll}
                >
                  <ExportIcon />
                  Export
                </Button>
                {/* <Button
                  className="chart-head-action-btn"
                  disabled={!selected?.length}
                  onClick={e => setOpenTagPopover(e.currentTarget)}
                >
                  <TagIcon />
                  Tag
                </Button> */}
              </div>
            </div>
          </div>
          <div className={`org-chart-content ${selected.length ? "collapse" : ""}`}>
            {Object.keys(data)?.sort((a, b) => a < b ? -1 : 1)?.filter(filterData)?.map(val => (
              <OrgItem
                data={data[val]}
                key={val}
                label={val}
                selected={selected}
                setSelected={setSelected}
                handleGetNextEmployees={handleGetNextEmployees}
                setActiveItemId={setActiveItemId}
                setOpenTagPopover={setOpenTagPopover}
              />
            ))}
          </div>
          <TagsManagerPopover
            state={openTagPopover}
            setState={setOpenTagPopover}
            tagType={searchTypes.contact}
            itemId={activeItemId}
          />
        </>
      ) : (
        <div className="empty-state">
          <NoOrgChartIcon />
          No organisation chart
        </div>
      )}
    </div>
  )
}

export default OrgChart;