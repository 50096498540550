import React from "react";

const TagIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.75487 12.8971L4.92735 14.9257C4.62175 15.0794 4.24526 14.9706 4.07679 14.68V14.68C4.02805 14.59 4.00175 14.4902 4 14.3886V5.4C4 3.68571 5.21087 3 6.95334 3H11.0467C12.736 3 14 3.64 14 5.28571V14.3886C14 14.5507 13.9334 14.7063 13.8149 14.8209C13.6964 14.9356 13.5356 15 13.368 15C13.2611 14.9984 13.156 14.9729 13.0608 14.9257L9.20969 12.8971C9.06778 12.823 8.89679 12.823 8.75487 12.8971Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7 6H11" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default TagIcon;
