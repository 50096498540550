import React from "react";

const RecentIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.51331 13.2426C6.4704 14.2243 7.72977 14.8351 9.0768 14.9711C10.4238 15.1071 11.7752 14.7598 12.9005 13.9885C14.0259 13.2171 14.8556 12.0693 15.2483 10.7408C15.641 9.41226 15.5724 7.98517 15.0541 6.70271C14.5359 5.42026 13.6001 4.3618 12.4062 3.70772C11.2123 3.05363 9.83421 2.8444 8.50677 3.11568C7.17932 3.38695 5.98467 4.12195 5.12639 5.19541C4.26811 6.26887 3.79932 7.61437 3.79991 9.00262V10.3333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.5 9.00016L3.8 10.3335L5.1 9.00016M9 6.3335V9.66682H12.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default RecentIcon;
