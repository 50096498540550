import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { copyText } from "helpers/common";
import { setRevealedContacts } from "redux/actions/filters";
import { SearchProfileDetails } from "services/SearchDetails";

import Loader from "shared/Loader";
import StyledTooltip from "shared/StyledTooltip";

import PhonesIcon from "assets/iconComponents/PhonesIcon";
import EmailsIcon from "assets/iconComponents/EmailsIcon";
import WebsitesIcon from "assets/iconComponents/WebsitesIcon";
import CopyIcon from "assets/iconComponents/CopyIcon";
import EyeIcon from "assets/iconComponents/EyeIcon";

import "./styles.scss";

const TooltipItem = ({ value }) => {
  const [copied, setCopied] = useState(false);
  return (
    <div className="tooltip-item">
      {value}
      <Button
        className={`copy-btn ${copied ? "copied" : ""}`}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (!copied) {
            copyText(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          }
        }}
      >
        <CopyIcon color={copied ? "var(--success-color)" : "var(--primary-color)"} />
        {copied ? "Copied" : "Copy"}
      </Button>
    </div>
  )
};

const IconWrapper = ({ disabled, items, children }) => disabled ? (
  <div className="icon-box disabled">
    {children}
  </div>
) : (
  <StyledTooltip
    className="contact-tooltip"
    title={(
      <div className="contact-tooltip-content">
        {items?.map((val, i) => (
          <TooltipItem value={val} key={i} />
        ))}
      </div>
    )}
  >
    <div className="icon-box active">
      {children}
    </div>
  </StyledTooltip>
);

const ContactsColumn = ({ website, contactId, isLoading }) => {
  const dispatch = useDispatch();
  const revealedContacts = useSelector(state => state.filters.revealedContacts);
  const [revealLoading, setRevealLoading] = useState(false);
  const revealedData = revealedContacts?.find(val => val.id === contactId);

  const revealContact = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setRevealLoading(true);
    try {
      const res = await SearchProfileDetails.getRevealedDetails(contactId);
      const newRevealed = [...revealedContacts, res];
      dispatch(setRevealedContacts(newRevealed));
    } catch (e) {
      console.log(e);
    }
    setRevealLoading(false);
  };

  return (
    <>
      <div className="contact-column-wrapper">
        {isLoading ? (
          <div key="contact-loader" className="contact-loader" />
        ) : (
          <>
            {(revealedData || !contactId) ? (
              <div className="info-items-box">
                <IconWrapper disabled={!revealedData?.phones?.length} items={revealedData?.phones}>
                  <PhonesIcon color="var(--primary-color)" />
                </IconWrapper>
                <div className="divider" />
                {contactId && (
                  <IconWrapper disabled={!revealedData?.emails?.length} items={revealedData?.emails}>
                    <EmailsIcon color="var(--primary-color)" />
                  </IconWrapper>
                )}
                {!contactId && (
                  <IconWrapper disabled={!website} items={[website]}>
                    <WebsitesIcon color="var(--primary-color)" />
                  </IconWrapper>
                )}
              </div>
            ) : (
              <Button className="reveal-btn" disabled={revealLoading} onClick={revealContact}>
                {revealLoading ? <Loader size={12} /> : <EyeIcon />}
                Reveal
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
};

export default ContactsColumn;
