import React from "react";

const ContactFilterIcon = ({ color = "var(--second-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.196 8.19856C14.3977 8.19856 15.3726 7.22437 15.3726 6.02262C15.3726 4.82087 14.3977 3.84668 13.196 3.84668" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.1967 10.5635C14.5555 10.5882 14.9123 10.6391 15.2637 10.7182C15.7518 10.8137 16.3389 11.0138 16.5479 11.4517C16.6813 11.7322 16.6813 12.0588 16.5479 12.34C16.3396 12.7779 15.7518 12.9773 15.2637 13.0777" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.71776 8.19856C3.51601 8.19856 2.54114 7.22437 2.54114 6.02262C2.54114 4.82087 3.51601 3.84668 4.71776 3.84668" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.71687 10.5635C3.358 10.5882 3.00118 10.6391 2.64987 10.7182C2.16175 10.8137 1.57462 11.0138 1.36631 11.4517C1.23225 11.7322 1.23225 12.0588 1.36631 12.34C1.57393 12.7779 2.16175 12.9773 2.64987 13.0777" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.95359 11.0322C11.3887 11.0322 13.4691 11.4007 13.4691 12.8754C13.4691 14.3494 11.4025 14.7317 8.95359 14.7317C6.51778 14.7317 4.4381 14.3632 4.4381 12.8885C4.4381 11.4138 6.50472 11.0322 8.95359 11.0322Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.95318 8.92869C7.34718 8.92869 6.05949 7.641 6.05949 6.03431C6.05949 4.42831 7.34718 3.14062 8.95318 3.14062C10.5592 3.14062 11.8469 4.42831 11.8469 6.03431C11.8469 7.641 10.5592 8.92869 8.95318 8.92869Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ContactFilterIcon;
