import React, { useState, useRef, useEffect } from "react";
import { Button, TextField } from "@mui/material";

import DefaultPopover, { PopoverItem } from "shared/DefaultPopover";

import RecentChatIcon from "assets/iconComponents/RecentChatIcon";
import ThreeDots from "assets/iconComponents/ThreeDots";
import DeleteIcon from "assets/iconComponents/DeleteIcon";
import EditIcon from "assets/iconComponents/EditIcon";

import "./styles.scss";

const RecentItem = ({ label, isActive, isLoading, onClick, onRemove, onEdit }) => {
  const fieldRef = useRef();
  const [actionLoading, setActionLoading] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [chatName, setChatName] = useState(label);

  const onRemoveClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenPopover(null);
    setActionLoading(true);
    await onRemove();
    setActionLoading(false);
  };

  const onEditClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenPopover(null);
    setIsEdit(true);
  };

  useEffect(() => {
    if (isEdit && fieldRef.current) {
      fieldRef.current.focus();
    }
    // eslint-disable-next-line
  }, [isEdit, fieldRef.current]);

  const saveChatName = async () => {
    setIsEdit(false);
    if (!chatName?.trim() || chatName?.trim() === label) {
      return setChatName(label);
    }
    setActionLoading(true);
    await onEdit(chatName?.trim());
    setActionLoading(false);
  };

  return (
    <div
      className={`recent-item-wrapper ${isActive ? "active" : ""} ${isLoading ? "skeleton-loading" : ""} ${actionLoading ? "item-loading" : ""}`}
      onClick={() => {
        if (onClick && !isLoading && !isEdit) {
          onClick();
        }
      }}
    >
      {isLoading ? (
        <>
          <div className="icon-loader" />
          <div className="recent-item-label">
            <div className="label-loader" />
          </div>
        </>
      ) : (
        <>
          <RecentChatIcon />
          <div className="recent-item-label">
            <TextField
              inputRef={fieldRef}
              className={`chat-name-field ${isEdit ? "display" : ""}`}
              value={chatName}
              onChange={e => setChatName(e.target.value)}
              placeholder="Enter chat name"
              onBlur={saveChatName}
            />
            <div className={`item-label ${!isEdit ? "display" : ""}`}>{chatName}</div>
          </div>
          {(onRemove || onEdit) && !isEdit && (
            <div className="option-btn-box">
              <Button
                className="menu-popover-btn"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenPopover(e.currentTarget);
                }}
              >
                <ThreeDots />
              </Button>
            </div>
          )}
          <DefaultPopover state={openPopover} setState={setOpenPopover}>
            <PopoverItem label="Rename" icon={<EditIcon />} onClick={onEditClick} />
            <PopoverItem label="Delete chat" isDelete icon={<DeleteIcon />} onClick={onRemoveClick} />
          </DefaultPopover>
        </>
      )}
    </div>
  )
};

export default RecentItem;
