import { Button, Drawer, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { useNavigate } from "react-router-dom";

import { AdvancedSearchService } from "services/AdvancedSearchService";
import { downloadFile, getContactName } from "helpers/common";
import { searchTypes } from "utils/searchFilters";
import { setAiThread } from "redux/actions/chat";
import { CHAT } from "pathnameVariables";

import { getEmploymentExperiences } from "helpers/searchHelper";

import TagsManagerPopover from "shared/TagsManagerPopover";

import OrganisationProfile from "./OrganisationProfile";
import OrgChart from "./OrgChart";
import Employee from "./Employee";
import ContactInfoTab from "./ContactInfoTab";
import SimilarCompanies from "./SimilarCompanies";
import ContactHero from "./ContactHero";
import CompanyHero from "./CompanyHero";

import TagIcon from "assets/iconComponents/TagIcon";
import CloseIcon from "assets/iconComponents/CloseIcon";
import ExportIcon from "assets/iconComponents/ExportIcon";
import ProfileIcon from "assets/iconComponents/ProfileIcon";
import BookmarkIcon from "assets/iconComponents/BookmarkIcon";
import EmployeeIcon from "assets/iconComponents/EmployeeIcon";
import OrgChartIcon from "assets/iconComponents/OrgChartIcon";
import StartThreadIcon from "assets/iconComponents/StartThreadIcon";

import "./styles.scss"

export const detailsDrawerTabs = [
  {
    label: "Overview",
    value: "overview",
    icon: ProfileIcon,
    component: OrganisationProfile
  },
  // {
  //   label: "Overview",
  //   value: "overview",
  //   icon: OverviewIcon,
  //   component: Overview
  // },
  {
    label: "Org Chart",
    value: "orgChart",
    icon: OrgChartIcon,
    component: OrgChart
  },
  {
    label: "Employee",
    value: "employee",
    icon: EmployeeIcon,
    component: Employee
  },
  {
    label: "Similar Companies",
    value: "similarCompanies",
    icon: TagIcon,
    component: SimilarCompanies
  }
];

const DetailsDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const drawerData = useSelector(state => state.detailsDrawer);
  const [isLoading, setIsLoading] = useState(false);
  const searchId = drawerData?.id;

  const isCompany = drawerData?.isCompany;

  const [activeTab, setActiveTab] = useState(detailsDrawerTabs[0].value)
  const [openTagPopover, setOpenTagPopover] = useState(null);
  const [tagsUpdated, setTagsUpdated] = useState(null);

  const getData = async () => {
    setIsLoading(true);
    dispatch(setDrawerState({ data: {}, mainRequestFinished: false }));

    const successAction = data => {
      dispatch(setDrawerState({ data, mainRequestFinished: true }))
      setIsLoading(false);
    };

    if (!isCompany) {
      const [contact, expData] = await Promise.all([
        AdvancedSearchService.contactDetails(searchId),
        AdvancedSearchService.contactExperience(searchId)
      ]);
      successAction({
        contactData: contact,
        ...(getEmploymentExperiences(expData) || {})
      });
    } else {
      const company = await AdvancedSearchService.companyDetails(searchId);
      successAction(company || {});
    }
  };

  useEffect(() => {
    if (drawerData?.open && drawerData?.id) {
      getData();
      setActiveTab(detailsDrawerTabs[0].value);
    }
    // eslint-disable-next-line
  }, [drawerData?.open, drawerData?.id]);

  const name = isCompany ? drawerData?.data?.companyName : getContactName(drawerData?.data?.contactData);

  const ActiveTabComponent = isCompany ? detailsDrawerTabs.find(it => it.value === activeTab)?.component || null : null;

  const handleCloseDrawer = () => {
    dispatch(setDrawerState({ open: false, id: "", data: {} }));
  }

  const handleExport = async (itemsIds) => {
    try {
      const type = drawerData.isCompany ? "companies" : "contacts";
      const res = await AdvancedSearchService.exportCsv(type, [drawerData.id]);
      await downloadFile(res, itemsIds?.length > 1 ? type : name);
    } catch (e) {
      console.log(e)
    }
  };

  const handleStartAI = () => {
    let request = "";
    if (drawerData.isCompany) {
      request = `Give me information about the company "${name}"`;
    } else {
      request = `Give me information about the person "${name}"`;
    }
    handleCloseDrawer();
    dispatch(setAiThread(request));
    navigate(CHAT,  { state: { newChat: true } });
  };

  return (
    <Drawer
      anchor="right"
      open={drawerData?.open || false}
      onClose={handleCloseDrawer}
      className={`details-drawer ${drawerData.isCompany ? "company" : ""}`}
    >
      <div className="drawer-content-wrapper">
        <div className="drawer-header">
          <div className="drawer-title">{isCompany ? "Company overview" : "Contact overview"}</div>
          <div className="close-btn" onClick={handleCloseDrawer}><CloseIcon color="var(--main-text)" /></div>
        </div>
        <div className="basic-info-wrapper">
          {isCompany ? (
            <CompanyHero isLoading={isLoading} />
          ) : (
            <ContactHero isLoading={isLoading} />
          )}
        </div>

        <div className="buttons-wrapper">
          <Button className="btn" disabled={isLoading} onClick={handleStartAI}>
            <StartThreadIcon />
            Start AI thread
          </Button>
          <Button
            className="btn-outline"
            onClick={handleExport}
            disabled={isLoading}
          >
            <ExportIcon />
            Export
          </Button>
          <Button
            className="btn-outline"
            onClick={e => setOpenTagPopover(e.currentTarget)}
            disabled={isLoading}
          >
            <BookmarkIcon />
            Tag
          </Button>
        </div>

        {isCompany ? (
          <>
            <Tabs
              value={activeTab}
              onChange={(e, newVal) => setActiveTab(newVal)}
              variant="scrollable"
              className="details-tabs"
            >
              {detailsDrawerTabs?.map((val, i) => (
                <Tab
                  key={i}
                  value={val.value}
                  disabled={val.disabled || isLoading}
                  className="tab-btn"
                  disableRipple
                  label={
                    <>
                      {<val.icon color={activeTab === val.value ? "var(--primary-color)" : "var(--second-text)"} />}
                      {val.label === "profile" ? (!isCompany ? "Contact Profile" : "Company Profile") : val.label}
                    </>
                  }
                />
              ))}
            </Tabs>
            {ActiveTabComponent ? <ActiveTabComponent tagsUpdated={tagsUpdated} isLoading={isLoading} setActiveTab={setActiveTab} /> : null}
          </>
        ) : (
          <ContactInfoTab isLoading={isLoading} tagsUpdated={tagsUpdated} />
        )}

        <TagsManagerPopover
          state={openTagPopover}
          setState={setOpenTagPopover}
          tagType={isCompany ? searchTypes.company : searchTypes.contact}
          itemId={drawerData.id}
          onSuccess={() => setTagsUpdated((new Date().getTime()))}
        />

      </div>
    </Drawer>
  )
}

export default DetailsDrawer;