import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { searchTypesOptions } from "utils/searchFilters";
import { setSearchRequestData } from "redux/actions/filters";
import { setOpenSearchSidebar } from "redux/actions/ui";

import PageSideBar from "shared/PageSideBar";
import StyledTabs from "shared/StyledTabs";

import SaveIcon from "assets/iconComponents/SaveIcon";
import FiltersIcon from "assets/iconComponents/FiltersIcon";

import FiltersList from "./FiltersList";
import SavedSearchesList from "./SavedSearchesList";
import "./styles.scss";

const SearchFilters = ({ isPageLoading, setIsSearchLoading }) => {
  const dispatch = useDispatch();
  const requestData = useSelector(state => state.filters.searchRequestData);
  const openSidebar = useSelector(state => state.ui.openSearchSidebar);
  const [currentTab, setCurrentTab] = useState("filters");
  const setRequestData = val => dispatch(setSearchRequestData(val));
  const setRequestFilter = val => dispatch(setSearchRequestData({ ...requestData, filter: val }));

  const handleOpenSidebar = () => {
    setTimeout(() => {
      dispatch(setOpenSearchSidebar(true));
    }, 100)
  };
  
  const handleCloseSidebar = () => {
    dispatch(setOpenSearchSidebar(false));
  }

  return (
    <PageSideBar
      title="Search"
      isLoading={isPageLoading}
      className="search-filters-side-bar"
      isOpen={openSidebar}
      onOpen={handleOpenSidebar}
      onClose={handleCloseSidebar}
    >
      <div className="search-filters-list-wrapper">
        <StyledTabs
          isLoading={isPageLoading}
          value={requestData.searchType}
          onChange={val => {
            setIsSearchLoading(true);
            setRequestData({ ...requestData, searchType: val })
          }}
          tabs={searchTypesOptions}
        />
        <StyledTabs
          outline
          isLoading={isPageLoading}
          value={currentTab}
          onChange={setCurrentTab}
          tabs={[
            {
              label: "Filters",
              value: "filters",
              getIcon: color => <FiltersIcon color={color} />,
              content: <FiltersList isPageLoading={isPageLoading} setRequestFilter={setRequestFilter} />
            },
            {
              label: "Saved",
              value: "saved",
              getIcon: color => <SaveIcon color={color} />,
              content: <SavedSearchesList setRequestFilter={setRequestFilter} />
            }
          ]}
        />
      </div>
    </PageSideBar>
  )
};

export default SearchFilters;
