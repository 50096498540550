import moment from "moment";
import {
  locationOptions,
  classificationCodeOptions,
  industryTypeOptions,
  initialSearchRequestData
} from "utils/searchFilters";

const getSelectedDistricts = (arr) => {
  return locationOptions
    ?.filter(option => arr.some(vl => option.children?.some(child => child.value === vl)))
    ?.map(val => ({ value: val.label, item: val.value }))
};

const getClassCode = (type, code) => {
  return `${classificationCodeOptions?.find(val => val.value === type)?.label} - ${code}`;
};

export const removeFilter = ({ sectionKey, key, filters, item }) => {
  const clearedData = key === "districts" && item
    ? filters.location.districts?.filter(state => !locationOptions?.find(val => val.value === item)?.children?.some(val => val.value === state))
    : item
      ? filters[sectionKey][key]?.filter(val => val !== item)
      : initialSearchRequestData.filter[sectionKey][key];

  return {
    ...filters,
    [sectionKey]: {
      ...(filters[sectionKey] || {}),
      [key]: clearedData
    }
  }
};

export const getSelectedFilters = requestData => {
  const contact = [
    ...(requestData?.contact?.contactNameOrEmail ? [
      { label: "Name/Email", key: "contactNameOrEmail", items: [{ value: requestData?.contact?.contactNameOrEmail }] }
    ] : []),
    ...(requestData?.contact?.jobTitle ? [
      { label: "Job Title", key: "jobTitle", items: [{ value: requestData?.contact?.jobTitle }] }
    ] : []),
    ...(requestData?.contact?.departments?.length ? [
      { label: "Departments", key: "departments", items: requestData?.contact?.departments?.map(val => ({ value: val, item: val })) }
    ] : []),
    ...(requestData?.contact?.managementLevels?.length ? [
      { label: "Management Level", key: "managementLevels", items: requestData?.contact?.managementLevels?.map(val => ({ value: val, item: val })) }
    ] : [])
  ].map(val => ({ ...val, sectionKey: "contact" }));
  const company = [
    ...(requestData?.company?.companyName ? [
      { label: "Company Name", key: "companyName", items: [{ value: requestData?.company?.companyName }] }
    ] : []),
    ...(requestData?.company?.businessModels?.length ? [
      { label: "Business Models", key: "businessModels", items: requestData?.company?.businessModels?.map(val => ({ value: val, item: val })) }
    ] : []),
    ...(requestData?.company?.employeeCount?.min || requestData?.company?.employeeCount?.max ? [
      {
        label: "Employee Count",
        key: "employeeCount",
        items: [{
          value: `${requestData?.company?.employeeCount?.min || "---"} - ${requestData?.company?.employeeCount?.max || "---"}`
        }]
      }
    ] : []),
    ...(requestData?.company?.companyKeywords?.length ? [
      { label: "Keywords", key: "companyKeywords", items: requestData?.company?.companyKeywords?.map(val => ({ value: val, item: val })) }
    ] : [])
  ].map(val => ({ ...val, sectionKey: "company" }));
  const industry = [
    ...(requestData?.company?.industryType !== "any" ? [
      { label: "Industry Type", key: "industryType", items: [{ value: industryTypeOptions?.find(val => val.value === requestData?.company?.industryType)?.label }] }
    ] : []),
    ...(requestData?.company?.industries?.length ? [
      { label: "Industries", key: "industries", items: requestData?.company?.industries?.map(val => ({ value: val, item: val })) }
    ] : []),
    ...(requestData?.company?.industryKeywords?.length ? [
      { label: "Keywords", key: "industryKeywords", items: requestData?.company?.industryKeywords?.map(val => ({ value: val, item: val })) }
    ] : []),
    ...(requestData?.company?.industryCode ? [
      { label: "Classification Code", key: "industryCode", items: [{ value: getClassCode(requestData?.company?.industryCodeType, requestData?.company?.industryCode) }] }
    ] : [])
  ].map(val => ({ ...val, sectionKey: "company" }));
  const location = [
    ...(requestData?.location?.locationString ? [
      { label: "Location Name", key: "locationString", items: [{ value: requestData?.location?.locationString }] }
    ] : []),
    ...(requestData?.location?.districts?.length ? [
      { label: "Countries", key: "districts", items: getSelectedDistricts(requestData?.location?.districts) }
    ] : []),
    ...(requestData?.location?.postalCode ? [
      { label: "Postal Code", key: "postalCode", items: [{ value: requestData?.location?.postalCode }] }
    ] : []),
    ...(requestData?.location?.streetAddress ? [
      { label: "Street", key: "streetAddress", items: [{ value: requestData?.location?.streetAddress }] }
    ] : []),
    ...(requestData?.location?.suite ? [
      { label: "Suite", key: "suite", items: [{ value: requestData?.location?.suite }] }
    ] : [])
  ].map(val => ({ ...val, sectionKey: "location" }));
  const tags = [
    ...(requestData?.contact?.tags?.length ? [
      { label: "Contact Tags", key: "tags", sectionKey: "contact", items: requestData?.contact?.tags?.map(val => ({ value: val, item: val })) }
    ] : []),
    ...(requestData?.company?.tags?.length ? [
      { label: "Company Tags", key: "tags", sectionKey: "company", items: requestData?.company?.tags?.map(val => ({ value: val, item: val })) }
    ] : [])
  ];
  return { contact, company, industry, location, tags, summary: [...contact, ...company, ...industry, ...location, ...tags] };
};

const convertDistricts = arr => {
  const res = {};
  arr?.forEach(state => {
    const country = locationOptions?.find(val => val.children.some(child => child.value === state))?.value;
    if (res[country]) {
      res[country].push(state);
    } else {
      res[country] = [state];
    }
  });
  return Object.keys(res).map(country => ({ nesting: country, list: res[country] }));
}

export const convertFiltersForRequest = filters => {
  const filtersData = {};
  Object.keys(filters)?.forEach(sectionKey => { filtersData[sectionKey] = {} });
  Object.keys(filters)?.forEach(sectionKey => {
    Object.keys(filters[sectionKey])?.forEach(key => {
      if (["districts"].includes(key)) {
        return filtersData[sectionKey][key] = convertDistricts(filters[sectionKey][key]);
      }
      return filtersData[sectionKey][key] = filters[sectionKey][key];
    })
  });
  return filtersData;
};

export const convertRequestForFilters = filters => {
  const filtersData = {};
  Object.keys(filters)?.forEach(sectionKey => { filtersData[sectionKey] = {} });
  Object.keys(filters)?.forEach(sectionKey => {
    Object.keys(filters[sectionKey])?.forEach(key => {
      if (["districts"].includes(key)) {
        return filtersData[sectionKey][key] = filters[sectionKey][key].reduce((acc, curr) => ([...acc, ...curr.list]), []);
      }
      return filtersData[sectionKey][key] = filters[sectionKey][key];
    })
  });
  return filtersData;
};

export const jsonToBase64 = (obj = {}) => {
  return btoa(encodeURI(JSON.stringify(obj)))
};

export const base64ToJson = base64String => {
  const json = decodeURI(atob(base64String));
  return JSON.parse(json);
};

export const getEmploymentExperiences = employmentExperiences => {
  const companiesData = {
    currentCompanies: [],
    prevCompanies: [],
    lastCompany: {}
  };
  if (employmentExperiences?.length) {
    const sortedList = employmentExperiences.sort((a, b) => (new Date(b.from)).getTime() - (new Date(a.from)).getTime());
    const currentList = sortedList.filter(it => it?.isCurrent);
    companiesData.currentCompanies = currentList;
    companiesData.prevCompanies = sortedList.filter(it => it?.isCurrent === false);
    companiesData.lastCompany = currentList[0] || {};
  }
  return companiesData;
};
