import React, { useRef, useState } from "react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import "./styles.scss";

const StyledTooltip = ({ children, isOverflowContent, className, desktopOnly, ...rest }) => {
  const ref = useRef();
  const clientWidth = useSelector(state => state.ui.clientWidth);
  const [open, setOpen] = useState(false);

  if (desktopOnly && clientWidth < 1060) {
    return <>{children}</>
  }

  return (
    <Tooltip
      {...rest}
      ref={ref}
      PopperProps={{ className: `styled-tooltip ${className || ""}` }}
      onOpen={() => {
        if ((ref?.current?.scrollWidth > ref?.current?.clientWidth && isOverflowContent) || !isOverflowContent) {
          setOpen(true)
        }
      }}
      onClose={() => setOpen(false)}
      open={open}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: { marginTop: "4px" },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: { marginBottom: "4px" },
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: { marginLeft: "4px" },
            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: { marginRight: "4px" }
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
};

export default StyledTooltip;
