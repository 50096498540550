import React from "react";

const SettingsIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.6051 5.71814L15.1382 4.90807C14.7433 4.22263 13.8681 3.98617 13.1817 4.37946V4.37946C12.855 4.57194 12.4651 4.62654 12.0981 4.53124C11.7311 4.43594 11.417 4.19857 11.2252 3.87146C11.1018 3.66354 11.0355 3.42672 11.033 3.18496V3.18496C11.0441 2.79734 10.8979 2.42173 10.6276 2.14368C10.3573 1.86563 9.98596 1.70882 9.59819 1.70898L8.65769 1.70898C8.27779 1.70898 7.91355 1.86036 7.64556 2.12964C7.37758 2.39891 7.22794 2.76387 7.22977 3.14377V3.14377C7.21851 3.92812 6.57942 4.55804 5.79499 4.55795C5.55322 4.55544 5.3164 4.48914 5.10849 4.36574V4.36574C4.42211 3.97244 3.5469 4.2089 3.15197 4.89434L2.65082 5.71814C2.25637 6.40272 2.48961 7.27738 3.17256 7.67466V7.67466C3.61649 7.93096 3.88996 8.40462 3.88996 8.91722C3.88996 9.42982 3.61649 9.90349 3.17256 10.1598V10.1598C2.49048 10.5544 2.25698 11.4269 2.65082 12.1094V12.1094L3.12451 12.9264C3.30955 13.2603 3.62001 13.5066 3.98721 13.611C4.3544 13.7154 4.74804 13.6691 5.08103 13.4824V13.4824C5.40837 13.2914 5.79845 13.2391 6.16457 13.3371C6.53068 13.435 6.84249 13.6752 7.03068 14.0042C7.15408 14.2121 7.22039 14.4489 7.2229 14.6907V14.6907C7.2229 15.4831 7.86528 16.1255 8.65769 16.1255H9.59819C10.3879 16.1255 11.0292 15.4873 11.033 14.6975V14.6975C11.0311 14.3165 11.1817 13.9504 11.4512 13.681C11.7207 13.4115 12.0867 13.2609 12.4678 13.2628C12.7089 13.2692 12.9448 13.3353 13.1543 13.455V13.455C13.8388 13.8494 14.7135 13.6162 15.1108 12.9332V12.9332L15.6051 12.1094C15.7964 11.7811 15.8489 11.3899 15.751 11.0227C15.653 10.6555 15.4127 10.3424 15.0833 10.1529V10.1529C14.7539 9.9634 14.5136 9.65036 14.4157 9.28313C14.3177 8.91591 14.3703 8.52479 14.5616 8.1964C14.686 7.97918 14.8661 7.79908 15.0833 7.67466V7.67466C15.7622 7.2776 15.9949 6.40805 15.6051 5.725V5.725V5.71814Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="9.13117" cy="8.91657" r="1.97712" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default SettingsIcon;
