import React, { useState, useRef } from "react";

import SelectIcon from "assets/iconComponents/SelectIcon";

import CustomCheckbox from "shared/CustomCheckbox";

import "./styles.scss";
import {Button} from "@mui/material";

const ChildrenComponent = ({ data, value, onChange }) => {
  const listRef = useRef();
  const [open, setOpen] = useState(false);
  const [listHeight, setListHeight] = useState(0);

  const handleOpenChildrenList = isOpen => {
    setOpen(isOpen);
    setListHeight(isOpen ? `${listRef?.current?.clientHeight}px` : 0);
  };

  return (
    <div>
      <CustomCheckbox
        label={data.label}
        checked={data.children?.some(child => value?.includes(child.value))}
        onChange={() => {
          if (data.children?.some(child => value?.includes(child.value))) {
            return onChange(value?.filter(it => !data.children?.some(child => child.value === it)));
          }
          return onChange([...value, ...data.children?.map(child => child.value)]);
        }}
        rightContent={(
          <div
            className={`collapse-btn ${open ? "opened" : ""}`}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleOpenChildrenList(!open)
            }}
          />
        )}
      />
      <div className={`checkbox-list children-list ${open ? "opened" : ""}`} style={{ height: listHeight }}>
        <div ref={listRef} className="list-content children-content">
          {data.children?.map((child, index) => (
            <CustomCheckbox
              key={index}
              label={child.label}
              checked={value?.includes(child.value)}
              onChange={checked => {
                if (checked) {
                  return onChange([...(value || []), child.value]);
                }
                return onChange(value?.filter(it => it !== child.value));
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const CheckboxList = (
  { label,
    value,
    onChange,
    options,
    canCollapse = true,
    defaultAll = true,
    radioButton,
    onLabelBtnClick,
    labelBtnLabel
  }
) => {
  const listRef = useRef();
  const [open, setOpen] = useState(!canCollapse);
  const [listHeight, setListHeight] = useState(canCollapse ? 0 : "fit-content");

  const handleOpenList = isOpen => {
    setOpen(isOpen);
    setListHeight(isOpen ? `${listRef?.current?.clientHeight}px` : 0);
  };

  return (
    <div className="checkbox-list-wrapper">
      {label && (
        <div
          className={`checkbox-list-label ${open ? "opened" : ""} ${canCollapse ? "clickable" : ""}`}
          onClick={() => canCollapse ? handleOpenList(!open) : ""}
        >
          {label}
          <div className="right-part">
            {canCollapse && <SelectIcon className="arrow-icon" color="var(--main-text)" />}
            {onLabelBtnClick && (
              <Button
                className="label-btn"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onLabelBtnClick(e);
                }}
              >
                {labelBtnLabel}
              </Button>
            )}
          </div>
        </div>
      )}
      <div className={`checkbox-list ${open ? "opened" : ""}`} style={{ height: listHeight }}>
        <div ref={listRef} className="list-content">
          {!radioButton && defaultAll && (
            <CustomCheckbox
              label="All"
              checked={!value?.length}
              onChange={() => onChange([])}
            />
          )}
          {options?.map((val, i) => (
            <React.Fragment key={i}>
              {val.children ? (
                <ChildrenComponent data={val} value={value} onChange={onChange} />
              ) : (
                <CustomCheckbox
                  radioButton={radioButton}
                  label={val.label}
                  checked={radioButton ? value === val.value : value?.includes(val.value)}
                  onChange={checked => {
                    if (radioButton) {
                      return onChange(val.value);
                    }
                    if (checked) {
                      return onChange([...(value || []), val.value]);
                    }
                    return onChange(value?.filter(it => it !== val.value));
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CheckboxList;
