import React from "react";

const EmptySearch = () => (
  <svg width="100" height="81" viewBox="0 0 100 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path d="M87.7255 0.34668C81.1376 0.34668 75.7983 5.68828 75.7983 12.2739V44.2529C72.8058 44.1534 69.7716 45.1625 67.3646 47.3183C62.4084 51.7573 61.986 59.3705 66.425 64.3279C68.8864 67.0762 72.3244 68.4301 75.7439 68.3341C75.2532 73.273 71.4912 77.2468 66.6564 78.0591C66.0605 78.1575 65.4472 78.2119 64.8234 78.2119H59.5536C52.0307 78.2119 45.9334 72.1146 45.9334 64.5917V43.4151H22.8278V12.2739C22.8278 5.68712 28.1682 0.34668 34.7561 0.34668H87.7266H87.7255Z" fill="var(--primary-color)"/>
    </g>
    <path d="M64.8225 78.5595H59.5526C51.8503 78.5595 45.5853 72.2933 45.5853 64.5922V43.7627H22.4796V12.2743C22.4796 5.50592 27.9867 0 34.7551 0H87.7256V0.694316C81.3402 0.694316 76.1456 5.88895 76.1456 12.2743V44.6133L75.7869 44.6017C72.7631 44.4999 69.8539 45.5575 67.5963 47.578C65.268 49.6633 63.8909 52.5296 63.7185 55.6494C63.5461 58.7692 64.5991 61.7698 66.6832 64.0981C68.9849 66.6682 72.2875 68.0881 75.7336 67.9886L76.1282 67.977L76.0889 68.3693C75.5855 73.4332 71.7297 77.5597 66.7133 78.4022C66.085 78.5063 65.4485 78.5595 64.8225 78.5595ZM23.1728 43.0684H46.2784V64.5922C46.2784 71.9115 52.2334 77.8652 59.5514 77.8652H64.8213C65.4092 77.8652 66.0074 77.8155 66.5976 77.7171C71.1778 76.9476 74.7269 73.2619 75.3518 68.6864C71.8432 68.6725 68.5139 67.1832 66.1648 64.5598C61.6066 59.4693 62.0406 51.6189 67.1322 47.0607C69.4339 44.9998 72.3789 43.8819 75.4501 43.9004V12.2743C75.4501 6.93274 78.88 2.37687 83.6523 0.694316H34.754C28.3674 0.694316 23.1728 5.88895 23.1728 12.2743V43.0684Z" fill="var(--primary-color)"/>
    <g opacity="0.75">
      <path d="M99.6541 12.2739V43.4139H75.7986V12.2739C75.7986 5.68713 81.139 0.34668 87.7269 0.34668C91.0203 0.34668 94.0024 1.68208 96.1606 3.84025C98.3187 5.99841 99.6541 8.9805 99.6541 12.2739Z" fill="var(--primary-color)"/>
    </g>
    <path d="M99.9999 43.7625H75.4501V12.2753C75.4501 5.5069 80.9572 0.000976562 87.7256 0.000976562C91.0039 0.000976562 94.0867 1.27736 96.4045 3.59637C98.7224 5.91423 99.9999 8.99699 99.9999 12.2753V43.7625ZM76.1444 43.0682H99.3056V12.2753C99.3056 9.18215 98.101 6.27412 95.9139 4.08703C93.7268 1.89993 90.8188 0.695292 87.7256 0.695292C81.3402 0.695292 76.1444 5.88993 76.1444 12.2753V43.0682Z" fill="var(--primary-color)"/>
    <g opacity="0.75">
      <path d="M59.5515 78.2118H13.9673C6.44442 78.2118 0.347168 72.1134 0.347168 64.5917V43.415H45.9325V64.5917C45.9325 72.1146 52.0309 78.2118 59.5526 78.2118H59.5515Z" fill="var(--primary-color)"/>
    </g>
    <path d="M59.5515 78.5595H13.9673C6.26504 78.5595 0 72.2933 0 64.5921V43.0684H46.2796V64.5921C46.2796 71.9114 52.2345 77.8652 59.5526 77.8652V78.5595H59.5515ZM0.694316 43.7627V64.5921C0.694316 71.9114 6.64923 77.8652 13.9673 77.8652H55.1993C49.6228 76.0322 45.5841 70.7739 45.5841 64.5921V43.7627H0.694316Z" fill="var(--primary-color)"/>
    <path d="M37.0823 22.8822C37.0823 27.9426 34.1465 32.3168 29.8845 34.3916C28.1939 35.2179 26.2938 35.6796 24.2849 35.6796C17.2179 35.6796 11.4886 29.9503 11.4886 22.8822C11.4886 19.767 12.6019 16.9099 14.4545 14.6904C16.8002 11.8761 20.3331 10.0859 24.2849 10.0859C31.353 10.0859 37.0823 15.8152 37.0823 22.8822Z" fill="white"/>
    <path d="M24.2838 35.7387C17.196 35.7387 11.4297 29.9712 11.4297 22.8834C11.4297 19.8782 12.4874 16.9563 14.4095 14.6546C16.8592 11.7153 20.4581 10.0293 24.2838 10.0293C31.3728 10.0293 37.139 15.7956 37.139 22.8834C37.139 25.3401 36.4436 27.7274 35.129 29.7884C33.8491 31.7949 32.0439 33.4046 29.9077 34.4449C28.1523 35.3036 26.2591 35.7387 24.2826 35.7387H24.2838ZM24.2838 10.145C20.4917 10.145 16.9252 11.816 14.4974 14.7287C12.5938 17.0095 11.5454 19.9048 11.5454 22.8834C11.5454 29.9087 17.2596 35.6229 24.2838 35.6229C26.2429 35.6229 28.1176 35.1913 29.858 34.3408C34.2785 32.1884 37.0233 27.798 37.0233 22.8834C37.0233 15.8592 31.3079 10.145 24.2838 10.145Z" fill="black"/>
    <g opacity="0.25">
      <path d="M37.0824 22.8822C37.0824 27.9426 34.1466 32.3168 29.8847 34.3916C28.194 35.2179 26.2939 35.6796 24.285 35.6796C17.218 35.6796 11.4888 29.9503 11.4888 22.8822C11.4888 19.767 12.602 16.9099 14.4547 14.6904C16.8003 11.8761 20.3332 10.0859 24.285 10.0859C31.3531 10.0859 37.0824 15.8152 37.0824 22.8822Z" fill="var(--primary-color)"/>
    </g>
    <path d="M24.2837 36.0282C17.0362 36.0282 11.1403 30.1311 11.1403 22.8836C11.1403 19.8113 12.2222 16.8234 14.1871 14.4697C16.6913 11.4644 20.3723 9.74023 24.2837 9.74023C31.5323 9.74023 37.4282 15.6361 37.4282 22.8836C37.4282 25.3959 36.7177 27.8376 35.373 29.9448C34.0643 31.9965 32.2185 33.6432 30.0349 34.7055C28.2389 35.5838 26.3041 36.0282 24.2837 36.0282ZM24.2837 10.4346C20.5783 10.4346 17.0929 12.0674 14.7195 14.914C12.8587 17.1428 11.8334 19.9733 11.8334 22.8836C11.8334 29.7493 17.418 35.3339 24.2825 35.3339C26.1965 35.3339 28.0295 34.9127 29.7294 34.0806C34.0492 31.9769 36.7327 27.686 36.7327 22.8825C36.7327 16.018 31.147 10.4334 24.2825 10.4334L24.2837 10.4346Z" fill="var(--primary-color)"/>
    <path d="M30.1693 27.5527L28.9531 28.7677C28.5481 29.1727 27.8908 29.1727 27.4869 28.7677L24.285 25.5658L21.0819 28.7677C20.678 29.1727 20.0207 29.1727 19.6157 28.7677L18.3995 27.5527C17.9933 27.1465 17.9933 26.4904 18.3995 26.0854L21.6014 22.8846L18.3995 19.6814C17.9945 19.2764 17.9933 18.6203 18.3995 18.2153L19.6157 16.9991C20.0207 16.5941 20.678 16.5941 21.0819 16.9991L24.285 20.2022L27.4869 16.9991C27.8919 16.5941 28.5492 16.5941 28.9531 16.9991L30.1693 18.2153C30.5755 18.6215 30.5743 19.2776 30.1693 19.6814L26.9673 22.8846L30.1693 26.0854C30.5755 26.4904 30.5755 27.1477 30.1693 27.5527Z" fill="var(--primary-color)"/>
    <path d="M68.051 16.5415H43.3183C43.1263 16.5415 42.9712 16.3864 42.9712 16.1943C42.9712 16.0022 43.1263 15.8472 43.3183 15.8472H68.051C68.2431 15.8472 68.3982 16.0022 68.3982 16.1943C68.3982 16.3864 68.2431 16.5415 68.051 16.5415Z" fill="var(--primary-color)"/>
    <path d="M68.051 22.229H43.3183C43.1263 22.229 42.9712 22.0739 42.9712 21.8818C42.9712 21.6897 43.1263 21.5347 43.3183 21.5347H68.051C68.2431 21.5347 68.3982 21.6897 68.3982 21.8818C68.3982 22.0739 68.2431 22.229 68.051 22.229Z" fill="var(--primary-color)"/>
    <path d="M68.051 27.917H43.3183C43.1263 27.917 42.9712 27.7619 42.9712 27.5698C42.9712 27.3777 43.1263 27.2227 43.3183 27.2227H68.051C68.2431 27.2227 68.3982 27.3777 68.3982 27.5698C68.3982 27.7619 68.2431 27.917 68.051 27.917Z" fill="var(--primary-color)"/>
    <path d="M68.051 33.604H43.3183C43.1263 33.604 42.9712 33.4489 42.9712 33.2568C42.9712 33.0647 43.1263 32.9097 43.3183 32.9097H68.051C68.2431 32.9097 68.3982 33.0647 68.3982 33.2568C68.3982 33.4489 68.2431 33.604 68.051 33.604Z" fill="var(--primary-color)"/>
    <path d="M68.0511 39.2929H28.0411C27.849 39.2929 27.694 39.1379 27.694 38.9458C27.694 38.7537 27.849 38.5986 28.0411 38.5986H68.0511C68.2432 38.5986 68.3982 38.7537 68.3982 38.9458C68.3982 39.1379 68.2432 39.2929 68.0511 39.2929Z" fill="var(--primary-color)"/>
    <path d="M61.3926 50.165H46.3236C46.1315 50.165 45.9764 50.01 45.9764 49.8179C45.9764 49.6258 46.1315 49.4707 46.3236 49.4707H61.3926C61.5847 49.4707 61.7397 49.6258 61.7397 49.8179C61.7397 50.01 61.5847 50.165 61.3926 50.165Z" fill="var(--primary-color)"/>
    <path d="M61.3926 56.3559H46.3236C46.1315 56.3559 45.9764 56.2009 45.9764 56.0088C45.9764 55.8167 46.1315 55.6616 46.3236 55.6616H61.3926C61.5847 55.6616 61.7397 55.8167 61.7397 56.0088C61.7397 56.2009 61.5847 56.3559 61.3926 56.3559Z" fill="var(--primary-color)"/>
    <path d="M61.3926 62.5478H46.3236C46.1315 62.5478 45.9764 62.3928 45.9764 62.2007C45.9764 62.0086 46.1315 61.8535 46.3236 61.8535H61.3926C61.5847 61.8535 61.7397 62.0086 61.7397 62.2007C61.7397 62.3928 61.5847 62.5478 61.3926 62.5478Z" fill="var(--primary-color)"/>
    <path d="M35.1787 70.0981H5.24901C5.05692 70.0981 4.90186 69.9431 4.90186 69.751C4.90186 69.5589 5.05692 69.4038 5.24901 69.4038H35.1787C35.3708 69.4038 35.5258 69.5589 35.5258 69.751C35.5258 69.9431 35.3708 70.0981 35.1787 70.0981Z" fill="var(--primary-color)"/>
    <path d="M88.4341 63.7881L81.4111 70.0752L79.2842 67.6994C80.7735 67.1938 82.1876 66.3849 83.435 65.2693C84.6813 64.1538 85.6395 62.8381 86.3083 61.4136L88.4341 63.7881Z" fill="var(--primary-color)"/>
    <g opacity="0.75">
      <path d="M82.7443 68.8816L87.1023 64.9796L95.9275 74.8362C96.6207 75.6104 96.5557 76.8024 95.7807 77.4964L94.2288 78.8858C93.4547 79.579 92.2626 79.514 91.5686 78.7389L82.7434 68.8823L82.7443 68.8816Z" fill="var(--primary-color)"/>
    </g>
    <path d="M92.9749 79.7121C92.9332 79.7121 92.8916 79.7121 92.8487 79.7087C92.2539 79.6763 91.7077 79.4136 91.3097 78.9692L82.2523 68.8542L87.1276 64.4893L96.1838 74.6043C96.5807 75.0486 96.782 75.6203 96.7496 76.2151C96.7172 76.8099 96.4545 77.3561 96.0102 77.7542L94.4584 79.1428C94.0464 79.5119 93.5222 79.7121 92.9737 79.7121H92.9749ZM83.2336 68.9086L91.8269 78.5063C92.1012 78.8118 92.4773 78.9935 92.8869 79.0155C93.2954 79.0375 93.6912 78.8998 93.9967 78.6255L95.5485 77.2369C95.854 76.9626 96.0356 76.5866 96.0576 76.1769C96.0796 75.7673 95.9419 75.3727 95.6677 75.0672L87.0743 65.4694L83.2336 68.9086Z" fill="var(--primary-color)"/>
    <g opacity="0.75">
      <path d="M84.3735 48.2586C82.0915 45.7093 78.9683 44.36 75.7976 44.2547C72.8051 44.1552 69.7709 45.1643 67.364 47.3202C62.4077 51.7591 61.9853 59.3723 66.4243 64.3297C68.8857 67.0781 72.3237 68.432 75.7432 68.3359C76.9409 68.3024 78.1351 68.0918 79.283 67.6995C80.7735 67.1938 82.1876 66.3861 83.4339 65.2694C84.6802 64.1538 85.6383 62.8381 86.3072 61.4136C88.2941 57.1713 87.6947 51.9698 84.3724 48.2586H84.3735ZM81.8833 60.9681C81.5535 61.4275 81.1681 61.8568 80.7307 62.2491C80.2921 62.6425 79.8223 62.9781 79.3293 63.257C76.132 65.0657 71.9962 64.4767 69.4457 61.6277C66.4995 58.3389 66.7796 53.2855 70.0672 50.3416C73.3559 47.3965 78.4094 47.6754 81.3533 50.9642C83.9049 53.8132 84.0356 57.9906 81.8833 60.9681Z" fill="var(--primary-color)"/>
    </g>
    <path d="M75.4004 68.6865C71.8744 68.6865 68.5255 67.1948 66.166 64.5599C61.6078 59.4694 62.0418 51.619 67.1334 47.0608C69.5253 44.92 72.6081 43.7999 75.8101 43.9075C79.2226 44.0209 82.3551 45.4836 84.6325 48.0271C87.9548 51.7371 88.7359 57.0497 86.6229 61.5605C85.9135 63.0729 84.9195 64.4072 83.6674 65.5273C82.4142 66.6498 80.9769 67.4911 79.3962 68.028C78.2251 68.4272 76.9996 68.6483 75.7545 68.683C75.6365 68.6865 75.5184 68.6876 75.4016 68.6876L75.4004 68.6865ZM75.39 44.5937C72.5086 44.5937 69.7545 45.6444 67.5963 47.5769C65.268 49.6622 63.891 52.5286 63.7185 55.6484C63.5461 58.7682 64.5992 61.7688 66.6833 64.097C68.9849 66.6672 72.2876 68.0859 75.7337 67.9875C76.9105 67.9551 78.0666 67.7468 79.1717 67.3696C80.6633 66.8627 82.0196 66.0689 83.2034 65.0089C84.386 63.9512 85.3245 62.691 85.9934 61.2642C87.9884 57.0058 87.2501 51.9905 84.1141 48.4888C81.964 46.0876 79.0074 44.7071 75.7858 44.6006C75.6538 44.596 75.5208 44.5937 75.3888 44.5937H75.39Z" fill="var(--primary-color)"/>
    <g opacity="0.25">
      <path d="M81.8832 60.9678C81.5534 61.4272 81.168 61.8566 80.7306 62.2489C80.292 62.6423 79.8222 62.9779 79.3293 63.2568C76.1319 65.0655 71.9961 64.4765 69.4457 61.6274C66.4995 58.3387 66.7795 53.2852 70.0671 50.3413C73.3558 47.3963 78.4093 47.6752 81.3532 50.9639C83.9048 53.8129 84.0356 57.9904 81.8832 60.9678Z" fill="var(--primary-color)"/>
    </g>
    <path d="M75.3937 64.6328C74.9586 64.6328 74.5235 64.5992 74.0907 64.5309C72.2044 64.2324 70.4629 63.2835 69.1865 61.8578C67.7006 60.1984 66.9496 58.0588 67.0723 55.8346C67.195 53.6105 68.1762 51.5669 69.8357 50.0822C71.4951 48.5964 73.6359 47.8454 75.8589 47.968C78.083 48.0907 80.1266 49.072 81.6113 50.7326C84.208 53.6325 84.4406 58.0217 82.1644 61.1705C81.8184 61.653 81.4134 62.1032 80.9609 62.507C80.5119 62.9086 80.0213 63.2627 79.4994 63.5578C78.2496 64.2648 76.8274 64.6328 75.3925 64.6328H75.3937ZM75.389 48.6496C73.5063 48.6496 71.7126 49.3335 70.2985 50.5995C68.7768 51.9615 67.8777 53.8339 67.7654 55.8728C67.6532 57.9118 68.3417 59.8732 69.7037 61.3938C71.1884 63.0521 73.2945 63.9304 75.4226 63.9304C76.7036 63.9304 77.9927 63.6121 79.158 62.9525C79.6348 62.6818 80.0861 62.3577 80.498 61.9886C80.9135 61.6171 81.2838 61.2052 81.6008 60.7643C83.6873 57.8771 83.4743 53.8535 81.0928 51.1943C79.7308 49.6726 77.8585 48.7723 75.8195 48.66C75.676 48.6519 75.5314 48.6485 75.389 48.6485V48.6496Z" fill="var(--primary-color)"/>
    <g opacity="0.5">
      <g opacity="0.5">
        <path d="M6.96185 36.6803C7.90708 36.6803 8.67334 35.914 8.67334 34.9688C8.67334 34.0236 7.90708 33.2573 6.96185 33.2573C6.01663 33.2573 5.25037 34.0236 5.25037 34.9688C5.25037 35.914 6.01663 36.6803 6.96185 36.6803Z" fill="var(--primary-color)"/>
      </g>
      <path d="M91.3826 49.8164C92.1661 49.8164 92.8013 49.1813 92.8013 48.3977C92.8013 47.6142 92.1661 46.979 91.3826 46.979C90.5991 46.979 89.9639 47.6142 89.9639 48.3977C89.9639 49.1813 90.5991 49.8164 91.3826 49.8164Z" fill="var(--primary-color)"/>
      <path d="M91.0181 60.4932C91.6003 60.4932 92.0723 60.0212 92.0723 59.439C92.0723 58.8567 91.6003 58.3848 91.0181 58.3848C90.4359 58.3848 89.9639 58.8567 89.9639 59.439C89.9639 60.0212 90.4359 60.4932 91.0181 60.4932Z" fill="var(--primary-color)"/>
      <path d="M77.5413 79.6632C78.5038 79.6632 79.284 78.883 79.284 77.9205C79.284 76.958 78.5038 76.1777 77.5413 76.1777C76.5788 76.1777 75.7986 76.958 75.7986 77.9205C75.7986 78.883 76.5788 79.6632 77.5413 79.6632Z" fill="var(--primary-color)"/>
      <path d="M17.7492 6.16267C18.3704 6.16267 18.874 5.65908 18.874 5.03788C18.874 4.41667 18.3704 3.91309 17.7492 3.91309C17.128 3.91309 16.6244 4.41667 16.6244 5.03788C16.6244 5.65908 17.128 6.16267 17.7492 6.16267Z" fill="var(--primary-color)"/>
    </g>
  </svg>
);

export default EmptySearch;
