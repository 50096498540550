import React from "react";

const EmailsIcon = ({ color = "var(--main-text)" }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3508 7.2959L10.1518 9.8716C9.54635 10.3463 8.69768 10.3463 8.09227 9.8716L4.86572 7.2959" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.56593 3.125H12.6369C13.6564 3.13644 14.6268 3.56745 15.322 4.31765C16.0173 5.06786 16.3767 6.07177 16.3165 7.09559V11.9915C16.3767 13.0153 16.0173 14.0192 15.322 14.7694C14.6268 15.5196 13.6564 15.9506 12.6369 15.9621H5.56593C3.376 15.9621 1.90002 14.1805 1.90002 11.9915V7.09559C1.90002 4.90659 3.376 3.125 5.56593 3.125Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default EmailsIcon;
