import React, { useState } from "react";
import { Button } from "@mui/material";

import { useTableResize } from "hooks/tableResize";

import CustomCheckbox from "shared/CustomCheckbox";
import StyledTooltip from "shared/StyledTooltip";
import Pagination from "shared/Pagination";
import DefaultPopover, { PopoverItem } from "shared/DefaultPopover";

import SortArrows from "assets/iconComponents/SortArrows";
import ThreeDots from "assets/iconComponents/ThreeDots";
import EmptySearch from "assets/iconComponents/EmptySearch";

import "./styles.scss";

const TableRow = ({ row, columns, isLoading, selectedItems, setSelectedItems, itemIdKey, popoverOptions, onRowClick }) => {
  const [open, setOpen] = useState(null);
  return (
    <div className={`table-row ${onRowClick ? "clickable" : ""}`} onClick={() => onRowClick(row)}>
      {setSelectedItems && (
        <CustomCheckbox
          isLoading={isLoading}
          checked={selectedItems?.includes(row?.[itemIdKey])}
          onChange={(checked, e) => {
            e.preventDefault();
            e.stopPropagation();
            if (checked) {
              return setSelectedItems([...selectedItems, row?.[itemIdKey]]);
            }
            return setSelectedItems(selectedItems?.filter(val => val !== row?.[itemIdKey]));
          }}
        />
      )}
      {columns?.map((val, i) => (
        <div
          key={i}
          style={{ ...(val.style || {}) }}
          className="row-column"
        >
          {val.isComponent ? (
            val.getValue(row, isLoading)
          ) : (
            <>
              {isLoading ? (
                <div className="row-column-content">
                  <div className="row-column-loader" />
                </div>
              ) : (
                <StyledTooltip isOverflowContent title={val.getValue(row) || "- - -"}>
                  <div className="row-column-content">{val.getValue(row) || "- - -"}</div>
                </StyledTooltip>
              )}
            </>
          )}
        </div>
      ))}
      {setSelectedItems && !!popoverOptions?.length && (
        <Button className="three-dots-btn" onClick={e => {
          setOpen(e.currentTarget);
          e.preventDefault();
          e.stopPropagation();
        }}>
          <ThreeDots />
        </Button>
      )}
      <DefaultPopover state={open} setState={setOpen}>
        {popoverOptions?.map((val, i) => (
          <PopoverItem
            label={val.label}
            key={i}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              val.action([row[itemIdKey]], open);
              setOpen(null);
            }}
            icon={val.icon}
          />
        ))}
      </DefaultPopover>
    </div>
  )
};

const CustomTable = (
  {
    data,
    columns,
    isLoading,
    selectedItems,
    setSelectedItems,
    sortBy,
    sortDir,
    onSort,
    itemIdKey,
    onChangePage,
    popoverOptions,
    onRowClick
  }
) => {
  const [open, setOpen] = useState(null);
  const { onMouseDown, tableColumns, dragData } = useTableResize(columns);
  
  const tableRowHeight = 44;
  const paddinsAroundTable = 100;
  const loadersAmount = Math.ceil((document.documentElement.clientHeight - paddinsAroundTable) / tableRowHeight);
  
  return (
    <div className="custom-table-wrapper">
      <div className="custom-table-content">
        <div className="table-head">
          {setSelectedItems && (
            <CustomCheckbox
              isLoading={isLoading}
              checked={!!data?.data?.length && selectedItems?.length === data?.data?.length}
              onChange={checked => {
                if (checked) {
                  return setSelectedItems(data?.data?.map(val => val[itemIdKey]));
                }
                return setSelectedItems([]);
              }}
            />
          )}
          {tableColumns?.map((val, i) => (
            <div
              key={i}
              ref={val.ref}
              style={{ ...(val.style || {}) }}
              className={`head-column ${val.sortKey && !isLoading ? "clickable" : ""}`}
              onClick={() => {
                if (val.sortKey && !isLoading && onSort) {
                  onSort(val.sortKey, sortDir === "asc" ? "desc" : "asc");
                }
              }}
            >
              <StyledTooltip isOverflowContent title={val.label}>
                <div className={`head-column-label ${val.sortKey && onSort ? "with-arrows" : ""} ${isLoading ? "loading" : ""}`}>
                  {val.label}
                  {isLoading && <div className="label-loader" />}
                </div>
              </StyledTooltip>
              {val.sortKey && onSort && (
                <SortArrows
                  className="sort-arrows"
                  topActive={val.sortKey === sortBy && sortDir === "asc"}
                  bottomActive={val.sortKey === sortBy && sortDir === "desc"}
                />
              )}
              <div
                className={`drag-bar ${dragData?.column?.label === val?.label ? "active" : ""}`}
                onMouseDown={e => onMouseDown(e, val)}
              />
            </div>
          ))}
          {setSelectedItems && !!popoverOptions?.length && (
            <Button className="three-dots-btn" disabled={!selectedItems?.length} onClick={e => setOpen(e.currentTarget)}>
              <ThreeDots />
            </Button>
          )}
          <DefaultPopover state={open} setState={setOpen}>
            {popoverOptions?.filter(val => !val.disabledMulti)?.map((val, i) => (
              <PopoverItem
                label={val.multiLabel}
                key={i}
                onClick={() => {
                  val.action(selectedItems, open);
                  setOpen(null);
                }}
                icon={val.icon}
              />
            ))}
          </DefaultPopover>
        </div>
        <div className="table-body">
          {isLoading && !data?.data?.length ? (
            new Array(loadersAmount <= 20 ? loadersAmount : 20).fill(0).map((_, i) => (
              <TableRow
                key={i}
                columns={tableColumns}
                isLoading={isLoading}
                setSelectedItems={setSelectedItems}
              />
            ))
          ) : (
            <>
              {data?.data?.length ? (
                data?.data?.map((val, i) => (
                  <TableRow
                    key={i}
                    row={val}
                    columns={tableColumns}
                    isLoading={isLoading}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    itemIdKey={itemIdKey}
                    popoverOptions={popoverOptions}
                    onRowClick={onRowClick}
                  />
                ))
              ) : (
                <div className="nothing-found-wrapper">
                  <div className="nothing-found">
                    <EmptySearch />
                    Nothing found :(
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {onChangePage && (
        <Pagination
          isLoading={isLoading}
          onChange={onChangePage}
          page={data?.page}
          totalItems={data?.totalItems}
          limit={data?.limit}
          pageItemsCount={data?.data?.length}
        />
      )}
    </div>
  )
};

export default React.memo(CustomTable);
