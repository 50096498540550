import React from "react";
import { Button } from "@mui/material";

import StyledTooltip from "shared/StyledTooltip";

import CloseIcon from "assets/iconComponents/CloseIcon";

import "./styles.scss";

const LegendItem = ({ label, prefix, onRemove, onClick, className, isLoading, tooltipContent }) => {
  return (
    <div
      className={`legend-item-wrapper ${onClick ? "clickable" : ""} ${className || ""} ${isLoading ? "loader" : ""}`}
      onClick={e => onClick ? onClick(e) : null}
    >
      {isLoading ? (
        <div className="legend-item-loader" />
      ) : (
        <>
          <StyledTooltip isOverflowContent={!tooltipContent} title={tooltipContent || label}>
            <div className={`item-label ${onRemove ? "with-btn" : ""}`}>
              {prefix && <span>{prefix}</span>} {label}
            </div>
          </StyledTooltip>
          {onRemove && (
            <Button
              className="remove-tem-btn"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onRemove(e);
              }}
            >
              <CloseIcon />
            </Button>
          )}
        </>
      )}
    </div>
  )
};

export default LegendItem;
