import React from "react";

const WorkIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.10303 11.6074C2.10303 11.6074 2.20953 12.9109 2.23428 13.3219C2.26728 13.8732 2.48028 14.4889 2.83578 14.9164C3.33753 15.5224 3.92853 15.7362 4.71753 15.7377C5.64528 15.7392 12.3915 15.7392 13.3193 15.7377C14.1083 15.7362 14.6993 15.5224 15.201 14.9164C15.5565 14.4889 15.7695 13.8732 15.8033 13.3219C15.8273 12.9109 15.9338 11.6074 15.9338 11.6074" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.37195 3.99675V3.7185C6.37195 2.8035 7.11295 2.0625 8.02795 2.0625H9.96445C10.8787 2.0625 11.6204 2.8035 11.6204 3.7185L11.6212 3.99675" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.99622 12.5086V11.5381" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.06238 6.29155V8.8918C3.50088 9.84055 5.22438 10.505 7.11588 10.7683C7.34238 9.94255 8.08713 9.3373 8.99238 9.3373C9.88338 9.3373 10.6431 9.94255 10.8546 10.7758C12.7536 10.5125 14.4839 9.84805 15.9299 8.8918V6.29155C15.9299 5.02105 14.9076 3.99805 13.6371 3.99805H4.36263C3.09213 3.99805 2.06238 5.02105 2.06238 6.29155Z" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default WorkIcon;
