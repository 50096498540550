import { call, debounce } from "redux-saga/effects";
import { logout } from "redux/actions/auth";
import { DataSysAuth0Client } from "security/DataSysAuth0Client";

function* logoutSaga() {
  yield call(DataSysAuth0Client.logoutAsync);
}

export default function* handleLogoutSaga() {
  yield debounce(300, logout.toString(), logoutSaga);
}
