import React from "react";

const LocationFilterIcon = ({ color = "var(--second-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.17934 7.79385C3.19014 4.61341 5.77716 2.04392 8.9576 2.05472C12.138 2.06553 14.7075 4.65254 14.6967 7.83298V7.8982C14.6576 9.96559 13.5032 11.8765 12.088 13.3699C11.2787 14.2104 10.3748 14.9545 9.39455 15.5873C9.13243 15.8141 8.74363 15.8141 8.48151 15.5873C7.02014 14.6361 5.73754 13.4352 4.69238 12.0395C3.76085 10.8224 3.23196 9.34518 3.17934 7.81342L3.17934 7.79385Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <ellipse cx="8.93809" cy="7.90425" rx="1.84565" ry="1.84565" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default LocationFilterIcon;
