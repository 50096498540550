import React from "react";

import { prompts } from "utils/chat";

import CircleLogo from "assets/iconComponents/CircleLogo";
import StarsIcon from "assets/iconComponents/StarsIcon";

import "./styles.scss";

const WelcomeState = ({ onPromptSelect }) => {
  return (
    <div className="chat-welcome-state-wrapper">
      <div className="welcome-state-content">
        <div className="text-box">
          <CircleLogo />
          <div className="title">Welcome</div>
          <div className="subtitle">
            To get started, tell me what type of company or customer
            < br />
            you are in the market for or choose a suggestion.
          </div>
        </div>
        <div className="prompts-list">
          {prompts?.map((val, i) => (
            <div key={i} className="prompt-card">
              <div className="card-title">
                {val.icon}
                {val.label}
              </div>
              {val?.items?.map((item, index) => (
                <div className="prompt-item" key={index} onClick={() => onPromptSelect(item.text)}>
                  {item.text}
                </div>
              ))}
            </div>
          ))}
        </div>
        <StarsIcon className="icon" />
      </div>
    </div>
  )
};

export default WelcomeState;
