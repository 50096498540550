import { handleActions } from "redux-actions";
import { setDrawerState } from "redux/actions/detailsDrawer";

const InitialState = {
  open: false, // Drawer open/close state,
  isCompany: false,
  id: "",
  mainRequestFinished: false,
  data: {}
};

const handleSetDrawerState = (state, { payload }) => ({
  ...state,
  ...payload
});

export default handleActions(
  {
    [setDrawerState]: handleSetDrawerState,
  },
  {
    ...InitialState
  }
);
