import React from "react";

const EyeIcon = ({ color = "var(--primary-color)" }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5833 9.5C11.5833 10.0967 11.3463 10.669 10.9243 11.091C10.5023 11.5129 9.93005 11.75 9.33331 11.75C8.73658 11.75 8.16428 11.5129 7.74232 11.091C7.32037 10.669 7.08331 10.0967 7.08331 9.5C7.08331 8.90326 7.32037 8.33097 7.74232 7.90901C8.16428 7.48705 8.73658 7.25 9.33331 7.25C9.93005 7.25 10.5023 7.48705 10.9243 7.90901C11.3463 8.33097 11.5833 8.90326 11.5833 9.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.83331 9.5C3.03331 6.42725 5.83531 4.25 9.33331 4.25C12.8313 4.25 15.6333 6.42725 16.8333 9.5C15.6333 12.5728 12.8313 14.75 9.33331 14.75C5.83531 14.75 3.03331 12.5728 1.83331 9.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default EyeIcon;
