import React from "react";

const NoOrgChartIcon = () => (
  <svg width="100" height="83" viewBox="0 0 100 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75">
      <path d="M69.8816 73.8693C69.7513 75.7933 68.5796 77.2993 67.1547 77.2993H6.63516C5.12174 77.2993 3.89478 75.6015 3.89478 73.5078V64.9707C4.00051 64.9793 4.10869 64.9842 4.21688 64.9842H6.96833V66.6944C6.96833 68.8188 8.69075 70.5412 10.8152 70.5412H51.5828C57.0575 74.1459 63.6914 75.2548 69.8803 73.8693H69.8816Z" fill="#1F4FFF" />
    </g>
    <path d="M67.1546 77.6686H6.63631C4.92126 77.6686 3.5271 75.8023 3.5271 73.5082V64.5703L3.92543 64.6023C4.02625 64.6109 4.1246 64.6146 4.21804 64.6146H7.33831V66.6935C7.33831 68.6114 8.89845 70.1716 10.8163 70.1716H51.6946L51.7869 70.2318C57.0512 73.6988 63.6176 74.8925 69.8016 73.5082L70.2835 73.4L70.2503 73.893C70.104 76.0445 68.7738 77.6674 67.1559 77.6674L67.1546 77.6686ZM4.26475 65.3535V73.5082C4.26475 75.3954 5.3282 76.9309 6.63631 76.9309H67.1546C68.2427 76.9309 69.193 75.8257 69.4537 74.3381C63.2623 75.5835 56.7451 74.3455 51.4734 70.9104H10.8163C8.49151 70.9104 6.60066 69.0196 6.60066 66.6948V65.3535H4.26475Z" fill="#1F4FFF" />
    <g opacity="0.5">
      <path d="M93.0317 4.21503V5.92515H10.8153C8.69085 5.92515 6.96843 7.64757 6.96843 9.77202V64.9841H4.21698C4.10879 64.9841 4.0006 64.9792 3.89487 64.9706C1.92165 64.8071 0.370117 63.1535 0.370117 61.1373V4.21503C0.370117 2.09058 2.09254 0.368164 4.21698 0.368164H89.1848C91.3093 0.368164 93.0317 2.09058 93.0317 4.21503Z" fill="#1F4FFF" />
    </g>
    <path d="M7.33719 65.3536H4.21692C4.10381 65.3536 3.98456 65.3487 3.86408 65.3389C1.69783 65.1594 0 63.314 0 61.138V4.21569C0 1.89085 1.89085 0 4.21569 0H89.1835C91.5084 0 93.3992 1.89085 93.3992 4.21569V6.29464H10.8165C8.89856 6.29464 7.33842 7.85478 7.33842 9.77268V65.3536H7.33719ZM4.21692 0.737653C2.29902 0.737653 0.738882 2.29779 0.738882 4.21569V61.138C0.738882 62.9329 2.13797 64.4562 3.92555 64.6037C4.02513 64.6123 4.12348 64.616 4.21692 64.616H6.59954V9.77268C6.59954 7.44784 8.49039 5.55699 10.8152 5.55699H92.6628V4.21569C92.6628 2.29779 91.1027 0.737653 89.1848 0.737653H4.21815H4.21692Z" fill="#1F4FFF" />
    <g opacity="0.25">
      <path d="M99.6311 9.77264V66.6949C99.6311 68.8194 97.9086 70.5418 95.7842 70.5418H77.7326C77.8986 70.4324 78.0633 70.3217 78.2268 70.2074C79.3763 69.4082 80.4717 68.496 81.4958 67.4719C82.2815 66.6863 83.0019 65.8601 83.6535 64.9971C83.8527 64.7364 84.0457 64.4709 84.2313 64.2029C84.2448 64.1844 84.2584 64.1635 84.2719 64.1439C89.8695 56.037 89.8707 45.2316 84.2719 37.1236C83.4629 35.952 82.5372 34.8369 81.4958 33.7943C72.1965 24.4962 57.1189 24.4962 47.8195 33.7956C38.5202 43.0961 38.5202 58.1725 47.8195 67.4719C48.9899 68.6423 50.2525 69.6664 51.584 70.5418H10.8151C8.69068 70.5418 6.96826 68.8194 6.96826 66.6949V9.77264C6.96826 7.6482 8.69068 5.92578 10.8151 5.92578H95.783C97.9074 5.92578 99.6298 7.6482 99.6298 9.77264H99.6311Z" fill="#1F4FFF" />
    </g>
    <path d="M95.7831 70.9103H76.5021L77.5287 70.2341C77.718 70.1087 77.8729 70.0042 78.0143 69.9059C79.1589 69.1104 80.242 68.2043 81.2342 67.2122C82.005 66.4413 82.7205 65.6213 83.3586 64.7755C83.5541 64.5197 83.7446 64.2567 83.9278 63.9936C83.9401 63.9763 83.9463 63.9677 83.9524 63.9591L83.9684 63.9358C89.4614 55.9802 89.4614 45.2891 83.9684 37.3348C83.1643 36.1705 82.2447 35.0677 81.2354 34.0571C72.0946 24.9176 57.2211 24.9176 48.0803 34.0583C38.9408 43.1991 38.9408 58.0727 48.0803 67.2134C49.2151 68.3482 50.4617 69.3649 51.787 70.2353L52.8173 70.9128H10.8165C8.49168 70.9128 6.60083 69.0219 6.60083 66.6971V9.77233C6.60083 7.44749 8.49168 5.55664 10.8165 5.55664H95.7844C98.1092 5.55664 100 7.44749 100 9.77233V66.6946C100 69.0194 98.1092 70.9103 95.7844 70.9103H95.7831ZM78.9093 70.1726H95.7831C97.701 70.1726 99.2612 68.6125 99.2612 66.6946V9.77233C99.2612 7.85443 97.701 6.29429 95.7831 6.29429H10.8165C8.89862 6.29429 7.33848 7.85443 7.33848 9.77233V66.6946C7.33848 68.6125 8.89862 70.1726 10.8165 70.1726H50.4052C49.3995 69.4374 48.4455 68.6199 47.5578 67.7322C38.1306 58.305 38.1306 42.9631 47.5578 33.5346C56.9863 24.1062 72.327 24.1062 81.7554 33.5334C82.7968 34.5759 83.7459 35.7132 84.5745 36.9143C87.3641 40.9542 88.8381 45.6985 88.8381 50.6347C88.8381 55.5696 87.3641 60.3139 84.5745 64.3538L84.5634 64.3698C84.5511 64.387 84.5388 64.4054 84.5278 64.4214C84.3458 64.6857 84.1479 64.9574 83.9463 65.2205C83.2885 66.091 82.5521 66.9368 81.7567 67.7322C80.8715 68.6174 79.9162 69.4374 78.9106 70.1726H78.9093Z" fill="#1F4FFF" />
    <g opacity="0.75">
      <path d="M95.7842 5.92578H10.8151C8.69068 5.92578 6.96826 7.6482 6.96826 9.77264V16.6968H99.6298V9.77264C99.6298 7.6482 97.9074 5.92578 95.783 5.92578H95.7842ZM13.017 13.097C12.2658 13.097 11.6573 12.4884 11.6573 11.7373C11.6573 10.9861 12.2658 10.3775 13.017 10.3775C13.7682 10.3775 14.378 10.9861 14.378 11.7373C14.378 12.4884 13.7682 13.097 13.017 13.097ZM19.0018 13.097C18.2507 13.097 17.6421 12.4884 17.6421 11.7373C17.6421 10.9861 18.2507 10.3775 19.0018 10.3775C19.753 10.3775 20.3628 10.9861 20.3628 11.7373C20.3628 12.4884 19.753 13.097 19.0018 13.097ZM24.9867 13.097C24.2355 13.097 23.6269 12.4884 23.6269 11.7373C23.6269 10.9861 24.2355 10.3775 24.9867 10.3775C25.7379 10.3775 26.3476 10.9861 26.3476 11.7373C26.3476 12.4884 25.7379 13.097 24.9867 13.097Z" fill="#1F4FFF" />
    </g>
    <path d="M99.6298 16.3281H6.96826V17.0658H99.6298V16.3281Z" fill="#1F4FFF" />
    <g opacity="0.25">
      <path d="M79.9996 59.9731C79.2706 61.1693 78.3891 62.2967 77.3539 63.3319C76.32 64.3658 75.1914 65.2473 73.9951 65.9776C67.0821 70.1909 57.9377 69.3094 51.9602 63.3307C44.9488 56.3205 44.9488 44.9508 51.9602 37.9382C58.9728 30.9256 70.3425 30.9256 77.3539 37.9382C83.3326 43.9169 84.2129 53.0613 79.9996 59.9743V59.9731Z" fill="#1F4FFF" />
    </g>
    <path d="M64.6381 68.9574C63.9533 68.9574 63.2686 68.9193 62.585 68.8419C58.4271 68.371 54.6626 66.5551 51.6985 63.591C44.5543 56.4468 44.5543 44.8226 51.6985 37.6772C55.1593 34.2164 59.761 32.3096 64.6566 32.3096C69.5521 32.3096 74.1539 34.2152 77.6147 37.676C80.5788 40.6401 82.3947 44.4046 82.8656 48.5625C83.3217 52.5975 82.4156 56.7173 80.3145 60.1646C79.5596 61.4014 78.6523 62.5546 77.6147 63.5922C76.5783 64.6286 75.4251 65.5372 74.1871 66.292C71.3237 68.0378 67.9957 68.9574 64.6381 68.9574ZM64.6566 33.0472C59.959 33.0472 55.5416 34.8766 52.2197 38.1985C45.3632 45.0562 45.3632 56.2132 52.2197 63.0697C57.9796 68.8308 66.8548 69.8967 73.8022 65.6626C74.9911 64.9384 76.0976 64.0668 77.0922 63.071C78.0868 62.0751 78.9597 60.9674 79.6838 59.781C83.9192 52.8335 82.8533 43.9584 77.0922 38.1973C73.7703 34.8754 69.3542 33.0472 64.6566 33.0472Z" fill="#1F4FFF" />
    <g opacity="0.75">
      <path d="M84.2709 37.123C83.4619 35.9514 82.5362 34.8363 81.4948 33.7938C72.1955 24.4956 57.1179 24.4956 47.8185 33.795C38.5192 43.0956 38.5192 58.172 47.8185 67.4713C48.9889 68.6417 50.2515 69.6658 51.583 70.5412C57.0576 74.1459 63.6916 75.2548 69.8805 73.8692C72.509 73.2816 75.0576 72.2439 77.4021 70.7551C77.5127 70.685 77.6222 70.6137 77.7316 70.5412C77.8976 70.4318 78.0623 70.3211 78.2258 70.2068C79.3753 69.4077 80.4707 68.4954 81.4948 67.4713C82.2804 66.6857 83.0009 65.8596 83.6525 64.9965C83.8517 64.7359 84.0447 64.4703 84.2303 64.2023C84.2438 64.1838 84.2574 64.1629 84.2709 64.1433C89.8684 56.0365 89.8697 45.2311 84.2709 37.123ZM79.9986 59.9718C79.2696 61.1668 78.3881 62.2955 77.3542 63.3294C76.319 64.3646 75.1916 65.2461 73.9954 65.9751C67.0823 70.1896 57.9379 69.3081 51.9592 63.3294C44.9478 56.3168 44.9478 44.9483 51.9592 37.9369C58.9718 30.923 70.3428 30.923 77.3542 37.9344C83.3316 43.9131 84.2131 53.0588 79.9986 59.9718Z" fill="#1F4FFF" />
    </g>
    <path d="M64.6713 74.8299C58.4381 74.8299 52.2455 72.4203 47.5589 67.7325C38.1317 58.3053 38.1317 42.9645 47.5589 33.5349C52.1262 28.9675 58.1983 26.4521 64.6577 26.4521C71.1171 26.4521 77.1892 28.9675 81.7565 33.5349C82.7991 34.5774 83.747 35.7134 84.5756 36.9145C87.3652 40.9544 88.8392 45.6988 88.8392 50.6349C88.8392 55.5698 87.3652 60.3129 84.5768 64.3528C84.5621 64.3749 84.5461 64.397 84.5314 64.4192C83.715 65.5945 82.7807 66.712 81.7565 67.7337C80.7349 68.7553 79.6174 69.6909 78.4371 70.511C74.2657 73.4099 69.4562 74.8299 64.6713 74.8299ZM64.6577 27.1898C58.3951 27.1898 52.5086 29.629 48.0802 34.0561C38.9407 43.1969 38.9407 58.0704 48.0802 67.21C56.1133 75.243 68.7038 76.3765 78.0166 69.9036C79.1612 69.1082 80.2444 68.2021 81.2353 67.2112C82.2274 66.2203 83.1335 65.1372 83.9277 63.9926C83.9425 63.9717 83.9548 63.9544 83.9658 63.9372L83.9683 63.9336C89.4613 55.978 89.4613 45.2869 83.9683 37.3313C83.1655 36.167 82.2459 35.0655 81.2353 34.0549C76.8069 29.6265 70.9204 27.1886 64.6577 27.1886V27.1898Z" fill="#1F4FFF" />
    <path d="M94.3521 78.5505L91.2024 81.7003C90.4143 82.4895 89.1369 82.4895 88.3489 81.7003L77.4033 70.7547C77.6812 70.5789 77.9553 70.3957 78.227 70.2064C79.3765 69.4073 80.472 68.495 81.4961 67.4709C82.2817 66.6853 83.0021 65.8579 83.6537 64.9961L94.3534 75.6958C95.1414 76.4838 95.1414 77.7612 94.3534 78.548L94.3521 78.5505Z" fill="#1F4FFF" />
    <path d="M76.791 60.3117L74.4022 62.6993C73.6068 63.4947 72.3159 63.4947 71.5205 62.6993L65.232 56.4108L58.941 62.6993C58.1468 63.4947 56.8572 63.4947 56.0617 62.6993L53.6717 60.3117C52.8751 59.5151 52.8751 58.2242 53.6717 57.4287L59.9614 51.1415L53.6717 44.8505C52.8763 44.0551 52.8751 42.7666 53.6717 41.97L56.0617 39.58C56.8572 38.7845 58.1468 38.7845 58.941 39.58L65.232 45.8709L71.5205 39.58C72.3159 38.7845 73.6068 38.7845 74.4022 39.58L76.791 41.97C77.5889 42.7666 77.5864 44.0551 76.791 44.8505L70.5025 51.1415L76.791 57.4287C77.5889 58.2242 77.5889 59.5151 76.791 60.3117Z" fill="#1F4FFF" />
    <g opacity="0.5">
      <path d="M35.6422 66.0984C36.3918 66.0984 36.9995 65.4907 36.9995 64.7411C36.9995 63.9915 36.3918 63.3838 35.6422 63.3838C34.8926 63.3838 34.2849 63.9915 34.2849 64.7411C34.2849 65.4907 34.8926 66.0984 35.6422 66.0984Z" fill="#1F4FFF" />
      <path d="M30.0827 82.2928C30.7223 82.2928 31.2408 81.7743 31.2408 81.1347C31.2408 80.4951 30.7223 79.9766 30.0827 79.9766C29.4431 79.9766 28.9246 80.4951 28.9246 81.1347C28.9246 81.7743 29.4431 82.2928 30.0827 82.2928Z" fill="#1F4FFF" />
      <path d="M74.4858 81.4829C75.378 81.4829 76.1013 80.7596 76.1013 79.8674C76.1013 78.9752 75.378 78.252 74.4858 78.252C73.5936 78.252 72.8704 78.9752 72.8704 79.8674C72.8704 80.7596 73.5936 81.4829 74.4858 81.4829Z" fill="#1F4FFF" />
      <path d="M96.5772 3.49531C97.1361 3.49531 97.5891 3.0423 97.5891 2.48349C97.5891 1.92468 97.1361 1.47168 96.5772 1.47168C96.0184 1.47168 95.5654 1.92468 95.5654 2.48349C95.5654 3.0423 96.0184 3.49531 96.5772 3.49531Z" fill="#1F4FFF" />
      <path d="M89.6138 35.9044C91.0248 35.9044 92.1686 34.7606 92.1686 33.3497C92.1686 31.9387 91.0248 30.7949 89.6138 30.7949C88.2029 30.7949 87.0591 31.9387 87.0591 33.3497C87.0591 34.7606 88.2029 35.9044 89.6138 35.9044Z" fill="#1F4FFF" />
      <path d="M10.589 81.4822C11.4187 81.4822 12.0914 80.8096 12.0914 79.9799C12.0914 79.1502 11.4187 78.4775 10.589 78.4775C9.7593 78.4775 9.08667 79.1502 9.08667 79.9799C9.08667 80.8096 9.7593 81.4822 10.589 81.4822Z" fill="#1F4FFF" />
      <path d="M97.2865 75.0633C97.6783 75.0633 97.9959 74.7457 97.9959 74.3539C97.9959 73.9621 97.6783 73.6445 97.2865 73.6445C96.8947 73.6445 96.5771 73.9621 96.5771 74.3539C96.5771 74.7457 96.8947 75.0633 97.2865 75.0633Z" fill="#1F4FFF" />
    </g>
  </svg>

);

export default NoOrgChartIcon;
