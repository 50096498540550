import React, { useState, useEffect } from "react";

import StyledTooltip from "shared/StyledTooltip";
import LegendItem from "shared/LegendItem";

import TagsTooltipIcon from "assets/iconComponents/TagsTooltipIcon";

import "./styles.scss";

const NameColumn = ({ avatar, title, subtitle, abbreviation, isLoading, tags }) => {
  const [avatarError, setAvatarError] = useState(false);

  useEffect(() => {
    if (avatar) {
      setAvatarError(false);
    }
  }, [avatar])

  return (
    <div className="name-column-wrapper">
      <div className="name-column-content">
        {isLoading ? (
          <>
            <div className="row-avatar-wrapper">
              <div className="avatar-loader" />
            </div>
            <div className="text-box">
              <div className="title-loader">
                <div />
              </div>
              <div className="subtitle-loader">
                <div />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row-avatar-wrapper">
              {!!tags?.length && (
                <StyledTooltip
                  className="tags-tooltip-wrapper"
                  title={(
                    <div className="tags-list">
                      <div className="label">Tags</div>
                      {tags?.map((val, i) => <LegendItem key={i} label={val.tagName} />)}
                    </div>
                  )}
                >
                  <div className="tags-icon-box">
                    <TagsTooltipIcon />
                  </div>
                </StyledTooltip>
              )}
              {avatar && !avatarError ? (
                <img src={avatar} alt="" className="avatar" onError={() => setAvatarError(true)} />
              ) : (
                <div className="default-avatar">
                  {abbreviation}
                </div>
              )}
            </div>
            <div className="text-box">
              {title && (
                <StyledTooltip isOverflowContent title={title}>
                  <div className="name-column-title">{title}</div>
                </StyledTooltip>
              )}
              {subtitle && (
                <StyledTooltip isOverflowContent title={subtitle}>
                  <div className="name-column-subtitle">{subtitle}</div>
                </StyledTooltip>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
};

export default NameColumn;
