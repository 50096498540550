import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { base64ToJson, convertRequestForFilters, getSelectedFilters } from "helpers/searchHelper";
import { AdvancedSearchService } from "services/AdvancedSearchService";
import { setSavedSearches } from "redux/actions/filters";

import SavedSearchItem from "./SavedSearchItem";
import "./styles.scss";

const getList = arr => {
  const res = arr?.map(val => {
    const filter = convertRequestForFilters(base64ToJson(val.searchQuery))
    return {
      ...val,
      filter,
      items: getSelectedFilters(filter)?.summary || []
    }
  });
  return res?.sort((a, b) => (
    a.isFavorite === b.isFavorite ? new Date(b.createdAt) - new Date(a.createdAt) : b.isFavorite - a.isFavorite
  ))
};

const SavedSearchesList = ({ setRequestFilter }) => {
  const dispatch = useDispatch();
  const savedSearches = useSelector(state => state.filters.savedSearches);
  const base64UrlQuery = new URLSearchParams(window.location.search).get("q");

  const [searchedList, setSearchedList] = useState(getList(savedSearches));
  const [selected, setSelected] = useState(searchedList?.find(val => val.searchQuery === base64UrlQuery)?.id);

  useEffect(() => {
    setSearchedList(getList(savedSearches))
  }, [savedSearches]);

  const handlePinSearch = async (item) => {
    if (item?.isFavorite) {
      await AdvancedSearchService.unpinSavedSearch(item.id);
    } else {
      await AdvancedSearchService.pinSavedSearch(item.id);
    }
    const searches = await AdvancedSearchService.getSavedSearches();
    dispatch(setSavedSearches(searches));
  };

  const handleRemoveSearch = async (item) => {
    await AdvancedSearchService.removeSavedSearch(item.id);
    const searches = await AdvancedSearchService.getSavedSearches();
    dispatch(setSavedSearches(searches));
  };

  return (
    <div className="saved-searches-list">
      {searchedList?.map(val => (
        <SavedSearchItem
          key={val.id}
          search={val}
          onPin={() => handlePinSearch(val)}
          onDelete={() => handleRemoveSearch(val)}
          onClick={() => {
            if (selected !== val.id) {
              setSelected(val.id);
              setRequestFilter(val.filter);
            }
          }}
          isActive={val.id === selected}
        />
      ))}
    </div>
  )
};

export default SavedSearchesList;
