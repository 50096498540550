import { ApiService } from "services/ApiService";

export class Tags {
  static async createTag(data) {
    return ApiService._requestAsync({
      url: "/tags",
      method: "POST",
      data
    });
  }

  static async getTags(type) {
    return ApiService._requestAsync({
      url: `/tags?type=${type}`,
      method: "GET"
    });
  }

  static async updateTag(data) {
    return ApiService._requestAsync({
      url: "/tags",
      method: "PUT",
      data
    });
  }

  static async deleteTag(data) {
    return ApiService._requestAsync({
      url: "/tags",
      method: "DELETE",
      data
    });
  }

  // COMPANY TAGS API

  /**
   *
   * @param {string} id
   * @param {string[]} tagNames
   * @returns {Promise<Promise<unknown> | *>}
   */
  static async addCompanyToTag(id, tagNames) {
    return ApiService._requestAsync({
      url: `/companies/${id}/tag`,
      method: "PUT",
      data: {
        tagNames
      }
    });
  }

  /**
   *
   * @param {string} id
   * @param {string[]} tagNames
   * @returns {Promise<Promise<unknown> | *>}
   */
  static async deleteCompanyFromTag(id, tagNames) {
    return ApiService._requestAsync({
      url: `/companies/${id}/tag`,
      method: "DELETE",
      data: {
        tagNames
      }
    });
  }

  static async getCompanyTags(id) {
    return ApiService._requestAsync({
      url: `/companies/${id}/tags`,
      method: "GET"
    });
  }

  // CONTACT TAGS API

  /**
   *
   * @param {string} id
   * @param {string[]} tagNames
   * @returns {Promise<Promise<unknown> | *>}
   */
  static async addContactToTag(id, tagNames) {
    return ApiService._requestAsync({
      url: `/contacts/${id}/tag`,
      method: "PUT",
      data: {
        tagNames
      }
    });
  }

  /**
   *
   * @param {string} id
   * @param {string[]} tagNames
   * @returns {Promise<Promise<unknown> | *>}
   */
  static async deleteContactFromTag(id, tagNames) {
    return ApiService._requestAsync({
      url: `/contacts/${id}/tag`,
      method: "DELETE",
      data: {
        tagNames
      }
    });
  }

  static async getContactTags(id) {
    return ApiService._requestAsync({
      url: `/contacts/${id}/tags`,
      method: "GET"
    });
  }
}
