import React from "react";

const ChangePasswordIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66479 13.2649C5.68675 13.2869 5.68673 13.3225 5.66477 13.3445C5.6428 13.3664 5.6072 13.3664 5.58523 13.3445C5.56327 13.3225 5.56325 13.2869 5.58521 13.2649C5.59576 13.2544 5.61007 13.2484 5.625 13.2484C5.63993 13.2484 5.65424 13.2544 5.66479 13.2649" stroke="#1F4FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.03979 13.2649C9.06175 13.2869 9.06173 13.3225 9.03977 13.3445C9.0178 13.3664 8.9822 13.3664 8.96023 13.3445C8.93827 13.3225 8.93825 13.2869 8.96021 13.2649C8.97076 13.2544 8.98507 13.2484 9 13.2484C9.01493 13.2484 9.02924 13.2544 9.03979 13.2649" stroke="#1F4FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.4148 13.2649C12.4367 13.2869 12.4367 13.3225 12.4148 13.3445C12.3928 13.3664 12.3572 13.3664 12.3352 13.3445C12.3133 13.3225 12.3133 13.2869 12.3352 13.2649C12.3458 13.2544 12.3601 13.2484 12.375 13.2484C12.3899 13.2484 12.4042 13.2544 12.4148 13.2649" stroke="#1F4FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="2.25" y="10.3047" width="13.5" height="6" rx="2.5" stroke="#1F4FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 2.05469V2.05469C10.0355 2.05469 10.875 2.89415 10.875 3.92969V5.42969H7.125V3.92969C7.125 2.89415 7.96447 2.05469 9 2.05469V2.05469Z" stroke="#1F4FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.25 8.05469V6.92969C5.25 6.10126 5.92157 5.42969 6.75 5.42969H11.25C12.0784 5.42969 12.75 6.10126 12.75 6.92969V8.05469" stroke="#1F4FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default ChangePasswordIcon;
