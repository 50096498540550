import React from "react";
import "./styles.scss";

const DetailsSectionTitle = ({ label = "", icon, secondary = false, rightContent }) => {
  return (
    <div className={`details-section-title`}>
      {icon && <div className="icon">{icon}</div>}
      <div className={`text ${secondary ? "secondary" : ""} ${icon ? "with-icon" : ""}`}>{label}</div>
      {rightContent}
    </div>
  )
}

export default DetailsSectionTitle;