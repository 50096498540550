import { handleActions } from "redux-actions";
import { setRecentDialogsDrawerState, setAiThread, setRecentChatId } from "redux/actions/chat";

const InitialState = {
  recentDialogsDrawerState: true,
  aiThread: null,
  recentChatId: localStorage.getItem("recentChatId")
};

const handleSetRecentDialogsDrawerState = (state, { payload }) => ({ ...state, recentDialogsDrawerState: payload });

const handleSetAiThread = (state, { payload }) => ({ ...state, aiThread: payload });

const handleSetRecentChatId = (state, { payload }) => ({ ...state, recentChatId: payload });

export default handleActions(
  {
    [setRecentDialogsDrawerState]: handleSetRecentDialogsDrawerState,
    [setAiThread]: handleSetAiThread,
    [setRecentChatId]: handleSetRecentChatId
  },
  {
    ...InitialState
  }
);
