import React from "react";
import { Pagination as MuiPagination } from "@mui/material";

import "./styles.scss";

const Pagination = ({
  page = 1,
  onChange = () => { },
  isLoading,
  totalItems,
  pageItemsCount,
  limit
}) => {
  const totalPages = Math.ceil((totalItems || 1) / (limit || 1));
  return (
    <div className="pagination-wrapper">
      {isLoading ? (
        <>
          <div key="total-items-loader" className="total-items-loader" />
          <div key="loader-controls" className="loader-controls" />
        </>
      ) : (
        <>
          <div className="total-items-label">
            {!!totalItems && <>{pageItemsCount} of {totalItems === 10000 ? "10000+" : totalItems}</>}
          </div>
          <MuiPagination
            count={totalPages}
            page={page || 1}
            onChange={(e, newValue) => newValue !== page ? onChange(newValue) : null}
          />
        </>
      )}
    </div>
  )
};

export default Pagination;
