import React from "react";

import { useAnimateItemHook } from "hooks/animateItem";

const AnimatedListItem = ({ children, index, totalLength }) => {
  const delay = 300 / (totalLength / index);
  const [styles] = useAnimateItemHook(delay, -10);

  return (
    <div style={styles} className="animated-list-item-wrapper">
      {children}
    </div>
  )
};

export default AnimatedListItem;
