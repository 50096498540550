import React, { useState } from "react";

import StyledTextField from "shared/StyledTextField";
import LegendItem from "shared/LegendItem";

import "./styles.scss";

const KeywordsTextField = ({ value, onChange, placeholder, label }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <div className="keywords-text-field-wrapper">
      <StyledTextField
        label={label}
        placeholder={placeholder}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        endBtnLabel="Add"
        disabledEndBtn={!inputValue?.trim()}
        onEndBtnClick={() => {
          if (!value?.includes(inputValue)) {
            onChange([...(value || []), inputValue?.trim()]);
          }
          setInputValue("");
        }}
      />
      <div className={`keywords-list ${value?.length ? "active" : ""}`}>
        {value?.map(val => (
          <LegendItem key={val} label={val} onRemove={() => onChange(value?.filter(it => it !== val))} />
        ))}
      </div>
    </div>
  )
};

export default KeywordsTextField;
