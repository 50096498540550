import React from "react";
import { CircularProgress } from "@mui/material";
import "./styles.scss";

const GlobalLoader = ({ isLoading }) => {
  if (!isLoading) {
    return <></>
  }
  return (
    <div className="global-loader">
      <CircularProgress size={150} />
    </div>
  )
}

export default GlobalLoader;
