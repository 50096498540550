import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import SelectIcon from "assets/iconComponents/SelectIcon";
import CloseIcon from "assets/iconComponents/CloseIcon";

import "./styles.scss";

const AutocompleteOption = ({ optionProps, data }) => (
  <li {...optionProps} className="autocomplete-option">
    <div className="label-wrapper">
      <span>{data.label}</span>
    </div>
  </li>
);

const StyledAutocomplete = ({
  label = "",
  onChange = () => { },
  options = [],
  value = "",
  renderOption = (props, option) => <AutocompleteOption key={option.value} optionProps={props} data={option} />,
  isOptionEqualToValue = (option, value) => option?.value === value?.value,
  noOptionsText = "Nothing found",
  placeholder,
  disabled = false
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options?.slice(0, 50) || []);
  const [inputValue, setInputValue] = useState(options?.find(val => val.value === value)?.label || "");

  return (
    <div className="autocomplete-wrapper">
      {label && <div className="autocomplete-label">{label}</div>}
      <Autocomplete
        className="styled-autocomplete"
        value={value}
        onChange={(e, newVal) => {
          onChange(e, newVal);
          setInputValue(newVal?.label || "");
        }}
        disabled={disabled}
        options={filteredOptions}
        renderInput={params => <TextField {...params} placeholder={placeholder} />}
        renderOption={renderOption}
        isOptionEqualToValue={isOptionEqualToValue}
        inputValue={inputValue}
        onInputChange={(e, newVal) => {
          if (e?.type === "change") {
            onChange(e, null);
            setInputValue(newVal);
            setFilteredOptions(options?.filter(val => val.label.includes(newVal))?.slice(0, 50) || [])
          }
        }}
        noOptionsText={noOptionsText}
        popupIcon={<SelectIcon />}
        clearIcon={<CloseIcon />}
        componentsProps={{ popper: { className: "styled-autocomplete-popper" } }}
      />
    </div>
  )
};

export default StyledAutocomplete;
