import React from "react";
import { CircularProgress } from "@mui/material";

import "./styles.scss";

const Loader = ({ size, absolute }) => (
  <div className={`dm-loader ${absolute ? "absolute" : ""}`}>
    <CircularProgress size={size || 40} />
  </div>
);

export default Loader;
