import { ApiService } from "services/ApiService";

export class AIChatService {
  static async sendMessage(data, signal) {
    return ApiService._requestAsync({
      url: "/conversations",
      method: "POST",
      data,
      signal
    });
  }
  static async getConversations(params) {
    return ApiService._requestAsync({
      url: "/conversations",
      method: "GET",
      params
    });
  }
  static async getConversationHistory(id, params, signal) {
    return ApiService._requestAsync({
      url: `/conversations/${id}/messages`,
      method: "GET",
      params,
      signal
    });
  }
  static async removeConversation(id) {
    return ApiService._requestAsync({
      url: `/conversations/${id}`,
      method: "DELETE"
    });
  }
  static async renameConversation(id, name) {
    return ApiService._requestAsync({
      url: `/conversations/${id}`,
      method: "PUT",
      data: { name }
    });
  }
}
