import React from "react";

const CopyIcon = ({ color = "var(--primary-color)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7193_75852)">
      <path d="M2 8.32812C2 7.4225 2.735 6.6875 3.64062 6.6875H5.04688C5.23336 6.6875 5.4122 6.76158 5.54406 6.89344C5.67592 7.0253 5.75 7.20414 5.75 7.39062C5.75 7.57711 5.67592 7.75595 5.54406 7.88781C5.4122 8.01967 5.23336 8.09375 5.04688 8.09375H3.64062C3.57846 8.09375 3.51885 8.11844 3.4749 8.1624C3.43094 8.20635 3.40625 8.26596 3.40625 8.32812V15.3594C3.40625 15.4888 3.51125 15.5938 3.64062 15.5938H10.6719C10.734 15.5938 10.7936 15.5691 10.8376 15.5251C10.8816 15.4811 10.9062 15.4215 10.9062 15.3594V13.9531C10.9062 13.7666 10.9803 13.5878 11.1122 13.4559C11.2441 13.3241 11.4229 13.25 11.6094 13.25C11.7959 13.25 11.9747 13.3241 12.1066 13.4559C12.2384 13.5878 12.3125 13.7666 12.3125 13.9531V15.3594C12.3125 15.7945 12.1396 16.2118 11.832 16.5195C11.5243 16.8271 11.107 17 10.6719 17H3.64062C3.2055 17 2.7882 16.8271 2.48053 16.5195C2.17285 16.2118 2 15.7945 2 15.3594V8.32812Z" fill={color}/>
      <path d="M6.6875 3.64062C6.6875 2.735 7.4225 2 8.32812 2H15.3594C16.265 2 17 2.735 17 3.64062V10.6719C17 11.107 16.8271 11.5243 16.5195 11.832C16.2118 12.1396 15.7945 12.3125 15.3594 12.3125H8.32812C7.893 12.3125 7.4757 12.1396 7.16803 11.832C6.86035 11.5243 6.6875 11.107 6.6875 10.6719V3.64062ZM8.32812 3.40625C8.26596 3.40625 8.20635 3.43094 8.1624 3.4749C8.11844 3.51885 8.09375 3.57846 8.09375 3.64062V10.6719C8.09375 10.8013 8.19875 10.9062 8.32812 10.9062H15.3594C15.4215 10.9062 15.4811 10.8816 15.5251 10.8376C15.5691 10.7936 15.5938 10.734 15.5938 10.6719V3.64062C15.5938 3.57846 15.5691 3.51885 15.5251 3.4749C15.4811 3.43094 15.4215 3.40625 15.3594 3.40625H8.32812Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_7193_75852">
        <rect width="15" height="15" fill="transparent" transform="translate(2 2)"/>
      </clipPath>
    </defs>
  </svg>
);

export default CopyIcon;
