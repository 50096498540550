import React from "react";
import { Popover, Button } from "@mui/material";

import "./styles.scss";

export const PopoverItem = ({ label, icon, isDelete, isActivate, hasDivider, ...restProps }) => (
  <>
    <Button className={`popover-item-btn ${isDelete ? "delete" : ""}`} {...restProps}>
      {icon}
      <div className="popover-item-btn-label">{label}</div>
    </Button>
    {hasDivider && <div className="popover-item-divider" />}
  </>
);

const DefaultPopover = ({ state, setState, children, vertical, horizontal, className, onClose, ...rest }) => {
  return (
    <Popover
      {...rest}
      className={`default-popover-wrapper ${className || ""}`}
      open={Boolean(state)}
      anchorEl={state}
      onClose={e => {
        e.preventDefault();
        e.stopPropagation();
        setState(null);
        if (onClose) {
          onClose();
        }
      }}
      anchorOrigin={{
        vertical: vertical || "bottom",
        horizontal: horizontal || "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      {children}
    </Popover>
  );
};

export default DefaultPopover;
