import React from "react";

const EditIcon = ({ color = "var(--main-text)" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.4258 6.97461L16.9219 11.0134" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.13419 19.9226L9.80074 19.4378C10.6965 19.1768 11.4769 18.6192 12.0128 17.8568L18.4016 8.99764C19.1664 7.94832 18.9357 6.4789 17.8864 5.71406L16.1529 4.45028C15.1037 3.68674 13.633 3.91766 12.8693 4.96552L6.41037 13.9239C5.91747 14.6259 5.65703 15.4642 5.66421 16.3224L5.68023 18.0982C5.69233 19.369 6.91422 20.2772 8.13419 19.9226Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default EditIcon;
