import React from "react";

const ChatIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 6.50537V8.30868M9 8.30868V10.112M9 8.30868H10.8M9 8.30868H7.2" stroke="var(--primary-color)" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M10.2 13.1177C12.4626 13.1177 13.5942 13.1177 14.2968 12.4132C15 11.7093 15 10.5756 15 8.30883C15 6.04207 15 4.90839 14.2968 4.20449C13.5942 3.5 12.4626 3.5 10.2 3.5H7.8C5.5374 3.5 4.4058 3.5 3.7032 4.20449C3 4.90839 3 6.04207 3 8.30883C3 10.5756 3 11.7093 3.7032 12.4132C4.095 12.8063 4.72 12.9237 5.5 13" stroke="var(--primary-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.2 13.1177C9.4584 13.1177 8.6412 13.4182 7.8954 13.8059C6.6966 14.4293 6.0972 14.7413 5.802 14.5423C5.5068 14.3439 5.3878 14.2317 5.5 13" stroke="var(--primary-color)" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export default ChatIcon;
