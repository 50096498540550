import React from "react";

const ContactIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="8.68425" cy="5.45852" rx="3.58352" ry="3.58352" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3 14.0257C2.99903 13.7738 3.05537 13.5249 3.16476 13.298C3.50801 12.6115 4.47597 12.2476 5.27917 12.0829C5.85844 11.9593 6.44571 11.8767 7.03661 11.8357C8.13061 11.7396 9.23094 11.7396 10.3249 11.8357C10.9158 11.8772 11.503 11.9597 12.0824 12.0829C12.8856 12.2476 13.8535 12.5772 14.1968 13.298C14.4168 13.7606 14.4168 14.2976 14.1968 14.7602C13.8535 15.4811 12.8856 15.8106 12.0824 15.9685C11.5038 16.0972 10.9163 16.1821 10.3249 16.2225C9.43451 16.298 8.53992 16.3117 7.64759 16.2637C7.44164 16.2637 7.24256 16.2637 7.03661 16.2225C6.44746 16.1826 5.86222 16.0977 5.28604 15.9685C4.47597 15.8106 3.51487 15.4811 3.16476 14.7602C3.05593 14.5307 2.99965 14.2797 3 14.0257Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ContactIcon;
