import React from "react";

const LogoutIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Property 1=Iconly, Property 2=Light, Property 3=Logout">
      <g id="Logout">
        <path id="Stroke 1" d="M11.2631 5.54211V4.84236C11.2631 3.31611 10.0256 2.07861 8.49933 2.07861H4.84308C3.31758 2.07861 2.08008 3.31611 2.08008 4.84236V13.1899C2.08008 14.7161 3.31758 15.9536 4.84308 15.9536H8.50683C10.0286 15.9536 11.2631 14.7199 11.2631 13.1981V12.4909" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 3" d="M16.3589 9.01611H7.32812" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 5" d="M14.1621 6.82983L16.3581 9.01608L14.1621 11.2031" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
  </svg>
);

export default LogoutIcon;
