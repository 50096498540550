import React from "react";

const DepartmentsIcon = ({ color = "var(--second-text)" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 14.25C1.5 12.6 2.85 11.25 4.5 11.25H7.5C9.15 11.25 10.5 12.6 10.5 14.25" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.87525 4.49987C8.92525 5.54987 8.92525 7.19987 7.87525 8.17487C6.82525 9.14987 5.17525 9.22487 4.20025 8.17487C3.22525 7.12487 3.15025 5.54987 4.12525 4.49987C5.10025 3.44987 6.82525 3.52487 7.87525 4.49987" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.999 10.5H14.249C15.524 10.5 16.499 11.475 16.499 12.75" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.4762 5.0248C15.2262 5.7748 15.2262 6.97481 14.4762 7.64981C13.7262 8.32481 12.5262 8.39981 11.8512 7.64981C11.1762 6.89981 11.1012 5.6998 11.8512 5.0248C12.5262 4.3498 13.7262 4.3498 14.4762 5.0248" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DepartmentsIcon;
