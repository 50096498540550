import { ApiService } from "services/ApiService";
import { searchTypes } from "utils/searchFilters";

export class AdvancedSearchService {
  static async advancedContactsSearch(params, signal) { // q, limit, page, sortBy, order
    const { q, ...other } = params;
    return ApiService._requestAsync({
      url: `/search/advanced/contacts?q=${q}`,
      method: "GET",
      params: other,
      signal
    });
  }

  static async advancedCompaniesSearch(params, signal) { // q, limit, page, sortBy, order
    const { q, ...other } = params;
    return ApiService._requestAsync({
      url: `/search/advanced/companies?q=${q}`,
      method: "GET",
      params: other,
      signal
    });
  }

  static async advancedSearch(params, signal) {
    const { type, ...otherParams } = params;
    if (type === searchTypes.contact) {
      return AdvancedSearchService.advancedContactsSearch(otherParams, signal);
    }
    return AdvancedSearchService.advancedCompaniesSearch(otherParams, signal);
  }

  static async contactDetails(id) {
    return ApiService._requestAsync({
      url: `/contacts/${id}/profile`,
      method: "GET"
    });
  }

  static async contactExperience(id) {
    return ApiService._requestAsync({
      url: `/contacts/${id}/employment-experience`,
      method: "GET"
    });
  }

  static async companyDetails(id) {
    return ApiService._requestAsync({
      url: `/companies/${id}/profile`,
      method: "GET"
    });
  }

  static async getSavedSearches() {
    return ApiService._requestAsync({
      url: "/search-engagement/saved-searches",
      method: "GET"
    });
  }

  static async saveSearch(data) {
    return ApiService._requestAsync({
      url: "/search-engagement/saved-searches",
      method: "POST",
      data
    });
  }

  static async removeSavedSearch(id) {
    return ApiService._requestAsync({
      url: `/search-engagement/saved-searches/${id}`,
      method: "DELETE"
    });
  }

  static async pinSavedSearch(id) {
    return ApiService._requestAsync({
      url: `/search-engagement/saved-searches/${id}/favorite`,
      method: "PUT"
    });
  }

  static async unpinSavedSearch(id) {
    return ApiService._requestAsync({
      url: `/search-engagement/saved-searches/${id}/favorite`,
      method: "DELETE"
    });
  }

  static async saveContact(contactId) {
    return ApiService._requestAsync({
      url: "/search-engagement/saved-contacts",
      method: "POST",
      data: { contactId }
    });
  }

  static async getSavedContacts() {
    return ApiService._requestAsync({
      url: "/search-engagement/saved-contacts",
      method: "GET"
    });
  }

  static async removeSavedContact(contactId) {
    return ApiService._requestAsync({
      url: "/search-engagement/saved-contacts",
      method: "DELETE",
      data: { contactId }
    });
  }

  static async saveCompany(companyId) {
    return ApiService._requestAsync({
      url: "/search-engagement/saved-companies",
      method: "POST",
      data: { companyId }
    });
  }

  static async getSavedCompanies() {
    return ApiService._requestAsync({
      url: "/search-engagement/saved-companies",
      method: "GET"
    });
  }

  static async removeSavedCompany(companyId) {
    return ApiService._requestAsync({
      url: "/search-engagement/saved-companies",
      method: "DELETE",
      data: { companyId }
    });
  }

  static async getJobTitles() {
    const res = await ApiService._requestAsync({
      url: "/contacts/job-titles",
      method: "GET",
      params: { limit: 100 }
    });
    return res?.data?.map(val => ({ label: val.title, value: val.title }));
  }

  static async getManagementLevels() {
    const res = await ApiService._requestAsync({
      url: "/contacts/management-levels",
      method: "GET",
      params: { limit: 200 }
    });
    return res?.data?.map(val => ({ label: val.title, value: val.title }))
  }

  static async getDepartments() {
    const res = await ApiService._requestAsync({
      url: "/contacts/departments",
      method: "GET",
      params: { limit: 200 }
    });
    return res?.data?.map(val => ({ label: val.title, value: val.title }))
  }

  static async getIndustries() {
    const res = await ApiService._requestAsync({
      url: "/companies/industries",
      method: "GET",
      params: { limit: 200 }
    });
    return res?.data?.map(val => ({ label: val.title, value: val.title }))
  }

  static async getRecommendations() {
    return ApiService._requestAsync({
      url: "/search-engagement/recommendations",
      method: "GET"
    });
  }

  static async exportCsv(type, ids) {
    const q = type === "contacts" ? "contactIds" : "companyIds";
    return ApiService._requestAsync({
      url: `/${type}/exports/csv?${q}=${ids}`,
      method: "GET",
      responseType: "blob"
    });
  }
}
