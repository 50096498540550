import React from "react";

const TagsTooltipIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="2" fill="var(--primary-color)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.83216 11.3159L4.86648 12.9406C4.6297 13.0637 4.33798 12.9766 4.20745 12.7439V12.7439C4.16968 12.6718 4.1493 12.5918 4.14795 12.5104V5.31136C4.14795 3.93836 5.08616 3.38916 6.43628 3.38916H9.6079C10.9168 3.38916 11.8962 3.90175 11.8962 5.21982V12.5104C11.8962 12.6403 11.8446 12.7649 11.7528 12.8567C11.661 12.9486 11.5364 13.0001 11.4065 13.0001C11.3237 12.9988 11.2423 12.9785 11.1685 12.9406L8.18456 11.3159C8.07461 11.2565 7.94212 11.2565 7.83216 11.3159Z" stroke="var(--primary-inner-color)" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.18457 6.66145H9.83217" stroke="var(--primary-inner-color)" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default TagsTooltipIcon;
