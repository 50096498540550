import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import moment from "moment";

import { Tags } from "services/Tags";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { getNameAbbreviation } from "helpers/common";
import { setRevealedContacts } from "redux/actions/filters";
import { searchTypes } from "utils/searchFilters";
import { SearchProfileDetails } from "services/SearchDetails";

import DetailsSectionTitle from "shared/DetailsSectionTitle";
import AvatarComponent from "shared/AvatarComponent";
import TagLegendItem from "shared/TagLegendItem";
import StyledTooltip from "shared/StyledTooltip";

import DepartmentsIcon from "assets/iconComponents/DepartmentsIcon";
import ManagementIcon from "assets/iconComponents/ManagementIcon";
import EmailIcon from "assets/iconComponents/EmailIcon";
import PhoneIcon from "assets/iconComponents/PhoneIcon";
import Location from "assets/iconComponents/Location";
import EyeIcon from "assets/iconComponents/EyeIcon";
import CheckMark from "assets/iconComponents/CheckMark";
import CopyIcon from "assets/iconComponents/CopyIcon";

import "./styles.scss";
import Loader from "shared/Loader";

const HistoryItem = ({ data, isLoading = false }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`history-item ${data?.company?.id ? "clickable" : ""}`}
      onClick={() => {
        if (data?.company?.id) {
          dispatch(setDrawerState({
            data: {},
            id: data?.company?.id,
            mainRequestFinished: false,
            open: true,
            isCompany: true
          }));
        }
      }}
    >
      <div className={`dot ${isLoading ? "loader" : (data?.isCurrent ? "current" : "")}`} />
      <div className={`status ${isLoading ? "loader" : ""}`}>{data?.isCurrent ? "Current" : "Former"}</div>
      <div className="info">
        <AvatarComponent
          url={data?.company?.companyWebsiteUrl}
          shortName={getNameAbbreviation(data?.company?.name)}
          isLoading={isLoading}
        />
        <div className="text">
          <StyledTooltip isOverflowContent title={data?.jobTitle}>
            <div className={`role ${isLoading ? "loader" : ""}`}>
              {data?.jobTitle}
            </div>
          </StyledTooltip>
          <StyledTooltip isOverflowContent title={data?.company?.name}>
            <div className={`company ${isLoading ? "loader" : ""}`}>
              {data?.company?.name}
            </div>
          </StyledTooltip>
        </div>
      </div>
    </div>
  )
};

const EducationItem = ({ data }) => {
  return (
    <div className="education-item">
      <div className={`dot ${!data?.endDate ? "current" : ""}`} />
      <StyledTooltip isOverflowContent title={data?.name}>
        <div className="name">
          <span className="date">
            {data?.startDate ? (
              moment(data?.startDate).format("YYYY")
            ) : "----"}
            {" - "}
            {data?.endDate ? (
              moment(data?.endDate).format("YYYY")
            ) : "----"}
          </span>
          {" "}
          {data?.name}
        </div>
      </StyledTooltip>
      <div className="degree">{data?.degree}</div>
    </div>
  )
};

const ContactInfoTab = ({ isLoading, tagsUpdated }) => {
  const drawerData = useSelector(state => state.detailsDrawer);
  const revealedContacts = useSelector(state => state.filters.revealedContacts);
  const dispatch = useDispatch();
  const [tagsLoading, setTagsLoading] = useState(true);
  const revealedData = revealedContacts?.find(val => val.id === drawerData.id);
  const [copySuccess, setCopySuccess] = useState(false);
  const [revealLoading, setRevealLoading] = useState(false);

  const getData = async () => {
    setTagsLoading(true);
    const res = await Tags.getContactTags(drawerData.id);
    dispatch(setDrawerState({
      data: {
        ...drawerData.data,
        contactData: {
          ...drawerData?.data?.contactData, tags: res?.tags || []
        }
      }
    }));
    setTagsLoading(false);
  };

  useEffect(() => {
    if (drawerData?.open && drawerData?.id && drawerData?.mainRequestFinished) {
      getData();
    }
    // eslint-disable-next-line
  }, [drawerData?.open, drawerData?.id, drawerData?.mainRequestFinished, tagsUpdated]);


  const revealContact = async () => {
    setRevealLoading(true);
    try {
      const res = await SearchProfileDetails.getRevealedDetails(drawerData.id);
      const newRevealed = [...revealedContacts, res];
      dispatch(setRevealedContacts(newRevealed));
    } catch (e) {
      console.log(e);
    }
    setRevealLoading(false);
  };

  let copyTimeout;

  const handleCopyText = text => {
    try {
      navigator.clipboard.writeText(text).then(() => {
        setCopySuccess(true);
        clearTimeout(copyTimeout);
        copyTimeout = setTimeout(() => setCopySuccess(false), 2000);
      });
    } catch (e) {
      console.log(e);
      setCopySuccess(false);
    }
  }

  return (
    <div className="contact-details-wrapper">
      <div className="divider" />
      <div className="contact-details">
        <DetailsSectionTitle
          label="Details"
          rightContent={!revealedData && (
            <Button className="reveal-btn" onClick={revealContact}>
              {revealLoading ? <Loader size={19} /> : <EyeIcon />}
              Reveal
            </Button>
          )}
        />
        <div className={`details-item ${isLoading ? "loader" : ""}`}>
          <DepartmentsIcon />
          <span>
            {drawerData?.data?.lastCompany?.department || "- - -"}
          </span>
        </div>
        <div className={`details-item ${isLoading ? "loader" : ""}`}>
          <ManagementIcon />
          <span>
            {drawerData?.data?.lastCompany?.managementLevel || "- - -"}
          </span>
        </div>
        <div className={`details-item ${isLoading ? "loader" : ""}`}>
          <EmailIcon />
          <span className={revealedData?.email ? "copy" : ""} onClick={() => handleCopyText(revealedData?.email)}>
            {revealedData ? (
              <>
                <span className="text">{revealedData?.email || "- - -"}</span>
                {!!revealedData?.email && (
                  <span className="icon-box">
                    {copySuccess ? <CheckMark color="var(--success-color)" /> : <CopyIcon />}
                  </span>
                )}
              </>
            ) : "********@****.***"}
          </span>
        </div>
        <div className={`details-item ${isLoading ? "loader" : ""}`}>
          <PhoneIcon />
          <span>
            {revealedData ? (revealedData?.phones?.length ? revealedData?.phones[0] : "- - -") : "(***) ***-***"}
          </span>
        </div>
        <div className={`details-item ${isLoading ? "loader" : ""}`}>
          <Location color="var(--second-text)" />
          {drawerData.data?.contactData?.countryName ? (
            <span>{drawerData?.data?.contactData?.countryName}</span>
          ) : "- - -"}
        </div>
      </div>

      <div className="divider" />

      <div className="employment-history">
        <DetailsSectionTitle label="Employment History" />
        <div className="scroll-wrapper">
          <div className="history-wrapper">
            <div className="line" />
            {isLoading ? (
              <HistoryItem data={{}} isLoading />
            ) : drawerData.data?.currentCompanies?.map(it => (
              <HistoryItem data={it} key={it?.company?.id} />
            ))}
            {drawerData.data?.prevCompanies?.map(it => (
              <HistoryItem data={it} key={it?.company?.id} />
            ))}
          </div>
        </div>
      </div>

      <div className="divider" />

      {!!drawerData.data?.contactData?.education?.length && (
        <>
          <div className="employment-history">
            <DetailsSectionTitle label="Education" />
            <div className="scroll-wrapper">
              <div className="history-wrapper">
                <div className="line" />
                {drawerData.data?.contactData?.education?.sort((a, b) => a?.startDate && new Date(a?.startDate) < new Date(b?.startDate) ? 1 : -1)?.map(it => (
                  <EducationItem data={it} key={it?.company?.id} />
                ))}
              </div>
            </div>
          </div>
          <div className="divider" />
        </>
      )}

      <div className="tags-section">
        <DetailsSectionTitle label="Tags" />
        <div className="tags-wrapper">
          {tagsLoading ? (
            new Array(4).fill(0).map((_, i) => (
              <TagLegendItem isLoading key={`loader-tag-${i}`} />
            ))
          ) : (
            <>
              {drawerData?.data?.contactData?.tags?.length ? drawerData?.data?.contactData?.tags?.map((tag, i) => (
                <TagLegendItem key={i} name={tag.name} type={searchTypes.contact} />
              )) : (
                <span className="empty-text">No tags added</span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContactInfoTab;