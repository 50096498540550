import React from "react";
import { useDispatch } from "react-redux";

import { setDrawerState } from "redux/actions/detailsDrawer";
import { getNameAbbreviation, wrapAvatarUrl } from "helpers/common";

import StyledTooltip from "shared/StyledTooltip";
import AvatarComponent from "shared/AvatarComponent";

import "./styles.scss";

const CompanyNameColumn = ({ companyName, companyId, companyWebsite, isLoading }) => {
  const dispatch = useDispatch();

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (companyId) {
      dispatch(setDrawerState({ data: {}, mainRequestFinished: false, id: companyId, open: true, isCompany: true }));
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="company-name-column-wrapper">
          <AvatarComponent isLoading />
          <div className="loader" />
        </div>
      ) : (
        companyName ? (
          <div className={`company-name-column-wrapper ${!companyId ? "disabled" : ""}`} onClick={handleClick}>
            <AvatarComponent url={wrapAvatarUrl(companyWebsite)} shortName={getNameAbbreviation(companyName, 1)} />
            <StyledTooltip isOverflowContent title={companyName}>
              <span>{companyName}</span>
            </StyledTooltip>
          </div>
        ) : (
          <div className="company-name-column-wrapper disabled">- - -</div>
        )
      )}
    </>
  )
};

export default CompanyNameColumn;
