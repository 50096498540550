import { useState, useEffect, createRef } from "react";

export const useTableResize = (columns) => {
  const [tableColumnsRef, setTableColumnsRef] = useState(columns?.map(val => ({ ...val, ref: createRef() })));
  const [tableColumns, setTableColumns] = useState(columns);
  const [dragData, setDragData] = useState(null);

  useEffect(() => {
    setTableColumnsRef(columns?.map(val => ({ ...val, ref: createRef() })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    setTableColumns(tableColumnsRef?.map(val => ({
      ...val,
      style: { ...(val.style || {}), width: `${val.ref?.current?.clientWidth}px` }
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumnsRef]);

  const onMouseMove = e => {
    const width = dragData.clientWidth + (e?.clientX - dragData.startPoint);
    setTableColumns(tableColumns.map(column => {
      if (column.label === dragData.column.label) {
        return {
          ...column,
          style: { ...(column.style || {}), width: `${width}px`, flex: "unset", maxWidth: "unset" }
        }
      }
      return column;
    }))
  }

  const onDocMouseUp = () => {
    setDragData(null);
    document.getElementById("root").style.pointerEvents = "unset";
    document.getElementById("root").style.userSelect = "unset";
    document.body.style.cursor = "unset";
  }

  const onMouseDown = (e, column) => {
    document.getElementById("root").style.pointerEvents = "none";
    document.getElementById("root").style.userSelect = "none";
    document.body.style.cursor = "col-resize";
    setDragData({ startPoint: e?.clientX, column, clientWidth: column.ref.current.clientWidth });
  }

  useEffect(() => {
    if (dragData) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onDocMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragData]);

  return { onMouseDown, tableColumns, dragData };
};
