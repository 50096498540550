import axios from "axios";
import { DataSysAuth0Client } from "../security/DataSysAuth0Client";

export class HttpService {
  static async requestAsync({ url, method = "GET", data = {}, headers = {}, responseType = "json", params, process, returnResponse, signal }) {
    const reqOptions = {
      url,
      method,
      responseType,
      headers,
      timeout: 600000,
      data,
      params,
      withCredentials: true,
      ...(signal ? { signal } : {}),
      ...(process ? {
        onDownloadProgress: process,
        onUploadProgress: process,
      } : {})
    };
    return axios(reqOptions)
      .then(res => {
        if(returnResponse) return res;
        if (res.status >= 200 && res.status <= 300) {
          return res.data;
        }
        return res;
      })
      .catch(e => {
        if (e?.response?.status === 401 && e?.response?.data?.message === "Your search session has expired") {
          return DataSysAuth0Client.logoutAsync();
        }
        throw e;
      });
  }
}
