import React, { useState } from "react";
import "./styles.scss";

const AvatarComponent = ({ url, shortName, isLoading }) => {
  const [imgError, setImgError] = useState(false);
  return (
    <div className={`avatar-component-wrapper ${isLoading ? "avatar-placeholder" : ""}`}>
      {!isLoading && (
        <>
          {!imgError && url ? (
            <img
              src={url}
              alt=""
              onError={() => setImgError(true)}
              onCanPlayThrough={() => setImgError(false)}
            />
          ) : shortName}
        </>
      )}
    </div>
  )
};

export default AvatarComponent;
