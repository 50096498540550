import React, { useState } from "react";
import { Button } from "@mui/material";

import { Tags } from "services/Tags";

import DefaultPopover, { PopoverItem } from "shared/DefaultPopover";

import TagsFilterIcon from "assets/iconComponents/TagsFilterIcon";
import PinnedIcon from "assets/iconComponents/PinnedIcon";
import ThreeDots from "assets/iconComponents/ThreeDots";
import DeleteIcon from "assets/iconComponents/DeleteIcon";

import "./styles.scss";

const TagItem = ({ name, type, isSkeletonLoading, disabled, isActive, isPinned, onClick, onPin, onRemoved }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(null);

  const removeTag = async () => {
    setOpen(null);
    setIsLoading(true);
    try {
      await Tags.deleteTag({ name, type });
      if (onRemoved) {
        await onRemoved();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`tag-item-wrapper ${isLoading ? "disabled" : ""}${isActive ? "active" : ""}`}
      onClick={() => {
        if (onClick && !disabled && !isSkeletonLoading) {
          onClick();
        }
      }}
    >
      {isSkeletonLoading ? (
        <>
          <div className="icon-loader" />
          <div className="tag-name">
            <div className="label-loader" />
          </div>
          <div className="btn-loader" />
        </>
      ) : (
        <>
          {isPinned ? <PinnedIcon /> : <TagsFilterIcon />}
          <div className="tag-name">
            <span>{name}</span>
          </div>
          <Button
            className="menu-btn"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(e.currentTarget);
            }}
          >
            <ThreeDots />
          </Button>
          <DefaultPopover state={open} setState={setOpen}>
            <PopoverItem
              label={isPinned ? "Unpin" : "Pin"}
              hasDivider
              icon={<PinnedIcon />}
              onClick={() => {
                onPin();
                setOpen(null);
              }}
            />
            <PopoverItem
              label="Delete"
              isDelete
              icon={<DeleteIcon />}
              onClick={removeTag}
            />
          </DefaultPopover>
        </>
      )}
    </div>
  )
};

export default TagItem;
