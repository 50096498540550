import React from "react";

const SaveIcon = ({ color }) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.75 15.75H13.25C14.0456 15.75 14.8087 15.4339 15.3713 14.8713C15.9339 14.3087 16.25 13.5456 16.25 12.75V5.5605C16.25 5.3616 16.1709 5.17087 16.0303 5.03025L13.4697 2.46975C13.3291 2.32909 13.1384 2.25004 12.9395 2.25H5.75C4.95435 2.25 4.19129 2.56607 3.62868 3.12868C3.06607 3.69129 2.75 4.45435 2.75 5.25V12.75C2.75 13.5456 3.06607 14.3087 3.62868 14.8713C4.19129 15.4339 4.95435 15.75 5.75 15.75Z" stroke={color || "var(--main-text)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.25 15.75V10.5C13.25 10.3011 13.171 10.1103 13.0303 9.96967C12.8897 9.82902 12.6989 9.75 12.5 9.75H6.5C6.30109 9.75 6.11032 9.82902 5.96967 9.96967C5.82902 10.1103 5.75 10.3011 5.75 10.5V15.75M7.25 2.25H11.75V4.5C11.75 4.69891 11.671 4.88968 11.5303 5.03033C11.3897 5.17098 11.1989 5.25 11 5.25H8C7.80109 5.25 7.61032 5.17098 7.46967 5.03033C7.32902 4.88968 7.25 4.69891 7.25 4.5V2.25Z" stroke={color || "var(--main-text)"} strokeWidth="1.5"/>
    <path d="M8.75 12.75H10.25" stroke={color || "var(--main-text)"} strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export default SaveIcon;
