import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { AccountService } from "services/AccountService";
import { getMeInfo, logout, setThemeType } from "redux/actions/auth";

import { dsToast } from "shared/dsToast";
import StyledTextField from "shared/StyledTextField";
import ModalWrapper from "shared/ModalWrapper";
import SubmitButton, { submitStatusValues } from "shared/SubmitButton";

import HiddenEye from "assets/iconComponents/HiddenEye";
import DisplayEye from "assets/iconComponents/DisplayEye";
import ThemeDarkIcon from "assets/iconComponents/ThemeDarkIcon";
import ThemeLightIcon from "assets/iconComponents/ThemeLightIcon";
import ThemeDefaultIcon from "assets/iconComponents/ThemeDefaultIcon";
import ChangePasswordIcon from "assets/iconComponents/ChangePasswordIcon";
import LogoutIcon from "assets/iconComponents/LogoutIcon";

import "./styles.scss";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.me.user);
  const theme = useSelector(state => state.me.themeType);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [email, setEmail] = useState("");
  const [passwordModalState, setPasswordModalState] = useState(false);
  const [saveStatus, setSaveStatus] = useState(submitStatusValues.default);
  const [passwordStatus, setPasswordStatus] = useState(submitStatusValues.default);

  const [showPassword, setShowPassword] = useState({
    new: false,
    repeat: false
  });

  const handleCancel = () => {
    setFirstName(userInfo.firstName);
    setLastName(userInfo.lastName);
    setEmail(userInfo.email);
    setNewPassword1("");
    setNewPassword2("");
  };

  useEffect(() => {
    if (userInfo) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleUpdateUserProfile = () => {
    let data = { firstName, lastName, email };
    setSaveStatus(submitStatusValues.loading);
    return AccountService.updateUserProfile(data)
      .then(() => {
        dsToast.success("Information has been successfully updated");
        dispatch(getMeInfo());
        setSaveStatus(submitStatusValues.success);
        setTimeout(() => {
          setSaveStatus(submitStatusValues.default);
        }, 2000);
      })
      .catch(() => {
        dsToast.error("Sorry, something went wrong")
        setSaveStatus(submitStatusValues.default);
      });
  };

  const handleUpdatePassword = () => {
    let data = { newPassword: newPassword2 };
    if (newPassword1 !== newPassword2) {
      return dsToast.error("Repeated password is not correct")
    }
    if (newPassword1?.length < 4) {
      return dsToast.error("Password must be longer than or equal to 4 characters\"")
    }
    setPasswordStatus(submitStatusValues.loading);
    return AccountService.updateUserProfile(data)
      .then(() => {
        dsToast.success("Password has been successfully updated");
        setPasswordStatus(submitStatusValues.success);
        setTimeout(() => {
          setNewPassword1("");
          setNewPassword2("");
          setPasswordModalState(false);
          setPasswordStatus(submitStatusValues.default);
        }, 2000);
      })
      .catch(() => {
        dsToast.error("Sorry, something went wrong")
        setPasswordStatus(submitStatusValues.default);
      });
  };

  const isDisabled = !firstName || !lastName || !email;

  const onChangeTheme = (themeKey) => {
    dispatch(setThemeType(themeKey));
    localStorage.setItem("theme", themeKey);
    document.body.className = "";
    document.body.classList.add(themeKey);
  };

  const closeChangePasswordModal = () => {
    setPasswordModalState(false)
    setNewPassword1("");
    setNewPassword2("");
  };

  const saveText = {
    [submitStatusValues.default]: "Update",
    [submitStatusValues.loading]: "Saving",
    [submitStatusValues.success]: "Success",
  }

  const passwordText = {
    [submitStatusValues.default]: "Change password",
    [submitStatusValues.loading]: "Saving",
    [submitStatusValues.success]: "Success",
  }

  return (
    <div className="profile-settings-wrapper">
      <div className="section">
        <div className="settings-profile-title">User info</div>
        <div className="settings-profile-section">
          <StyledTextField
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            label="First Name"
            className="settings-profile-input"
            placeholder="Enter Your First Name"
          />
          <StyledTextField
            label="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            className="settings-profile-input"
            placeholder="Enter Your Last Name"
          />
        </div>
        <div className="settings-profile-section">
          <StyledTextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            label="E-mail"
            className="settings-profile-input"
            placeholder="edwardgewin@gmail.com"
          />
        </div>
      </div>

      <div className="section">
        <div className="settings-profile-title">Theme</div>
        <div className="settings-profile-section">
          <div className={`theme-item ${theme === "system" ? "active" : ""}`} onClick={() => onChangeTheme("system")}>
            <div className="icon-wrapper">
              <ThemeDefaultIcon />
            </div>
            <div className="label">Default</div>
          </div>
          <div className={`theme-item ${theme === "light" ? "active" : ""}`} onClick={() => onChangeTheme("light")}>
            <div className="icon-wrapper">
              <ThemeLightIcon />
            </div>
            <div className="label">Light</div>
          </div>
          <div className={`theme-item ${theme === "dark" ? "active" : ""}`} onClick={() => onChangeTheme("dark")}>
            <div className="icon-wrapper">
              <ThemeDarkIcon />
            </div>
            <div className="label">Dark</div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="settings-profile-title">Password</div>
        <Button className="change-password-btn" onClick={() => setPasswordModalState(true)}>
          <ChangePasswordIcon />
          Change password
        </Button>
      </div>
      <div className="settings-profile-buttons">
        <Button className="cancel-btn" onClick={handleCancel}>
          Cancel
        </Button>

        <SubmitButton
          className="save-btn"
          status={saveStatus}
          onClick={handleUpdateUserProfile}
          disabled={isDisabled || (firstName === userInfo?.firstName && lastName === userInfo?.lastName && email === userInfo?.email)}
        >
          {saveText[saveStatus]}
        </SubmitButton>

        <Button
          onClick={() => dispatch(logout())}
          className="logout-btn"
        >
          <LogoutIcon color="var(--second-text)" />
          Sign Out
        </Button>
      </div>


      <ModalWrapper
        contentClassName="change-password-modal"
        title="Change password"
        open={passwordModalState}
        onClose={closeChangePasswordModal}
      >
        <div className="change-password-content">
          <StyledTextField
            value={newPassword1}
            onChange={e => setNewPassword1(e.target.value)}
            label="New Password"
            type={showPassword.new ? "text" : "password"}
            className="settings-profile-input"
            placeholder="Enter new password"
            onEndBtnClick={() => setShowPassword({ ...showPassword, new: !showPassword.new })}
            endBtnLabel={showPassword?.new ? <DisplayEye className={showPassword.new ? "active" : ""} /> : <HiddenEye />}
          />
          <StyledTextField
            value={newPassword2}
            onChange={e => setNewPassword2(e.target.value)}
            label="Repeat Password"
            type={showPassword.repeat ? "text" : "password"}
            className="settings-profile-input"
            placeholder="Repeat password"
            onEndBtnClick={() => setShowPassword({ ...showPassword, repeat: !showPassword.repeat })}
            endBtnLabel={showPassword?.repeat ? <DisplayEye className={showPassword.repeat ? "active" : ""} /> : <HiddenEye />}
          />
          <div className="btn-box">
            <Button className="cancel-btn" onClick={closeChangePasswordModal}>
              Cancel
            </Button>
            <SubmitButton
              className="apply-btn"
              status={passwordStatus}
              onClick={handleUpdatePassword}
              disabled={!newPassword1 || !newPassword2}
            >
              {passwordText[passwordStatus]}
            </SubmitButton>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default ProfileSettings;
