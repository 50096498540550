import React from "react";

const Logo = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8232_55961)">
      <rect width="32" height="32" rx="4" fill="var(--primary-color)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M-7.9264 -6.07375C1.45596 2.56905 5.54786 9.39552 5.33474 16.1946C5.12061 23.0261 0.561828 30.0539 -8.01709 39.0937L-7.12458 39.9407C1.45601 30.8992 6.33459 23.5705 6.56458 16.2332C6.79559 8.86356 2.33837 1.70896 -7.09274 -6.97874L-7.9264 -6.07375ZM13.1838 16.0533C13.3181 9.9737 11.0086 3.7188 5.769 -3.40594L6.76025 -4.13491C12.099 3.12472 14.5562 9.64099 14.4139 16.0805C14.2718 22.5133 11.5375 28.7671 6.70318 35.5066L5.70336 34.7894C10.4639 28.1528 13.0494 22.1396 13.1838 16.0533ZM16.7808 -1.1108C19.1992 5.14569 20.5931 10.317 20.6615 15.5956C20.7299 20.8683 19.4761 26.2994 16.5167 33.0681L17.6441 33.561C20.6506 26.6846 21.9632 21.0769 21.8919 15.5797C21.8207 10.0883 20.3688 4.75859 17.9285 -1.55443L16.7808 -1.1108ZM25.4372 -0.927987C30.2078 11.4871 29.3892 21.7425 25.3108 32.8526L26.4659 33.2766C30.6287 21.9364 31.4808 11.3695 26.5857 -1.36933L25.4372 -0.927987Z" fill="var(--primary-inner-color)"/>
    </g>
    <defs>
      <clipPath id="clip0_8232_55961">
        <rect width="32" height="32" rx="4" fill="var(--primary-inner-color)"/>
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
