import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tags } from "services/Tags";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { searchTypes } from "utils/searchFilters";

import StyledTooltip from "shared/StyledTooltip";
import TagLegendItem from "shared/TagLegendItem";
import DetailsSectionTitle from "shared/DetailsSectionTitle";
import LegendItem from "shared/LegendItem";

import Location from "assets/iconComponents/Location";

import "./styles.scss"

const OrganisationProfile = ({ isLoading, tagsUpdated }) => {
  const dispatch = useDispatch();
  const drawerData = useSelector(state => state.detailsDrawer);
  const searchId = drawerData?.id;

  const [isDataLoading, setIsDataLoading] = useState(true);

  const getData = async () => {
    setIsDataLoading(true);
    try {
      const companyTags = await Tags.getCompanyTags(searchId).catch(() => { });
      const res = {
        companyTags: companyTags?.tags || [],
      }
      dispatch(setDrawerState({ data: { ...drawerData.data, ...res } }));
    } catch (e) {
      console.log(e)
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (drawerData?.open && drawerData?.id && drawerData?.mainRequestFinished) {
      getData();
    }
    // eslint-disable-next-line
  }, [drawerData?.open, drawerData?.id, drawerData?.mainRequestFinished, tagsUpdated]);

  const companyLocation = drawerData?.data?.locations?.find(it => it?.isPrimary) || drawerData?.data?.locations?.[0];

  return (
    <div className="organisation-profile-wrapper">
      <div className="organisation-profile-content">
        {/* COMPANY DETAILS SECTION */}
        <div className="section">
          <DetailsSectionTitle label="About company" />
          <div className="section-item">
            <div className={`value big ${isLoading ? "loader" : ""}`}>
              {drawerData.data?.description || "- - -"}
            </div>
            {isLoading && <div className="value loader" />}
          </div>
        </div>

        <div className="divider" />

        {/* SPECIALITIES SECTION */}
        <div className="specialties-section">
          <DetailsSectionTitle label="Specialties" />
          <div className="legend-items-list">
            {isLoading ? (
              new Array(5).fill(0).map((it, i) => (
                <LegendItem isLoading className="loader" />
              ))
            ) : (
              <>
                {!!drawerData?.data?.specialties?.length ? (
                  drawerData?.data?.specialties?.map((val, i) => (
                    <LegendItem label={val.title} key={i} />
                  ))
                ) : (
                  <span className="empty-text">No specialties</span>
                )}
              </>
            )}
          </div>
        </div>

        <div className="divider" />

        {/* NAICS CODES SECTION */}
        <div className="codes-section">
          <DetailsSectionTitle label="NAICS codes" />
          <div className="legend-items-list">
            {isLoading ? (
              new Array(5).fill(0).map((it, i) => (
                <LegendItem isLoading className="loader" />
              ))
            ) : (
              <>
                {!!drawerData?.data?.naicsCodes?.length ? (
                  drawerData?.data?.naicsCodes?.map((val, i) => (
                    <LegendItem label={val?.code} tooltipContent={val?.title} key={i} />
                  ))
                ) : (
                  <span className="empty-text">No codes added</span>
                )}
              </>
            )}
          </div>
        </div>

        <div className="divider" />

        {/* LOCATION SECTION */}
        <div className="section">
          <DetailsSectionTitle label="Location" icon={<Location />} />

          <div className="location-items">
            <div className="item">
              <div className="label">Country</div>
              <div className={`value ${isLoading ? "loader" : ""}`}>{companyLocation?.inferredLocation?.countryRegion || "- - -"}</div>
            </div>
            <div className="item">
              <div className="label">Admin District</div>
              <div className={`value ${isLoading ? "loader" : ""}`}>{companyLocation?.inferredLocation?.adminDistrict || "- - -"}</div>
            </div>
            <div className="item">
              <div className="label">City</div>
              <div className={`value ${isLoading ? "loader" : ""}`}>{companyLocation?.inferredLocation?.locality || "- - -"}</div>
            </div>
            <div className="item">
              <div className="label">Neighborhood</div>
              <div className={`value ${isLoading ? "loader" : ""}`}>{companyLocation?.inferredLocation?.neighborhood || "- - -"}</div>
            </div>
            <div className="item">
              <div className="label">Postal Code</div>
              <div className={`value ${isLoading ? "loader" : ""}`}>{companyLocation?.inferredLocation?.postalCode || "- - -"}</div>
            </div>
            <div className="item">
              <div className="label">Address</div>
              <StyledTooltip title={companyLocation?.inferredLocation?.addressLine}>
                <div className={`value ${isLoading ? "loader" : ""}`}>
                  {companyLocation?.inferredLocation?.addressLine || "- - -"}
                </div>
              </StyledTooltip>
            </div>
          </div>
        </div>

        <div className="divider" />

        {/* TAGS SECTION */}
        <div className="tags-section">
          <DetailsSectionTitle label="Tags" />

          <div className="tags-wrapper">
            {isDataLoading ? (
              new Array(4).fill(0).map((_, i) => (
                <TagLegendItem isLoading key={`loader-tag-${i}`} />
              ))
            ) : (
              <>
                {drawerData?.data?.companyTags?.length ? drawerData?.data?.companyTags?.map((tag, i) => (
                  <TagLegendItem key={`${tag.name}-${i}`} name={tag.name} type={searchTypes.company} />
                )) : (
                  <span className="empty-text">No tags added</span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div >
  )
}

export default OrganisationProfile;