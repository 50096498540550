import React from "react";

const ManagementIcon = ({ color = "var(--second-text)" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="14.8127" cy="14.0625" rx="1.6875" ry="1.6875" transform="rotate(90 14.8127 14.0625)" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="8.99975" cy="14.0625" r="1.6875" transform="rotate(90 8.99975 14.0625)" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <ellipse cx="8.99975" cy="3.9375" rx="1.6875" ry="1.6875" transform="rotate(90 8.99975 3.9375)" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <ellipse cx="3.18823" cy="14.0625" rx="1.6875" ry="1.6875" transform="rotate(90 3.18823 14.0625)" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.8127 12.375L14.8127 10.5C14.8127 9.67125 14.1415 9 13.3127 9L4.68774 9C3.85899 9 3.18774 9.67125 3.18774 10.5L3.18774 12.375" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.00073 12.375L9.00073 5.625" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default ManagementIcon;
