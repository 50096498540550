import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { searchTypes } from "utils/searchFilters";
import { Tags } from "services/Tags";
import { AdvancedSearchService } from "services/AdvancedSearchService";
import { setFiltersOptions, setSearchRequestData, setSavedSearches } from "redux/actions/filters";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { SEARCH } from "pathnameVariables";
import { contactTableColumns, companyTableColumns, getRowOptions } from "utils/tableColumns";
import {
  getSelectedFilters,
  jsonToBase64,
  base64ToJson,
  convertFiltersForRequest,
  convertRequestForFilters
} from "helpers/searchHelper";
import { getContactName, downloadFile } from "helpers/common";

import PageContent from "shared/PageContent";
import CustomTable from "shared/CustomTable";
import TagsManagerPopover from "shared/TagsManagerPopover";

import SearchFilters from "./SearchFilters";
import "./styles.scss";

const Search = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const base64UrlQuery = new URLSearchParams(window.location.search).get("q");
  const typeUrlQuery = new URLSearchParams(window.location.search).get("type");
  const requestData = useSelector(state => state.filters.searchRequestData);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(true);
  const [prevRequestController, setPrevRequestController] = useState(null);
  const [searchError, setSearchError] = useState("");
  const [searchResult, setSearchResult] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [openTagPopover, setOpenTagPopover] = useState(null);
  const [tagItemsIds, setTagItemsIds] = useState([]);

  const getOptions = async () => {
    const [contactTags, companyTags, managementLevels, industries, departments, jobTitles, searches] = await Promise.all([
      Tags.getTags(searchTypes.contact).then(arr => arr?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)),
      Tags.getTags(searchTypes.company).then(arr => arr?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)),
      AdvancedSearchService.getManagementLevels(),
      AdvancedSearchService.getIndustries(),
      AdvancedSearchService.getDepartments(),
      AdvancedSearchService.getJobTitles(),
      AdvancedSearchService.getSavedSearches()
    ]);
    dispatch(setSavedSearches(searches));
    dispatch(setFiltersOptions({
      contactTags: contactTags?.map(val => ({ label: val.name, value: val.name })),
      companyTags: companyTags?.map(val => ({ label: val.name, value: val.name })),
      managementLevels,
      industries,
      departments,
      jobTitles
    }));
    setIsPageLoading(false);
    if (base64UrlQuery) {
      dispatch(setSearchRequestData({
        ...requestData,
        searchType: typeUrlQuery || searchTypes.contact,
        filter: convertRequestForFilters(base64ToJson(base64UrlQuery))
      }));
    }
  };

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line
  }, []);

  const getSearchData = async (request, page) => {
    if (prevRequestController) {
      prevRequestController.abort();
    }
    const controller = new AbortController();
    setPrevRequestController(controller);
    setIsSearchLoading(true);
    setSelectedItems([]);

    try {
      const data = request || requestData;
      const filtersData = convertFiltersForRequest(data?.filter);

      const base64 = jsonToBase64(filtersData);
      const res = await AdvancedSearchService.advancedSearch({
        q: base64,
        type: data?.searchType,
        page: page || 1,
        limit: 30,
        sortBy: data?.sortBy,
        order: data?.sortDir
      }, controller.signal);
      setSearchResult(res);
      if (window.location.pathname.includes(SEARCH)) {
        if (getSelectedFilters(data?.filter)?.summary?.length) {
          navigate(`${SEARCH}?q=${base64}&type=${data?.searchType}`);
        } else {
          navigate(SEARCH);
        }
      }
    } catch (e) {
      setSearchError(e?.response?.data?.message || "Something went wrong");
    } finally {
      setIsSearchLoading(false);
    }
  };

  // eslint-disable-next-line
  const debounceGetData = useCallback(debounce(getSearchData, 700), [prevRequestController])

  useEffect(() => {
    if (!isPageLoading) {
      setIsSearchLoading(true);
      debounceGetData(requestData);
    }
    // eslint-disable-next-line
  }, [requestData, isPageLoading]);

  const handleExport = async (itemsIds) => {
    try {
      const item = searchResult?.data?.find(val => [val.contactId, val.companyId].includes(itemsIds[0]));
      const type = requestData?.searchType === searchTypes.contact ? "contacts" : "companies";
      const name = requestData?.searchType === searchTypes.contact ? getContactName(item) : item?.companyName;
      const res = await AdvancedSearchService.exportCsv(type, itemsIds);
      await downloadFile(res, itemsIds?.length > 1 ? type : name);
    } catch (e) {
      console.log(e)
    }
  };

  const tagsUpdated = tags => {
    const idKey = requestData?.searchType === searchTypes.contact ? "contactId" : "companyId";
    setSearchResult({
      ...searchResult,
      data: searchResult?.data?.map(val => {
        if (val[idKey] === tagItemsIds[0]) {
          return { ...val, tags: tags?.map(tag => ({ tagName: tag.name })) }
        }
        return val;
      })
    })
  };

  const handleTag = (itemsIds, currentTarget) => {
    setOpenTagPopover(currentTarget);
    setTagItemsIds(itemsIds);
  };

  const actionEvents = {
    "export": handleExport,
    "tag": handleTag
  };

  return (
    <div className="search-page-wrapper">
      <SearchFilters isPageLoading={isPageLoading} setIsSearchLoading={setIsSearchLoading} />
      <PageContent>
        <CustomTable
          searchError={searchError}
          columns={requestData?.searchType === searchTypes.contact ? contactTableColumns : companyTableColumns}
          isLoading={isPageLoading || isSearchLoading}
          data={searchResult}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          itemIdKey={requestData?.searchType === searchTypes.contact ? "contactId" : "companyId"}
          sortBy={requestData?.sortBy}
          sortDir={requestData?.sortDir}
          onSort={(key, dir) => {
            const data = { ...requestData, sortDir: dir, sortBy: key };
            dispatch(setSearchRequestData(data));
            getSearchData(data);
          }}
          onChangePage={page => getSearchData(requestData, page)}
          popoverOptions={getRowOptions(requestData?.searchType)?.map(val => ({ ...val, action: actionEvents[val.value] }))}
          onRowClick={({ companyId, contactId }) => {
            if (companyId || contactId) {
              dispatch(setDrawerState({
                data: {},
                mainRequestFinished: false,
                open: true,
                id: companyId || contactId,
                isCompany: !!companyId
              }));
            }
          }}
        />
        <TagsManagerPopover
          state={openTagPopover}
          setState={setOpenTagPopover}
          tagType={requestData?.searchType}
          itemId={tagItemsIds[0]}
          onSuccess={tagsUpdated}
        />
      </PageContent>
    </div>
  )
};

export default Search;
