import React from "react";

const SendIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7352_11701)">
      <path d="M15.6958 9.15442H5.7958M15.6958 9.15442L3.5458 15.0044L5.7958 9.15442M15.6958 9.15442L3.5458 3.30442L5.7958 9.15442" stroke="var(--primary-inner-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.9774 3.5209C16.2886 3.39011 15.6682 3.06481 15.2265 2.60433C14.7849 2.14385 14.5443 1.56467 14.5443 0.97451V0.905273H14.4917V0.97451C14.4917 1.56467 14.2485 2.14385 13.8082 2.60433C13.3678 3.06481 12.7461 3.39121 12.0573 3.5209L11.9456 3.54288L12.0573 3.56376C12.7461 3.69454 13.3678 4.01984 13.8082 4.48142C14.2485 4.943 14.4917 5.52108 14.4917 6.11124V6.18048H14.5443V6.11124C14.5443 5.52108 14.7875 4.9419 15.2278 4.48142C15.6682 4.01984 16.2899 3.69454 16.9787 3.56376L17.0918 3.54178L16.9774 3.5209Z" fill="var(--primary-inner-color)"/>
    </g>
    <defs>
      <clipPath id="clip0_7352_11701">
        <rect width="18" height="18" fill="var(--primary-inner-color)"/>
      </clipPath>
    </defs>
  </svg>
);

export default SendIcon;
