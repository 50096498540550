import React from "react";
import { Button, CircularProgress } from "@mui/material";

import CheckMark from "assets/iconComponents/CheckMark";

import "./styles.scss";

export const submitStatusValues = {
  default: "default",
  loading: "loading",
  success: "success"
};

const SubmitButton = ({ status = submitStatusValues.default, disabled = false, onClick, className = "", children }) => {

  const statusValues = {
    default: {
      icon: null,
      className: "default"
    },
    loading: {
      icon: <CircularProgress size={10} thickness={7} />,
      className: "loading"
    },
    success: {
      icon: <CheckMark />,
      className: "success"
    }
  };

  const statusData = statusValues[status] ? statusValues[status] : statusValues[submitStatusValues.default];

  return (
    <Button
      onClick={onClick}
      className={`submit-button ${statusData.className || ""} ${disabled ? "disabled" : ""} ${className}`}
    >
      {statusData.icon}
      {children}
    </Button>
  );
};

export default SubmitButton;
