import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { detailsDrawerTabs } from "..";
import { searchTypes } from "utils/searchFilters";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { downloadFile, getNameAbbreviation } from "helpers/common";
import { AdvancedSearchService } from "services/AdvancedSearchService";

import AvatarComponent from "shared/AvatarComponent";
import TagsManagerPopover from "shared/TagsManagerPopover";
import DetailsSectionTitle from "shared/DetailsSectionTitle";
import { SearchProfileDetails } from "services/SearchDetails";
import DefaultPopover, { PopoverItem } from "shared/DefaultPopover";

import TagIcon from "assets/iconComponents/TagIcon";
import Location from "assets/iconComponents/Location";
import ThreeDots from "assets/iconComponents/ThreeDots";
import ExportIcon from "assets/iconComponents/ExportIcon";

import "./styles.scss";

const Card = ({ company, isLoading, onClick, popoverOptions }) => {
  const buttonRef = useRef();
  const [logo, setLogo] = useState("");
  const [open, setOpen] = useState(null);

  useEffect(() => {
    if (!company?.logo && company?.companyWebsite) {
      getAvatar(company?.companyWebsite);
    }
    // eslint-disable-next-line
  }, [company?.companyWebsite]);

  const getAvatar = () => {
    try {
      SearchProfileDetails.getCompanyAvatar(company?.companyWebsite).then(res => setLogo(res)).catch(() => { });
    } catch (e) { }
  }

  return (
    <div className={`card-item ${isLoading ? "loader" : ""}`}>
      <AvatarComponent url={company?.logo || logo} shortName={getNameAbbreviation(company?.companyName)} />
      <div className="text-box">
        <div className="name" onClick={onClick}>{company?.companyName}</div>
        <div className="location">
          <Location />
          <span>
            {company?.streetAddress}
          </span>
        </div>
      </div>
      {!!popoverOptions?.length && (
        <Button
          className="three-dots-btn"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(e.currentTarget);
          }}
          ref={buttonRef}
        >
          <ThreeDots />
        </Button>
      )}
      <DefaultPopover state={open} setState={setOpen}>
        {popoverOptions?.map((val, i) => (
          <PopoverItem
            label={val.label}
            key={i}
            onClick={() => {
              val.action(company, buttonRef);
              setOpen(null);
            }}
            icon={val.icon}
          />
        ))}
      </DefaultPopover>
    </div>
  );
};

const SimilarCompanies = ({ isLoading, setActiveTab }) => {
  const dispatch = useDispatch();
  const drawerData = useSelector(state => state.detailsDrawer);
  const [dataLoading, setDataLoading] = useState(true);
  const [activeItemId, setActiveItemId] = useState(null);
  const [openTagPopover, setOpenTagPopover] = useState(null);
  const searchId = drawerData?.id;

  const getData = async () => {
    setDataLoading(true);
    let similarCompanies = [];
    try {
      similarCompanies = await SearchProfileDetails.getSimilarCompanies(searchId);
    } catch (e) {
      console.log(e);
    }
    dispatch(setDrawerState({
      data: {
        ...drawerData.data,
        similarCompanies
      }
    }))
    setDataLoading(false);
  };

  useEffect(() => {
    if (drawerData?.open && drawerData?.id && drawerData?.mainRequestFinished) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerData?.open, drawerData?.id, drawerData?.mainRequestFinished]);

  const handleExport = async (item) => {
    try {
      const name = item?.companyName;
      const res = await AdvancedSearchService.exportCsv("companies", [item?.companyId]);
      await downloadFile(res, name);
    } catch (e) {
      console.log(e)
    }
  };

  const popoverOptions = [{
    label: "Export",
    value: "export",
    icon: <ExportIcon color="var(--main-text)" />,
    action: handleExport
  },
  {
    label: `Tag Contact`,
    value: "tag",
    icon: <TagIcon />,
    action: (item, buttonRef) => {
      setActiveItemId(item.companyId);
      setOpenTagPopover(buttonRef.current)
    }
  }];

  return (
    <div className="similar-companies-wrapper">
      <DetailsSectionTitle label="Similar Companies" />

      <div className="companies-wrapper">
        {(isLoading || dataLoading) ? (
          new Array(9).fill(0).map((it, i) => <Card key={`loader-card-${i}`} isLoading={isLoading || dataLoading} />)
        ) : drawerData?.data?.similarCompanies?.length ? drawerData?.data?.similarCompanies?.map(company => (
          <Card
            key={company.companyId}
            company={company}
            onClick={() => {
              dispatch(setDrawerState({
                data: {},
                mainRequestFinished: false,
                id: company.companyId,
                open: true,
                isCompany: true
              }));
              setActiveTab(detailsDrawerTabs[0].value);
            }}
            popoverOptions={popoverOptions}
          />
        )) : <div className="empty-text">No similar companies...</div>}
      </div>
      <TagsManagerPopover
        state={openTagPopover}
        setState={setOpenTagPopover}
        tagType={searchTypes.company}
        itemId={activeItemId}
      />
    </div>
  )
}

export default SimilarCompanies;