import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";

import { setDrawerState } from "redux/actions/detailsDrawer";
import { convertFiltersForRequest, jsonToBase64 } from "helpers/searchHelper";
import { initialSearchRequestData, searchTypes } from "utils/searchFilters";
import { setSearchRequestData } from "redux/actions/filters";
import { SEARCH } from "pathnameVariables";

import "./styles.scss";

const TagLegendItem = ({ name, type, isLoading }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onSearch = () => {
    dispatch(setDrawerState({ open: false, id: "", data: {} }));

    const key = type === searchTypes.contact ? "contact" : "company";
    const filter = {
      ...initialSearchRequestData.filter,
      [key]: {
        ...initialSearchRequestData.filter[key],
        tags: [name]
      }
    };

    if (pathname === SEARCH) {
      const requestData = {
        ...initialSearchRequestData,
        searchType: type,
        filter
      };
      dispatch(setSearchRequestData(requestData))
    } else {
      const filtersData = convertFiltersForRequest(filter);
      const base64 = jsonToBase64(filtersData);
      navigate(`${SEARCH}?q=${base64}&type=${type}`);
    }
  };

  return (
    <>
      {isLoading ? (
        <div key="tag-legend-item-btn-loader" className="tag-legend-item-btn loader" />
      ) : (
        <Button className="tag-legend-item-btn" onClick={onSearch}>
          {name}
        </Button>
      )}
    </>
  )
};

export default TagLegendItem;
