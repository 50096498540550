import React from "react";
import { Backdrop, Modal, Button } from "@mui/material";
import CloseIcon from "assets/iconComponents/CloseIcon";
import "./styles.scss";

const ModalWrapper = ({ open, onClose, children, contentClassName, title, isLoading }) => {
  return (
    <Modal
      className="modal-wrapper"
      open={Boolean(open)}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className={`modal-content ${contentClassName || ""}`}>
        {isLoading && <div className="modal-loader-box" />}
        <div className="modal-head">
          {title && <div className="title">{title}</div>}
          <Button onClick={onClose} className="close-modal-btn">
            <CloseIcon color="var(--second-text)" />
          </Button>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalWrapper;
