import React from "react";

const EmailIcon = ({ color = "var(--second-text)" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9509 6.7959L9.75186 9.3716C9.14645 9.84626 8.29778 9.84626 7.69236 9.3716L4.46582 6.7959" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.1659 2.625H12.2368C13.2564 2.63644 14.2268 3.06745 14.922 3.81765C15.6172 4.56786 15.9766 5.57177 15.9165 6.59559V11.4915C15.9766 12.5153 15.6172 13.5192 14.922 14.2694C14.2268 15.0196 13.2564 15.4506 12.2368 15.4621H5.1659C2.97597 15.4621 1.5 13.6805 1.5 11.4915V6.59559C1.5 4.40659 2.97597 2.625 5.1659 2.625Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EmailIcon;
