import React from "react";

const SearchIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.07499 3.35C5.46545 3.35 3.35 5.46545 3.35 8.07499C3.35 10.6845 5.46545 12.8 8.07499 12.8C10.6845 12.8 12.8 10.6845 12.8 8.07499C12.8 5.46545 10.6845 3.35 8.07499 3.35ZM2 8.07499C2 4.71987 4.71987 2 8.07499 2C11.4301 2 14.15 4.71987 14.15 8.07499C14.15 11.4301 11.4301 14.15 8.07499 14.15C4.71987 14.15 2 11.4301 2 8.07499Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.4113 11.4116C11.6749 11.148 12.1023 11.148 12.3659 11.4116L15.3021 14.3478C15.5657 14.6114 15.5657 15.0388 15.3021 15.3024C15.0385 15.566 14.6111 15.566 14.3475 15.3024L11.4113 12.3662C11.1477 12.1026 11.1477 11.6752 11.4113 11.4116Z" fill={color}/>
  </svg>
);

export default SearchIcon;
