import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button } from "@mui/material";

import { CHAT, SEARCH, TAGS, PROFILE_SETTING, PROFILE } from "pathnameVariables";
import { setRecentDialogsDrawerState } from "redux/actions/chat";
import { setBurgerOpen } from "redux/actions/auth";
import { setOpenProfileSidebar, setOpenSearchSidebar, setOpenTaggedSidebar } from "redux/actions/ui";
import { convertFiltersForRequest, getSelectedFilters, jsonToBase64 } from "helpers/searchHelper";

import StyledTooltip from "shared/StyledTooltip";

import Logo from "assets/iconComponents/Logo";
import TagIcon from "assets/iconComponents/TagIcon";
import ChatIcon from "assets/iconComponents/ChatIcon";
import SearchIcon from "assets/iconComponents/SearchIcon";
import RecentIcon from "assets/iconComponents/RecentIcon";
import CloseIcon from "assets/iconComponents/CloseIcon";
import BurgerMenuIcon from "assets/iconComponents/BurgerMenuIcon";

import "./styles.scss";

const NavigateBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { conversationId } = useParams();
  const clientWidth = useSelector(state => state.ui.clientWidth);
  const userInfo = useSelector(state => state.me.user);
  const burgerOpen = useSelector(state => state.me.burgerOpen);
  const recentChatId = useSelector(state => state.chat.recentChatId);
  const openRecentSidebar = useSelector(state => state.chat.recentDialogsDrawerState);
  const openSearchSidebar = useSelector(state => state.ui.openSearchSidebar);
  const openTaggedSidebar = useSelector(state => state.ui.openTaggedSidebar);
  const openProfileSidebar = useSelector(state => state.ui.openProfileSidebar);
  const requestData = useSelector(state => state.filters.searchRequestData);

  const openAllSidebars = () => {
    dispatch(setRecentDialogsDrawerState(true));
    dispatch(setOpenSearchSidebar(true));
    dispatch(setOpenTaggedSidebar(true));
    dispatch(setOpenProfileSidebar(true));
  };

  const getSearchPath = () => {
    if (getSelectedFilters(requestData?.filter)?.summary?.length) {
      const data = convertFiltersForRequest(requestData?.filter);
      const base64 = jsonToBase64(data);
      return `${SEARCH}?q=${base64}&type=${requestData?.searchType}`;
    }
    return SEARCH;
  };

  const tabPages = [
    {
      label: "Recent",
      to: conversationId ? null : `${CHAT}${recentChatId ? `/${recentChatId}` : ""}`,
      icon: RecentIcon,
      navigateState: recentChatId ? { state: { recentChat: true } } : {},
      onClick: () => setTimeout(openAllSidebars, 10),
      isActive: () => !!conversationId,
      activeClick: () => dispatch(setRecentDialogsDrawerState(!openRecentSidebar))
    },
    {
      label: "Search",
      to: getSearchPath(),
      icon: SearchIcon,
      onClick: () => setTimeout(openAllSidebars, 10),
      activeClick: () => dispatch(setOpenSearchSidebar(!openSearchSidebar))
    },
    {
      label: "Tagged",
      to: TAGS,
      icon: TagIcon,
      onClick: () => setTimeout(openAllSidebars, 10),
      activeClick: () => dispatch(setOpenTaggedSidebar(!openTaggedSidebar))
    }
  ];

  const handlePageClick = val => {
    const isActive = val.isActive ? val.isActive() : val.to === pathname;
    if (!isActive) {
      if (val.onClick) {
        val.onClick();
      }
      if (val.to) {
        navigate(val.to, val.navigateState);
      }
    }
    if (isActive && val.activeClick) {
      val.activeClick();
    }
  }

  return (
    <div className="navigate-bar-wrapper">
      <Button
        className={`mobile-menu-btn ${burgerOpen ? "opened" : ""}`}
        onClick={() => {
          if (burgerOpen) {
            dispatch(setRecentDialogsDrawerState(false))
          } else if (pathname.includes(CHAT)) {
            dispatch(setRecentDialogsDrawerState(true));
          }
          dispatch(setBurgerOpen(!burgerOpen))
        }}
      >
        <BurgerMenuIcon className='icon menu' />
        <CloseIcon className='icon close' />
      </Button>
      <div className="logo-wrapper">
        <Logo />
      </div>

      <div
        className={`backdrop ${burgerOpen ? "open" : ""}`}
        onClick={() => dispatch(setBurgerOpen(!burgerOpen))}
      />

      <div className={`content-wrapper ${burgerOpen ? "open" : ""}`}>
        <StyledTooltip title="New Chat" desktopOnly placement="right">
          <Button
            disabled={pathname.includes(CHAT) && !conversationId}
            className="new-chat-btn"
            onClick={() => {
              navigate(CHAT, { state: { newChat: true } });
              if (clientWidth < 1060) {
                dispatch(setRecentDialogsDrawerState(false));
              }
            }}
          >
            <ChatIcon />
          </Button>
        </StyledTooltip>
        <div className="tabs-list">
          {tabPages.map(val => (
            <StyledTooltip key={val.label} title={val.label} desktopOnly placement="right">
              <Button
                onClick={() => handlePageClick(val)}
                className={`tab-btn ${pathname.includes(val.to) ? "active" : ""}`}
              >
                <val.icon color={pathname.includes(val.to) ? "var(--primary-color)" : "var(--main-text)"} />
                <div className="btn-label">{val.label}</div>
              </Button>
            </StyledTooltip>
          ))}
        </div>
        <Button
          className="profile-btn"
          onClick={() => {
            if (pathname.includes(PROFILE)) {
              dispatch(setOpenProfileSidebar(!openProfileSidebar));
            } else {
              navigate(PROFILE_SETTING);
              openAllSidebars();
            }
          }}
        >
          {userInfo?.firstName && userInfo?.lastName ? `${userInfo?.firstName[0]}${userInfo?.lastName[0]}` : userInfo?.email?.[0]}
        </Button>
      </div>
    </div>
  )
};

export default NavigateBar;
