import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setBurgerOpen } from "redux/actions/auth";

import StyledTooltip from "shared/StyledTooltip";

import "./styles.scss";

const defaultBreakpoints = ["mobile", "tablet", "desktop"];

const PageSideBar = (
  {
    title,
    children,
    isLoading,
    headContent,
    className,
    isOpen,
    onClose,
    onOpen,
    breakpoints = defaultBreakpoints
  }
) => {
  const dispatch = useDispatch();
  const clientWidth = useSelector(state => state.ui.clientWidth);
  const burgerOpen = useSelector(state => state.me.burgerOpen);
  const deletedBreakpoints = defaultBreakpoints.filter(it => !breakpoints.find(item => item === it));
  const drawerClassName = `${breakpoints.join(" ")} ${deletedBreakpoints.map(it => `no-${it}`).join(" ")} ${isOpen ? "opened" : ""} ${onClose && onOpen ? "with-arrow" : ""}`;

  useEffect(() => {
    if (clientWidth < 768) {
      if (burgerOpen && onOpen) {
        onOpen();
      } else if (!burgerOpen && onClose) {
        onClose();
      }
    }
    // eslint-disable-next-line
  }, [burgerOpen]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setTimeout(() => {
      dispatch(setBurgerOpen(false));
    }, 100)
  }

  return (
    <>
      {onClose && (
        <div
          className={`page-side-bar-backdrop ${breakpoints.join(" ")} ${isOpen ? "show" : ""}`}
          onClick={handleClose}
        />
      )}
      <div className={`page-side-bar-wrapper ${drawerClassName} ${burgerOpen ? "with-burger-sidebar" : ""} ${className || ""}`}>
        <div className="page-side-bar-content">
          <div className="content-box">
            <div className="side-bar-title-wrapper">
              <div className={`side-bar-title ${isLoading ? "loading" : ""}`}>
                {title}
                {isLoading && <div className="title-loader" />}
              </div>
              {headContent && <div className="right-content">{headContent}</div>}
            </div>
            <div className="sidebar-content-wrapper">
              <div className="sidebar-content-scroll-box">
                <div className="sidebar-content">{children}</div>
              </div>
            </div>
          </div>
        </div>
        {(onClose && onOpen) && (
          <StyledTooltip title={`${isOpen ? "Close" : "Open"} sidebar`} placement="right">
            <div
              className={`page-side-bar-arrow ${isOpen ? "opened" : ""}`}
              onClick={isOpen ? onClose : onOpen}
            />
          </StyledTooltip>
        )}
      </div>
    </>
  )
};

export default PageSideBar;
