import React from "react";

const ProfileIcon = ({ color = "var(--second-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Iconly/Light/3 User">
      <g id="3 User">
        <path id="Stroke 1" d="M13.1959 8.19856C14.3977 8.19856 15.3725 7.22437 15.3725 6.02262C15.3725 4.82087 14.3977 3.84668 13.1959 3.84668" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 3" d="M14.1967 10.5635C14.5555 10.5882 14.9123 10.6391 15.2637 10.7182C15.7518 10.8137 16.3389 11.0138 16.5479 11.4517C16.6813 11.7322 16.6813 12.0588 16.5479 12.34C16.3396 12.7779 15.7518 12.9773 15.2637 13.0777" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 5" d="M4.71776 8.19856C3.51601 8.19856 2.54114 7.22437 2.54114 6.02262C2.54114 4.82087 3.51601 3.84668 4.71776 3.84668" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 7" d="M3.71686 10.5635C3.35798 10.5882 3.00117 10.6391 2.64986 10.7182C2.16173 10.8137 1.57461 11.0138 1.36629 11.4517C1.23223 11.7322 1.23223 12.0588 1.36629 12.34C1.57392 12.7779 2.16173 12.9773 2.64986 13.0777" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 9" fillRule="evenodd" clipRule="evenodd" d="M8.95361 11.0322C11.3887 11.0322 13.4691 11.4007 13.4691 12.8754C13.4691 14.3494 11.4025 14.7317 8.95361 14.7317C6.5178 14.7317 4.43811 14.3632 4.43811 12.8885C4.43811 11.4138 6.50474 11.0322 8.95361 11.0322Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Stroke 11" fillRule="evenodd" clipRule="evenodd" d="M8.95314 8.92869C7.34714 8.92869 6.05945 7.641 6.05945 6.03431C6.05945 4.42831 7.34714 3.14062 8.95314 3.14062C10.5591 3.14062 11.8468 4.42831 11.8468 6.03431C11.8468 7.641 10.5591 8.92869 8.95314 8.92869Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
  </svg>
);

export default ProfileIcon;
