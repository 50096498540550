export const getCurrentTheme = (themeType) => {
  if (themeType === "system") {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }
  return themeType;
};

export const getContactName = contact => {
  if (contact) {
    const fullName = [contact?.prefixName, contact?.firstName, contact?.middleName, contact?.lastName, contact?.suffixName];
    return fullName?.filter(val => !!val)?.join(" ");
  }
  return "";
};

export const getNameAbbreviation = (name, length = 3) => {
  if (name) {
    const arr = name.split(" ")?.filter(val => val);
    // eslint-disable-next-line
    let result = "";
    // eslint-disable-next-line
    arr.slice(0, length)?.forEach(val => result = result + val[0]);
    return result;
  }
  return "";
};

export const copyText = str => {
  const el = document.createElement("input");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const downloadFile = async (blob, fileName) => {
  const a = document.createElement("a");
  a.style = "display: none";
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${fileName}.csv`;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 100);
};


export const wrapAvatarUrl = url => `https://logo.clearbit.com/${url}`;
