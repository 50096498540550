import { ApiService } from "./ApiService";

export class AccountService {
  static async getMyAccountAsync() {
    return ApiService._requestAsync({
      url: "/accounts/me"
    });
  }

  static async updateUserProfile(data) {
    return ApiService._requestAsync({
      url: "/accounts/me/user-profile",
      method: "PUT",
      data
    });
  }
}
