import React from "react";

const ThemeDarkIcon = () => {
  return (
    <svg width="79" height="55" viewBox="0 0 79 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6884_154668)">
        <rect x="0.442871" width="77.7214" height="54.5544" rx="6.45899" fill="#131313" />
        <g filter="url(#filter0_dd_6884_154668)">
          <g clipPath="url(#clip1_6884_154668)">
            <rect x="7.11646" y="7.71484" width="93.4151" height="49.3232" rx="1.61475" fill="#131313" />
            <rect width="93.4151" height="13.4518" transform="translate(7.11646 7.71484)" fill="#131313" />
            <rect x="11.1021" y="10.7051" width="7.47321" height="7.47321" rx="1.49464" fill="#2E2E2E" />
            <rect x="20.8171" y="12.8262" width="20.925" height="3.22949" rx="1.61475" fill="#2E2E2E" />
            <rect x="45.728" y="12.8262" width="50.8178" height="3.22949" rx="1.61475" fill="#2E2E2E" />
            <rect width="93.4151" height="35.8714" transform="translate(7.11646 21.166)" fill="#1B1B1B" />
            <rect x="11.9607" y="25.4766" width="29.0654" height="9.68848" rx="1.49464" fill="#2E2E2E" />
            <rect x="11.9607" y="38.3945" width="29.0654" height="9.68848" rx="1.49464" fill="#2E2E2E" />
            <rect x="44.2556" y="25.4766" width="29.0654" height="9.68848" rx="1.49464" fill="#2E2E2E" />
            <rect x="44.2556" y="25.4766" width="29.0654" height="22.6065" rx="1.49464" fill="#2E2E2E" />
          </g>
          <rect x="7.27793" y="7.87632" width="93.0922" height="49.0002" rx="1.45327" stroke="#202020" strokeWidth="0.322949" />
        </g>
      </g>
      <rect x="0.816532" y="0.37366" width="76.9741" height="53.8071" rx="6.08533" stroke="#202020" strokeWidth="0.747321" />
      <defs>
        <filter id="filter0_dd_6884_154668" x="-68.4027" y="-43.2942" width="170.923" height="126.83" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-7.35304" dy="-2.45101" />
          <feGaussianBlur stdDeviation="3.14876" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.192157 0 0 0 0 0.639216 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6884_154668" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-36.7652" dy="-12.2551" />
          <feGaussianBlur stdDeviation="19.377" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.192157 0 0 0 0 0.639216 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_6884_154668" result="effect2_dropShadow_6884_154668" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6884_154668" result="shape" />
        </filter>
        <clipPath id="clip0_6884_154668">
          <rect x="0.442871" width="77.7214" height="54.5544" rx="6.45899" fill="white" />
        </clipPath>
        <clipPath id="clip1_6884_154668">
          <rect x="7.11646" y="7.71484" width="93.4151" height="49.3232" rx="1.61475" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThemeDarkIcon;
