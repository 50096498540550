import { handleActions } from "redux-actions";
import { initialSearchRequestData } from "utils/searchFilters";
import { setSearchRequestData, setFiltersOptions, setRevealedContacts, setSavedSearches } from "redux/actions/filters";

const initialState = {
  searchRequestData: initialSearchRequestData,
  filtersOptions: {},
  revealedContacts: [],
  savedSearches: []
};

const handleSetSearchRequestData = (state, { payload }) => ({ ...state, searchRequestData: payload });

const handleSetFiltersOptions = (state, { payload }) => ({ ...state, filtersOptions: payload });

const handleSetRevealedContacts = (state, { payload }) => ({ ...state, revealedContacts: payload });

const handleSetSavedSearches = (state, { payload }) => ({ ...state, savedSearches: payload });

const reducer = handleActions(
  {
    [setSearchRequestData]: handleSetSearchRequestData,
    [setFiltersOptions]: handleSetFiltersOptions,
    [setRevealedContacts]: handleSetRevealedContacts,
    [setSavedSearches]: handleSetSavedSearches
  },
  {
    ...initialState
  }
);

export default reducer;
