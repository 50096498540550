import React from "react";

const BookmarkIcon = ({ color = "var(--primary-color)" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.32738 15.1776L4.61628 17.7146C4.24013 17.9068 3.77673 17.7707 3.56937 17.4073V17.4073C3.50937 17.2947 3.47701 17.1699 3.47485 17.0428V5.80135C3.47485 3.65739 4.96525 2.7998 7.10996 2.7998H12.1482C14.2275 2.7998 15.7833 3.60022 15.7833 5.65841V17.0428C15.7833 17.2456 15.7014 17.4401 15.5555 17.5835C15.4096 17.7269 15.2117 17.8075 15.0054 17.8075C14.8738 17.8055 14.7445 17.7737 14.6274 17.7146L9.88718 15.1776C9.71251 15.0848 9.50205 15.0848 9.32738 15.1776Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.71021 7.91006H12.5046" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>


);

export default BookmarkIcon;
