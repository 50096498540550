import { handleActions } from "redux-actions";
import { setClientWidth, setOpenProfileSidebar, setOpenSearchSidebar, setOpenTaggedSidebar } from "redux/actions/ui";

const initialState = {
  clientWidth: document.documentElement.clientWidth,
  openSearchSidebar: true,
  openTaggedSidebar: true,
  openProfileSidebar: true,
};

const handleSetClientWidth = (state, { payload }) => ({ ...state, clientWidth: payload });

const handleSetOpenSearchSidebar = (state, { payload }) => ({ ...state, openSearchSidebar: payload });
const handleSetOpenTaggedSidebar = (state, { payload }) => ({ ...state, openTaggedSidebar: payload });
const handleSetOpenProfileSidebar = (state, { payload }) => ({ ...state, openProfileSidebar: payload });

const reducer = handleActions(
  {
    [setClientWidth]: handleSetClientWidth,
    [setOpenSearchSidebar]: handleSetOpenSearchSidebar,
    [setOpenTaggedSidebar]: handleSetOpenTaggedSidebar,
    [setOpenProfileSidebar]: handleSetOpenProfileSidebar

  },
  {
    ...initialState
  }
);

export default reducer;
