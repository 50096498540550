import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import { authorizeAsync } from "redux/actions/auth";
import { DataSysAuth0Client } from "security/DataSysAuth0Client";
import { AccountService } from "services/AccountService";
import { InitService } from "../../../services/InitService";

const logger = console;

function* authSaga({ payload }) {
  try {
    yield call(DataSysAuth0Client.initAsync, payload.domain, payload.clientId, payload.audience);
    const isAuth = yield call(DataSysAuth0Client.isAuthenticatedAsync);
    if (!isAuth) {
      yield call(DataSysAuth0Client.loginWithRedirectAsync);
    }

    yield call(InitService.initAsync);

    const accountScope = yield call(AccountService.getMyAccountAsync);

    yield put(
      authorizeAsync.success({
        ...accountScope,
        accountId: accountScope?.accountId,
        firstName: accountScope?.firstName,
        lastName: accountScope?.lastName,
        email: accountScope?.email,
        isEmailConfirmed: accountScope?.isEmailConfirmed
      })
    );
  } catch (e) {
    logger.error("authSaga", e.message);
    yield put(authorizeAsync.fail(e));
    yield call(DataSysAuth0Client.logoutAsync);
  }
}

function* getMeInfo() {
  try {
    const accountScope = yield call(AccountService.getMyAccountAsync);
    yield put(authorizeAsync.success(accountScope));
  } catch (e) {
    console.log(e);
  }
}

export default function* handleAuthSaga() {
  yield takeEvery("GET_ME_INFO", getMeInfo);
  yield takeLatest(authorizeAsync.request.toString(), authSaga);
}
