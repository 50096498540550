import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

import {
  searchTypes,
  businessModelOptions,
  industryTypeOptions,
  classificationCodeOptions,
  locationOptions,
  initialSearchRequestData
} from "utils/searchFilters";
import { getSelectedFilters, removeFilter } from "helpers/searchHelper";

import StyledTextField from "shared/StyledTextField";
import FilterAccordion from "shared/FilterAccordion";
import StyledAutocomplete from "shared/StyledAutocomplete";
import CheckboxList from "shared/CheckboxList";
import CustomCheckbox from "shared/CustomCheckbox";
import KeywordsTextField from "shared/KeywordsTextField";
import StyledSelect from "shared/StyledSelect";
import TagsManagerPopover from "shared/TagsManagerPopover";

import ContactFilterIcon from "assets/iconComponents/ContactFilterIcon";
import CompanyFilterIcon from "assets/iconComponents/CompanyFilterIcon";
import IndustryFilterIcon from "assets/iconComponents/IndustryFilterIcon";
import LocationFilterIcon from "assets/iconComponents/LocationFilterIcon";
import TagsFilterIcon from "assets/iconComponents/TagsFilterIcon";
import ClearFiltersIcon from "assets/iconComponents/ClearFiltersIcon";
import SaveIcon from "assets/iconComponents/SaveIcon";

import SaveSearchModal from "../SaveSearchModal";
import "./styles.scss";

const FiltersList = ({ isPageLoading, setRequestFilter }) => {
  const options = useSelector(state => state.filters.filtersOptions);
  const requestFilter = useSelector(state => state.filters.searchRequestData.filter);
  const { contact, company, location } = requestFilter;
  const [openSaveSearchModal, setOpenSaveSearchModal] = useState(false);
  const [openTagsPopover, setOpenTagsPopover] = useState(null);
  const [tagsPopoverType, setTagsPopoverType] = useState(null);

  const onRemoveSelected = (data) => {
    if (data?.selected) {
      return setRequestFilter(data?.selected?.reduce((acc, curr) => (
        removeFilter({ sectionKey: curr.sectionKey, key: curr.key, filters: acc })
      ), requestFilter));
    }
    if (data) {
      return setRequestFilter(removeFilter({ ...data, filters: requestFilter }));
    }
    return setRequestFilter(initialSearchRequestData.filter);
  };

  return (
    <div className="filters-list">
      <FilterAccordion
        label="Contact"
        isLoading={isPageLoading}
        icon={ContactFilterIcon}
        selected={getSelectedFilters(requestFilter)?.contact}
        onRemoveSelected={onRemoveSelected}
      >
        <StyledTextField
          label="Contact Name/Email"
          placeholder="Type here..."
          value={contact?.contactNameOrEmail}
          onChange={e => setRequestFilter({ ...requestFilter, contact: { ...contact, contactNameOrEmail: e.target.value } })}
        />
        <StyledAutocomplete
          label="Job Title"
          placeholder="Type here..."
          onChange={(e, newVal) => setRequestFilter({ ...requestFilter, contact: { ...contact, jobTitle: newVal?.value } })}
          value={contact?.jobTitle}
          options={options?.jobTitles?.map(it => ({ ...it, item: it }))}
        />
        <CustomCheckbox
          label="Current Employment"
          checked={contact?.isCurrentEmployment}
          onChange={checked => setRequestFilter({ ...requestFilter, contact: { ...contact, isCurrentEmployment: checked } })}
        />
        <CheckboxList
          label="Management Level"
          options={options?.managementLevels || []}
          value={contact?.managementLevels}
          onChange={val => setRequestFilter({ ...requestFilter, contact: { ...contact, managementLevels: val } })}
        />
        <CheckboxList
          label="Departments"
          options={options?.departments || []}
          value={contact?.departments}
          onChange={val => setRequestFilter({ ...requestFilter, contact: { ...contact, departments: val } })}
        />
      </FilterAccordion>
      <FilterAccordion
        label="Company"
        isLoading={isPageLoading}
        icon={CompanyFilterIcon}
        selected={getSelectedFilters(requestFilter)?.company}
        onRemoveSelected={onRemoveSelected}
      >
        <StyledTextField
          label="Company Name"
          placeholder="Type here..."
          value={company?.companyName}
          onChange={e => setRequestFilter({ ...requestFilter, company: { ...company, companyName: e.target.value } })}
        />
        <CheckboxList
          label="Business model"
          options={businessModelOptions}
          value={company?.businessModels}
          onChange={newArr => setRequestFilter({ ...requestFilter, company: { ...company, businessModels: newArr } })}
          canCollapse={false}
        />
        <div className="double-field-box">
          <div className="field-box-label">Employee Count</div>
          <StyledTextField
            type="number"
            placeholder="Min"
            value={`${company?.employeeCount?.min || ""}`}
            onChange={e => setRequestFilter({
                ...requestFilter,
                company: { ...company, employeeCount: { ...company?.employeeCount, min: e.target.value ? +e.target.value : 0 } }
              }
            )}
          />
          <StyledTextField
            type="number"
            placeholder="Max"
            value={`${company?.employeeCount?.max || ""}`}
            onChange={e => setRequestFilter({
                ...requestFilter,
                company: { ...company, employeeCount: { ...company?.employeeCount, max: e.target.value ? +e.target.value : 0 } }
              }
            )}
          />
        </div>
        <KeywordsTextField
          label="Company Keywords"
          placeholder="Business"
          value={company?.companyKeywords}
          onChange={val => setRequestFilter({ ...requestFilter, company: { ...company, companyKeywords: val } })}
        />
      </FilterAccordion>
      <FilterAccordion
        label="Industry"
        isLoading={isPageLoading}
        icon={IndustryFilterIcon}
        selected={getSelectedFilters(requestFilter)?.industry}
        onRemoveSelected={onRemoveSelected}
      >
        <CheckboxList
          radioButton
          label="Industry Type"
          options={industryTypeOptions}
          value={company?.industryType}
          onChange={val => setRequestFilter({ ...requestFilter, company: { ...company, industryType: val } })}
          canCollapse={false}
        />
        <CheckboxList
          label="Industries"
          options={options?.industries || []}
          value={company?.industries}
          onChange={val => setRequestFilter({ ...requestFilter, company: { ...company, industries: val } })}
        />
        <KeywordsTextField
          label="Industry Keywords"
          placeholder="Electronics"
          value={company?.industryKeywords}
          onChange={val => setRequestFilter({ ...requestFilter, company: { ...company, industryKeywords: val } })}
        />
        <div className="double-field-box">
          <div className="field-box-label">Industry Classification Code</div>
          <StyledSelect
            options={classificationCodeOptions}
            value={company?.industryCodeType}
            onChange={e => setRequestFilter({ ...requestFilter, company: { ...company, industryCodeType: e.target.value } })}
          />
          <StyledTextField
            placeholder="0800"
            value={company?.industryCode}
            onChange={e => setRequestFilter({ ...requestFilter, company: { ...company, industryCode: e.target.value } })}
          />
        </div>
      </FilterAccordion>
      <FilterAccordion
        label="Location"
        isLoading={isPageLoading}
        icon={LocationFilterIcon}
        selected={getSelectedFilters(requestFilter)?.location}
        onRemoveSelected={onRemoveSelected}
      >
        <StyledTextField
          label="Location Name"
          placeholder="New York"
          value={location?.locationString}
          onChange={e => setRequestFilter({ ...requestFilter, location: { ...location, locationString: e.target.value } })}
        />
        <CheckboxList
          options={locationOptions}
          value={location?.districts}
          onChange={val => setRequestFilter({ ...requestFilter, location: { ...location, districts: val } })}
          canCollapse={false}
        />
        <StyledTextField
          label="Postal Code"
          placeholder="10001"
          value={location?.postalCode}
          onChange={e => setRequestFilter({ ...requestFilter, location: { ...location, postalCode: e.target.value } })}
        />
        <StyledTextField
          label="Street"
          placeholder="e.g 100 Main Street"
          value={location?.streetAddress}
          onChange={e => setRequestFilter({ ...requestFilter, location: { ...location, streetAddress: e.target.value } })}
        />
        <StyledTextField
          placeholder="Suite"
          value={location?.suite}
          onChange={e => setRequestFilter({ ...requestFilter, location: { ...location, suite: e.target.value } })}
        />
      </FilterAccordion>
      <FilterAccordion
        label="Tags"
        isLoading={isPageLoading}
        icon={TagsFilterIcon}
        selected={getSelectedFilters(requestFilter)?.tags}
        onRemoveSelected={onRemoveSelected}
      >
        <CheckboxList
          label="Contact Tags"
          options={options?.contactTags || []}
          value={contact?.tags}
          onChange={val => setRequestFilter({ ...requestFilter, contact: { ...contact, tags: val } })}
          canCollapse={false}
          defaultAll={false}
          onLabelBtnClick={e => {
            setOpenTagsPopover(e.currentTarget);
            setTagsPopoverType(searchTypes.contact);
          }}
          labelBtnLabel="Create"
        />
        <CheckboxList
          label="Company Tags"
          options={options?.companyTags || []}
          value={company?.tags}
          onChange={val => setRequestFilter({ ...requestFilter, company: { ...company, tags: val } })}
          canCollapse={false}
          defaultAll={false}
          onLabelBtnClick={e => {
            setOpenTagsPopover(e.currentTarget);
            setTagsPopoverType(searchTypes.company);
          }}
          labelBtnLabel="Create"
        />
        <TagsManagerPopover
          state={openTagsPopover}
          setState={setOpenTagsPopover}
          tagType={tagsPopoverType}
          createOnly
        />
      </FilterAccordion>
      {!!getSelectedFilters(requestFilter)?.summary?.length && (
        <div className="selected-filters-options">
          {isPageLoading ? (
            <>
              <div className="btn-loader" />
              <div className="btn-loader" />
            </>
          ) : (
            <>
              <Button className="clear-btn" onClick={() => onRemoveSelected()}>
                <ClearFiltersIcon color="var(--primary-color)" />
                Clear Filters
              </Button>
              <Button className="save-btn" onClick={() => setOpenSaveSearchModal(true)}>
                <SaveIcon color="var(--primary-inner-color)" />
                Save Search
              </Button>
            </>
          )}
          <SaveSearchModal open={openSaveSearchModal} onClose={() => setOpenSaveSearchModal(false)} />
        </div>
      )}
    </div>
  )
};

export default FiltersList;
