import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";

import SelectIcon from "assets/iconComponents/SelectIcon";

import "./styles.scss";

const StyledSelect = (
  {
    label,
    value,
    onChange,
    options,
    placeholder,
    menuProps,
    error,
    className,
    isLoading,
    ...props
  }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`styled-select-wrapper ${className || ""}`}>
      {label && <div className="select-label">{label}</div>}
      {isLoading ? (
        <div className="select-loader">
          <div className="loader-content">
            <div className="icon-loader" />
            <div className="label-loader" />
          </div>
        </div>
      ) : (
        <>
          <Select
            MenuProps={{
              ...menuProps,
              className: `select-menu-wrapper ${menuProps?.menuClassName || ""}`
            }}
            className={`select-wrapper ${isLoading ? "loading" : ""}`}
            value={value}
            IconComponent={SelectIcon}
            error={!!error}
            open={open}
            onChange={onChange}
            onOpen={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            }}
            onClose={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(false);
            }}
            {...props}
          >
            {options.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                className="styled-select-option-label"
                onClick={() => {
                  setOpen(false);
                }}
              >
                {option.icon}
                <div className="option-label">
                  <span>{option.label}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
          {error && <div className="error-box">{error}</div>}
        </>
      )}
    </div>
  )
};

export default StyledSelect;
