import React from "react";

const CompanyIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.10305 11.6074C2.10305 11.6074 2.20955 12.9109 2.2343 13.3219C2.2673 13.8732 2.4803 14.4889 2.8358 14.9164C3.33755 15.5224 3.92855 15.7362 4.71755 15.7377C5.6453 15.7392 12.3916 15.7392 13.3193 15.7377C14.1083 15.7362 14.6993 15.5224 15.2011 14.9164C15.5566 14.4889 15.7696 13.8732 15.8033 13.3219C15.8273 12.9109 15.9338 11.6074 15.9338 11.6074" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.37198 3.99675V3.7185C6.37198 2.8035 7.11298 2.0625 8.02798 2.0625H9.96448C10.8787 2.0625 11.6205 2.8035 11.6205 3.7185L11.6212 3.99675" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.99622 12.5086V11.5381" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.0624 6.29155V8.8918C3.5009 9.84055 5.2244 10.505 7.1159 10.7683C7.3424 9.94255 8.08715 9.3373 8.9924 9.3373C9.8834 9.3373 10.6432 9.94255 10.8547 10.7758C12.7537 10.5125 14.4839 9.84805 15.9299 8.8918V6.29155C15.9299 5.02105 14.9077 3.99805 13.6372 3.99805H4.36265C3.09215 3.99805 2.0624 5.02105 2.0624 6.29155Z" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default CompanyIcon;
