import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";

import { Tags } from "services/Tags";
import { searchTypes } from "utils/searchFilters";
import { setFiltersOptions } from "redux/actions/filters";

import DefaultPopover from "shared/DefaultPopover";
import StyledTextField from "shared/StyledTextField";
import CustomCheckbox from "shared/CustomCheckbox";
import SubmitButton, { submitStatusValues } from "shared/SubmitButton";

import "./styles.scss";

const TagsManagerPopoverContent = ({ setState, createOnly, tagType, itemId, onSuccess, onTagCreated }) => {
  const dispatch = useDispatch();
  const options = useSelector(state => state.filters.filtersOptions);
  const [isTagsLoading, setIsTagsLoading] = useState(true);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [itemTagsList, setItemTagsList] = useState([]);
  const [tagName, setTagName] = useState("");
  const [createError, setCreateError] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [checkedTags, setCheckedTags] = useState([]);
  const [createStatus, setCreateStatus] = useState(submitStatusValues.default);
  const [updateStatus, setUpdateStatus] = useState(submitStatusValues.default);

  const getChanges = () => {
    const needRemove = itemTagsList?.filter(val => !checkedTags.some(it => it.name === val.name));
    const needAdd = checkedTags?.filter(val => !itemTagsList.some(it => it.name === val.name));
    return { needRemove, needAdd };
  };

  const getItemTags = async () => {
    try {
      let res = [];
      if (tagType === searchTypes.contact) {
        res = await Tags.getContactTags(itemId);
      } else {
        res = await Tags.getCompanyTags(itemId);
      }
      setCheckedTags(res?.tags || []);
      setItemTagsList(res?.tags || []);
      return res?.tags;
    } catch (e) {
      console.log(e);
      setCheckedTags([]);
      setItemTagsList([]);
      return [];
    }
  };

  const getData = async () => {
    const [list] = await Promise.all([
      Tags.getTags(tagType),
      ...(itemId && tagType ? [
        getItemTags()
      ] : [])
    ]);
    setTagsList(list?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1));
    setIsTagsLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const createTag = async () => {
    setCreateStatus(submitStatusValues.loading);
    if (tagsList?.some(val => val.name === tagName)) {
      setCreateStatus(submitStatusValues.default);
      return setCreateError(`Tag "${tagName}" already exist.`);
    }
    try {
      const createdTag = await Tags.createTag({ name: tagName, type: tagType });
      const list = await Tags.getTags(tagType);
      const key = tagType === searchTypes.contact ? "contactTags" : "companyTags";
      dispatch(setFiltersOptions({
        ...options,
        [key]: [
          { label: createdTag.name, value: createdTag.name },
          ...(options[key] || [])
        ]
      }));
      if (onTagCreated) {
        onTagCreated(list);
      }
      setTagsList(list?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1));
      setTagName("");
      setCreateStatus(submitStatusValues.success);
      setTimeout(() => setCreateStatus(submitStatusValues.default), 2000);
      if (createOnly) {
        setState(null);
      }
    } catch (e) {
      setCreateError(e?.response?.data?.message || "Something went wrong");
      setCreateStatus(submitStatusValues.default);
    }
  };

  const handleUpdateTags = async () => {
    const { needRemove, needAdd } = getChanges();
    const onRemove = {
      [searchTypes.contact]: Tags.deleteContactFromTag,
      [searchTypes.company]: Tags.deleteCompanyFromTag
    }
    const onAdd = {
      [searchTypes.contact]: Tags.addContactToTag,
      [searchTypes.company]: Tags.addCompanyToTag
    }
    setUpdateStatus(submitStatusValues.loading);
    try {
      await Promise.allSettled([
        ...needRemove?.length ? [onRemove[tagType](itemId, needRemove.map(tag => tag.name))] : [],
        ...needAdd?.length ? [onAdd[tagType](itemId, needAdd.map(tag => tag.name))] : []
      ]);
      const currentItemTags = await getItemTags();

      setUpdateStatus(submitStatusValues.success);
      setTimeout(() => setUpdateStatus(submitStatusValues.default), 2000);

      if (onSuccess) {
        onSuccess(currentItemTags);
      }
    } catch (e) {
      console.log(e);
      setUpdateStatus(submitStatusValues.default);
    }
  };

  const createText = {
    [submitStatusValues.default]: "Create",
    [submitStatusValues.loading]: "Creating",
    [submitStatusValues.success]: "Created",
  }

  const updateText = {
    [submitStatusValues.default]: "Update",
    [submitStatusValues.loading]: "Updating",
    [submitStatusValues.success]: "Updated",
  }


  return (
    <>
      <div className="popover-title">
        {createOnly ? "Create Tag" : "Tag / Untag"}
      </div>
      <div className="create-tab-box">
        <StyledTextField
          label="Create a new tag"
          placeholder="Enter tag name"
          value={tagName}
          onChange={e => {
            setTagName(e.target.value);
            setCreateError("");
          }}
        />
        <SubmitButton
          className="create-tag-btn"
          status={createStatus}
          disabled={!tagName || isCreateLoading}
          onClick={createTag}
        >
          {createText[createStatus]}
        </SubmitButton>
        {createError && <div className="error-box">{createError}</div>}
      </div>
      {!createOnly && (
        <>
          <div className="tags-list">
            {isTagsLoading ? (
              new Array(3).fill(0).map((_, i) => <CustomCheckbox key={i} isLoading label="-" />)
            ) : (
              <>
                {!!tagsList?.length && (
                  tagsList?.map((val, i) => (
                    <CustomCheckbox
                      key={i}
                      label={val.name}
                      checked={checkedTags?.some(tag => tag.name === val.name)}
                      onChange={checked => {
                        if (checked) {
                          return setCheckedTags([...checkedTags, val]);
                        }
                        return setCheckedTags(checkedTags?.filter(tag => tag.name !== val.name));
                      }}
                      disabled={updateLoading}
                    />
                  ))
                )}
              </>
            )}
          </div>
          {!!tagsList?.length && (
            <SubmitButton
              className="update-btn"
              status={updateStatus}
              onClick={handleUpdateTags}
              disabled={updateLoading || ![...getChanges().needRemove, ...getChanges().needAdd].length}
            >
              {updateText[updateStatus]}
            </SubmitButton>
          )}
        </>
      )}
    </>
  )
}

const TagsManagerPopover = ({ state, setState, createOnly, tagType, itemId, onSuccess, onTagCreated }) => {
  return (
    <DefaultPopover className="tags-manager-popover" state={state} setState={setState}>
      <TagsManagerPopoverContent
        itemId={itemId}
        tagType={tagType}
        setState={setState}
        createOnly={createOnly}
        onSuccess={onSuccess}
        onTagCreated={onTagCreated}
      />
    </DefaultPopover>
  )
};

export default TagsManagerPopover;
