import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { PROFILE_SETTING } from "pathnameVariables";
import { logout, setBurgerOpen } from "redux/actions/auth";

import PageSideBar from "shared/PageSideBar";
import PageContent from "shared/PageContent";

import LeftArrow from "assets/iconComponents/LeftArrow";
import LogoutIcon from "assets/iconComponents/LogoutIcon";
import SettingsIcon from "assets/iconComponents/SettingsIcon";

import "./styles.scss";
import { setOpenProfileSidebar } from "redux/actions/ui";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const openSidebar = useSelector(state => state.ui.openProfileSidebar);

  useEffect(() => {
    navigate(PROFILE_SETTING);
    // eslint-disable-next-line
  }, []);

  const profileChildRoutes = [
    {
      label: "User Settings",
      path: PROFILE_SETTING,
      icon: SettingsIcon
    },
    // {
    //   label: "Pricing & Plans",
    //   path: PROFILE_PLANS,
    //   icon: PricingIcon
    // },
  ];

  const handleOpenSIdebar = () => {
    setTimeout(() => {
      dispatch(setOpenProfileSidebar(true));
    }, 100)
  };

  const handleCloseSIdebar = () => {
    dispatch(setOpenProfileSidebar(false));
  }

  return (
    <div className="profile-page-wrapper">
      <PageSideBar
        title="Profile"
        isOpen={openSidebar}
        onOpen={handleOpenSIdebar}
        onClose={handleCloseSIdebar}
      >
        <div className="routes">
          {profileChildRoutes.map(val => (
            <Button
              key={val.label}
              onClick={() => navigate(val.path)}
              className={`tab-btn ${pathname.includes(val.path) ? "active" : ""}`}
            >
              <val.icon color={pathname.includes(val.path) ? "var(--primary-color)" : "var(--main-text)"} />
              {val.label}
              <LeftArrow className="arrow" color={pathname.includes(val.path) ? "var(--primary-color)" : "var(--main-text)"} />
            </Button>
          ))}
        </div>
        <Button
          onClick={() => dispatch(logout())}
          className="logout-btn"
        >
          <LogoutIcon color="var(--second-text)" />
          Sign Out
        </Button>
      </PageSideBar>
      <PageContent>
        <Outlet />
      </PageContent>
    </div>
  )
};

export default Profile;
