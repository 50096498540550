import React from "react";
import { Button } from "@mui/material";

import CheckMark from "assets/iconComponents/CheckMark";

import "./styles.scss";

const CustomCheckbox = ({ label, checked, onChange, disabled, radioButton, rightContent, isLoading }) => {
  if (isLoading) {
    return (
      <div className={`custom-checkbox-wrapper ${radioButton ? "radio" : ""}`}>
        <div key="check-mark-loader" className="check-mark-loader" />
        {label && (
          <div className="checkbox-label">
            <div className="checkbox-label-loader" />
          </div>
        )}
      </div>
    )
  }
  return (
    <Button
      disabled={disabled}
      className={`custom-checkbox-wrapper ${checked ? "active" : ""} ${radioButton ? "radio" : ""}`}
      onClick={e => onChange(!checked, e)}
    >
      <div className="check-mark">
        <CheckMark />
      </div>
      {label && <div className="checkbox-label">{label}</div>}
      {rightContent && <div className="checkbox-right-content">{rightContent}</div>}
    </Button>
  )
}

export default CustomCheckbox;
