import React from "react";
import moment from "moment";

import { getContactName } from "helpers/common";
import { searchTypes } from "utils/searchFilters";

import NameColumn from "shared/CustomTable/components/NameColumn";
import CompanyNameColumn from "shared/CustomTable/components/CompanyNameColumn";
import ContactsColumn from "shared/CustomTable/components/ContactsColumn";

import ExportIcon from "assets/iconComponents/ExportIcon";
import TagIcon from "assets/iconComponents/TagIcon";
import { getNameAbbreviation } from "helpers/common";
import { getEmploymentExperiences } from "helpers/searchHelper";

export const getRowOptions = (type) => ([
  {
    label: "Export",
    multiLabel: "Export Selected",
    value: "export",
    icon: <ExportIcon color="var(--main-text)" />
  },
  {
    label: `Tag ${type === searchTypes.contact ? "Contact" : "Company"}`,
    value: "tag",
    disabledMulti: true,
    icon: <TagIcon />
  }
]);

export const contactTableColumns = [
  {
    label: "Name / Job",
    getValue: (row, isLoading) => (
      <NameColumn
        isLoading={isLoading}
        avatar={row?.avatar}
        title={getContactName(row) || "- - -"}
        subtitle={getEmploymentExperiences(row?.employmentExperiences)?.lastCompany?.jobTitle || "- - -"}
        abbreviation={`${row?.firstName?.[0] || ""}${row?.lastName?.[0] || ""}`}
        contactId={row?.contactId}
        tags={row?.tags}
      />
    ),
    isComponent: true,
    style: { minWidth: "250px" }
  },
  {
    label: "Company",
    getValue: (row, isLoading) => {
      const { companyWebsite, companyName, companyId } = getEmploymentExperiences(row?.employmentExperiences)?.lastCompany;
      return (
        <CompanyNameColumn
          companyLogo={companyWebsite}
          companyName={companyName}
          companyId={companyId}
          isLoading={isLoading}
        />
      )
    },
    style: { minWidth: "180px" },
    isComponent: true,
  },
  {
    label: "Contacts",
    getValue: (row, isLoading) => (
      <ContactsColumn
        isLoading={isLoading}
        contactId={row?.contactId}
      />
    ),
    style: { minWidth: "70px" }
  },
  {
    label: "Management levels",
    getValue: row => getEmploymentExperiences(row?.employmentExperiences)?.lastCompany?.managementLevel,
    style: { minWidth: "115px" }
  },
  {
    label: "Departments",
    getValue: row => getEmploymentExperiences(row?.employmentExperiences)?.lastCompany?.department,
    style: { minWidth: "80px" }
  },
  {
    label: "Updated",
    getValue: row => row?.updatedAt ? moment(row?.updatedAt).format("MM/DD/YYYY, hh:mm A") : "",
    sortKey: "updatedAt",
    style: { minWidth: "96px" }
  }
];

export const companyTableColumns = [
  {
    label: "Name",
    getValue: (row, isLoading) => (
      <NameColumn
        isLoading={isLoading}
        avatar={row?.companyWebsite ? `https://logo.clearbit.com/${row?.companyWebsite}` : ""}
        title={row?.companyName || "- - -"}
        abbreviation={getNameAbbreviation(row?.companyName, 2)}
        companyId={row?.companyId}
        tags={row?.tags}
      />
    ),
    isComponent: true,
    style: { minWidth: "300px" }
  },
  {
    label: "Contacts",
    getValue: (row, isLoading) => (
      <ContactsColumn
        isLoading={isLoading}
        phones={row?.phones}
        website={row?.companyWebsite}
      />
    ),
    style: { minWidth: "55px", maxWidth: "55px" }
  },
  {
    label: "Address",
    getValue: row => {
      const address = [row?.address?.state, row?.address?.city, row?.address?.street]?.filter(val => !!val);
      return address?.join(", ");
    },
    style: { minWidth: "250px" }
  },
  {
    label: "Industry",
    getValue: row => (row?.industries || [])?.join(", "),
    style: { minWidth: "70px" }
  },
  {
    label: "Model",
    getValue: row => [row?.isB2B ? "B2B" : "", row?.isB2C ? "B2C" : ""]?.filter(val => !!val)?.join(", "),
    style: { minWidth: "51px" }
  },
  {
    label: "Employee",
    getValue: row => row?.numberOfEmployees?.number,
    style: { minWidth: "56px" }
  },
]
