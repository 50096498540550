import React from "react";

const ThemeDefaultIcon = () => {
  return (
    <svg width="309" height="218" viewBox="0 0 309 218" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7365_86115)">
        <rect width="309" height="216.894" transform="translate(0 0.830078)" fill="white" />
        <g filter="url(#filter0_dd_7365_86115)">
          <g clipPath="url(#clip1_7365_86115)">
            <rect x="25.425" y="31.502" width="371.394" height="196.096" rx="6.41981" fill="white" />
            <rect width="371.394" height="53.4808" transform="translate(25.425 31.502)" fill="white" />
            <rect x="41.2712" y="43.3867" width="29.7115" height="29.7115" rx="5.94231" fill="#E8EBFF" />
            <rect x="79.8962" y="51.8223" width="83.1923" height="12.8396" rx="6.41981" fill="#E8EBFF" />
            <rect x="178.935" y="51.8223" width="202.038" height="12.8396" rx="6.41981" fill="#E8EBFF" />
            <rect width="371.394" height="142.615" transform="translate(25.425 84.9824)" fill="#F3F6FF" />
            <rect x="44.6843" y="102.121" width="115.557" height="38.5189" rx="5.94231" fill="#E8EBFF" />
            <rect x="44.6843" y="153.479" width="115.557" height="38.5189" rx="5.94231" fill="#E8EBFF" />
            <rect x="173.081" y="102.121" width="115.557" height="38.5189" rx="5.94231" fill="#E8EBFF" />
            <rect x="173.081" y="102.121" width="115.557" height="89.8774" rx="5.94231" fill="#E8EBFF" />
          </g>
          <rect x="26.067" y="32.1439" width="370.11" height="194.812" rx="5.77783" stroke="#E8EBFF" strokeWidth="1.28396" />
        </g>
        <g clipPath="url(#clip2_7365_86115)">
          <g clipPath="url(#clip3_7365_86115)">
            <rect x="137.418" y="31.502" width="371.394" height="196.096" rx="6.41981" fill="#131313" />
            <rect width="371.394" height="53.4808" transform="translate(137.418 31.502)" fill="#131313" />
            <rect x="153.264" y="43.3867" width="29.7115" height="29.7115" rx="5.94231" fill="#2E2E2E" />
            <rect x="191.889" y="51.8223" width="83.1923" height="12.8396" rx="6.41981" fill="#2E2E2E" />
            <rect x="290.928" y="51.8223" width="202.038" height="12.8396" rx="6.41981" fill="#2E2E2E" />
            <rect width="371.394" height="142.615" transform="translate(137.418 84.9824)" fill="#1B1B1B" />
            <rect x="156.678" y="102.121" width="115.557" height="38.5189" rx="5.94231" fill="#2E2E2E" />
            <rect x="156.678" y="153.479" width="115.557" height="38.5189" rx="5.94231" fill="#2E2E2E" />
            <rect x="285.074" y="102.121" width="115.557" height="38.5189" rx="5.94231" fill="#2E2E2E" />
            <rect x="285.074" y="102.121" width="115.557" height="89.8774" rx="5.94231" fill="#2E2E2E" />
          </g>
          <rect x="138.06" y="32.1439" width="370.11" height="194.812" rx="5.77783" stroke="#202020" strokeWidth="1.28396" />
        </g>
      </g>
      <defs>
        <filter id="filter0_dd_7365_86115" x="-274.819" y="-171.297" width="679.545" height="504.247" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-29.2338" dy="-9.74459" />
          <feGaussianBlur stdDeviation="12.5186" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.192157 0 0 0 0 0.639216 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7365_86115" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-146.169" dy="-48.723" />
          <feGaussianBlur stdDeviation="77.0378" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.192157 0 0 0 0 0.639216 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_7365_86115" result="effect2_dropShadow_7365_86115" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_7365_86115" result="shape" />
        </filter>
        <clipPath id="clip0_7365_86115">
          <rect width="309" height="216.894" fill="white" transform="translate(0 0.830078)" />
        </clipPath>
        <clipPath id="clip1_7365_86115">
          <rect x="25.425" y="31.502" width="371.394" height="196.096" rx="6.41981" fill="white" />
        </clipPath>
        <clipPath id="clip2_7365_86115">
          <rect x="130.999" y="0.830078" width="179.755" height="218.274" rx="25.6793" fill="white" />
        </clipPath>
        <clipPath id="clip3_7365_86115">
          <rect x="137.418" y="31.502" width="371.394" height="196.096" rx="6.41981" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThemeDefaultIcon;
