import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Loader from "shared/Loader";
import NavigateBar from "shared/NavigateBar";
import DetailsDrawer from "shared/DetailsDrawer";

import "./styles.scss";

const AppWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper-page">
      {isLoading ? (
        <Loader absolute />
      ) : (
        <>
          <NavigateBar />
          <DetailsDrawer />
          <Outlet />
        </>
      )}
    </div>
  )
};

export default AppWrapper;
