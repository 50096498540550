import React from "react";

const IndustryFilterIcon = ({ color = "var(--second-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5.25H13.5" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.25 7.5H13.5" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.69727 3.75V5.25" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.5 10.5H6.75" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.5 12.75H6.75" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.125 7.5V5.85C4.125 5.51863 4.39363 5.25 4.725 5.25H6.525C6.85637 5.25 7.125 5.51863 7.125 5.85V7.5" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 9.75V8.20001C9 7.81341 8.6866 7.5 8.3 7.5H2.95C2.5634 7.50001 2.25 7.81341 2.25 8.20001V15.75" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.5 15.75H9" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 5.24996V4.31344C9 3.84449 9.2909 3.42473 9.73 3.26007L14.23 1.57261C14.5755 1.44306 14.9625 1.49115 15.2658 1.70132C15.5691 1.91148 15.75 2.257 15.75 2.62597V7.49996" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.8691 11.6484L12.7617 12.7559L14.2383 13.4941L13.1309 14.6016" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.5 9.75C15.364 9.75 16.875 11.261 16.875 13.125C16.875 14.989 15.364 16.5 13.5 16.5C11.636 16.5 10.125 14.989 10.125 13.125C10.125 11.261 11.636 9.75 13.5 9.75" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default IndustryFilterIcon;
