import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { setClientWidth } from "redux/actions/ui";
import store from "redux/store";
import {
  LOGOUT,
  APP_WRAPPER,
  SEARCH,
  CHAT,
  TAGS,
  PROFILE,
  PROFILE_SETTING
} from "pathnameVariables";

import Auth from "Auth";
import ProfileSettings from "pages/AppWrapper/ProfileSettings";
import Logout from "pages/Logout";

import AppWrapper from "pages/AppWrapper";
import Search from "pages/AppWrapper/Search";
import Chat from "pages/AppWrapper/Chat";
import Profile from "pages/AppWrapper/Profile";
import Tagged from "pages/AppWrapper/Tagged";

import "App.scss";

const App = () => {
  window.onresize = () => store.dispatch(setClientWidth(document.documentElement.clientWidth));

  useEffect(() => {
    document.body.className = localStorage.getItem("theme") || "system";
  }, []);

  return (
    <div className="app data-mind-app">
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Auth domain="id.datasys.com" audience="api.searchndeploy.com" clientId="ZNE0d2UEVYTU2KcBC80QAiO03TLxDH8F" />}
          >
            <Route path={APP_WRAPPER} element={<AppWrapper />}>
              <Route exact path={CHAT} element={<Chat />} />
              <Route exact path={`${CHAT}/:conversationId`} element={<Chat />} />
              <Route exact path={SEARCH} element={<Search />} />
              <Route exact path={TAGS} element={<Tagged />} />
              <Route exact path={PROFILE} element={<Profile />}>
                <Route exact path={PROFILE_SETTING} element={<ProfileSettings />} />
              </Route>
              <Route path={APP_WRAPPER} element={<Navigate to={SEARCH} />} />
            </Route>
            <Route path={LOGOUT} element={<Logout />} />
            <Route path="/" element={<Navigate to={APP_WRAPPER} />} />
            <Route path="*" element={<Navigate to={APP_WRAPPER} />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        draggable
        pauseOnHover
      />
    </div>
  )
};

export default App;
