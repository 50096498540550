import React from "react";
import { useSelector } from "react-redux";

import { getContactName, getNameAbbreviation } from "helpers/common";

import AvatarComponent from "shared/AvatarComponent";
import StyledTooltip from "shared/StyledTooltip";

import WorkIcon from "assets/iconComponents/WorkIcon";

import "./styles.scss";

const ContactHero = ({ isLoading }) => {
  const drawerData = useSelector(state => state.detailsDrawer);
  const name = getContactName(drawerData?.data?.contactData);

  return (
    <div className="contact-hero-wrapper">
      <AvatarComponent
        url={drawerData?.data?.contactData?.linkedinAvatar}
        shortName={getNameAbbreviation(name)}
        isLoading={isLoading}
      />
      <div className="text-box">
        {isLoading ? (
          <>
            <div className="contact-name-loader" />
            <div className="job-title-loader" />
            <div className="company-name-loader" />
          </>
        ) : (
          <>
            <StyledTooltip isOverflowContent title={name}>
              <div className="contact-name">{name}</div>
            </StyledTooltip>
            <div className="contact-job-title-wrapper">
              <WorkIcon color="var(--second-text)" />
              <StyledTooltip isOverflowContent title={drawerData?.data?.lastCompany?.jobTitle}>
                <div className="job-title">{drawerData?.data?.lastCompany?.jobTitle}</div>
              </StyledTooltip>
            </div>
            <StyledTooltip isOverflowContent title={drawerData?.data?.lastCompany?.company?.name}>
              <div className="contact-company-name">{drawerData?.data?.lastCompany?.company?.name}</div>
            </StyledTooltip>
          </>
        )}
      </div>
    </div>
  )
};

export default ContactHero;
