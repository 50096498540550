import React from "react";

const SortArrows = ({ topActive, bottomActive, ...props }) => (
  <svg {...props} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path style={{ transition: ".3s all" }} d="M8.99044 13.512C8.79028 13.7622 8.40973 13.7622 8.20957 13.512L5.24988 9.81235C4.98798 9.48497 5.22107 9 5.64032 9H11.5597C11.9789 9 12.212 9.48497 11.9501 9.81235L8.99044 13.512Z" fill={bottomActive ? "var(--main-text)" : "var(--second-text-2)"} />
    <path style={{ transition: ".3s all" }} d="M8.99044 2.48804C8.79028 2.23784 8.40973 2.23784 8.20957 2.48804L5.24988 6.18765C4.98798 6.51503 5.22107 7 5.64032 7H11.5597C11.9789 7 12.212 6.51503 11.9501 6.18765L8.99044 2.48804Z" fill={topActive ? "var(--main-text)" : "var(--second-text-2)"} />
  </svg>
);

export default SortArrows;
