import React from "react";
import "./styles.scss";

const PageContent = ({ children }) => {
  return (
    <div className="page-content-wrapper">
      <div className="page-content-scroll-box">
        {children}
      </div>
    </div>
  )
};

export default PageContent;
