import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, LinearProgress } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { AIChatService } from "services/AIChatService";
import { setRecentDialogsDrawerState, setAiThread, setRecentChatId } from "redux/actions/chat";
import { setBurgerOpen } from "redux/actions/auth";
import { getConversationsList } from "helpers/chatHelper";
import { CHAT } from "pathnameVariables";

import PageSideBar from "shared/PageSideBar";
import PageContent from "shared/PageContent";
import AnimatedListItem from "shared/AnimatedListItem";

import RecentItem from "./RecentItem";
import AIChat from "./AIChat";
import "./styles.scss";

const Chat = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { conversationId } = useParams();

  const recentDialogsDrawerState = useSelector(state => state.chat.recentDialogsDrawerState);
  const aiThread = useSelector(state => state.chat.aiThread);
  const clientWidth = useSelector(state => state.ui.clientWidth);

  const [convHistoryController, setConvHistoryController] = useState(null);
  const [sendMessageController, setSendMessageController] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isChatLoading, setIsChatLoading] = useState(!!conversationId);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [conversations, setConversations] = useState({ totalItems: 0, data: [], list: [] });
  const [conversationsList, setConversationsList] = useState([]);
  const [currentChatHistory, setCurrentChatHistory] = useState([]);

  useEffect(() => {
    setConversationsList(getConversationsList(conversations?.data, !conversationId));
    // eslint-disable-next-line
  }, [conversations?.data, conversationId]);

  const abortRequests = () => {
    if (sendMessageController) {
      sendMessageController.abort();
    }
    if (convHistoryController) {
      convHistoryController.abort();
    }
  }

  const saveRecentChat = id => {
    localStorage.setItem("recentChatId", id);
    dispatch(setRecentChatId(id));
  };

  const getConversations = async ({ nextPage, updateList }) => {
    if (nextPage) {
      setIsNextPageLoading(true);
    }
    const res = await AIChatService.getConversations({
      limit: 50,
      page: nextPage ? (conversations?.page || 1) + 1 : 1
    });
    setConversations({
      ...res,
      data: nextPage ? [...conversations.data, ...res.data] : res.data
    });
    setIsPageLoading(false);
    setIsNextPageLoading(false);
    if (updateList) {
      return res?.data?.[0];
    }
  };

  const sendMessage = async ({ str, isRefresh }) => {
    const history = [
      ...(conversationId ? currentChatHistory?.filter(val => val.content) : []),
      ...(!isRefresh || !conversationId ? [{ type: "user", content: str }] : []),
      { type: "ai", loading: true }
    ]
    setCurrentChatHistory(history);
    const controller = new AbortController();
    setSendMessageController(controller);
    try {
      const res = await AIChatService.sendMessage({
        input: str,
        ...(conversationId ? { conversationId } : {})
      }, controller.signal);
      setCurrentChatHistory([...history?.filter(val => val.content), { type: "ai", content: res }]);
      if (!conversationId && location.pathname === CHAT) {
        const newConv = await getConversations({ updateList: true });
        setConversationsList(conversationsList?.map(val => val?.id === "new" ? newConv : val));
        saveRecentChat(newConv.id)
        navigate(`${CHAT}/${newConv.id}`);
      }
    } catch (e) {
      if (e?.code !== "ERR_CANCELED") {
        setCurrentChatHistory([
          ...history?.filter(val => val.content),
          { type: "ai", error: "Something went wrong..." }
        ]);
      }
    } finally {
      dispatch(setAiThread(null));
    }
  };

  const getConversationHistory = async (convId) => {
    const id = convId || conversationId;
    setIsChatLoading(true);

    abortRequests();

    const controller = new AbortController();
    setConvHistoryController(controller);

    try {
      const res = await AIChatService.getConversationHistory(id, { limit: 100 }, controller.signal);
      setCurrentChatHistory(res?.data);
      setIsChatLoading(false);
    } catch (e) {
      setCurrentChatHistory([]);
      setIsChatLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getConversations({});
    if (conversationId) {
      getConversationHistory();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!conversationId && !aiThread) {
      setCurrentChatHistory([]);
    }
    // eslint-disable-next-line
  }, [conversationId]);

  useEffect(() => {
    if (aiThread) {
      sendMessage({ str: aiThread });
    }
    // eslint-disable-next-line
  }, [aiThread]);

  useEffect(() => {
    if (location?.state?.newChat) {
      setCurrentChatHistory([]);
      navigate(CHAT);
      abortRequests();
    }
    if (location?.state?.recentChat) {
      navigate(location.pathname);
      getConversationHistory();
    }
    // eslint-disable-next-line
  }, [location]);

  const handleRemoveChat = async chatId => {
    try {
      await AIChatService.removeConversation(chatId);
      setConversations({
        ...conversations,
        totalItems: conversations?.totalItems - 1,
        data: conversations.data?.filter(val => val.id !== chatId)
      });
      if (chatId === conversationId) {
        navigate(CHAT);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRenameChat = async (chatId, name) => {
    try {
      await AIChatService.renameConversation(chatId, name);
      setConversations({
        ...conversations,
        data: conversations.data?.map(val => val.id === chatId ? ({ ...val, name }) : val)
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenSIdebar = () => {
    setTimeout(() => {
      dispatch(setRecentDialogsDrawerState(true));
    }, 50);
  };

  const handleCloseSIdebar = () => {
    dispatch(setRecentDialogsDrawerState(false));
  }

  return (
    <div className="chat-page-wrapper">
      <PageSideBar
        title="Recent"
        isOpen={recentDialogsDrawerState}
        onOpen={handleOpenSIdebar}
        onClose={handleCloseSIdebar}
      >
        <div className="recent-list">
          {isPageLoading ? (
            <>
              <div className="list-label-loader" />
              {new Array(4).fill(0).map((_, i) => (
                <RecentItem key={i} isLoading={isPageLoading} />
              ))}
            </>
          ) : (
            <>
              {conversationsList?.map((val, i) => (
                <AnimatedListItem key={typeof val === "string" ? val : val.id} index={i} totalLength={conversationsList?.length}>
                  {typeof val === "string" ? (
                    <div className="list-label">{val}</div>
                  ) : (
                    <RecentItem
                      label={val.name}
                      isActive={conversationId ? conversationId === val.id : val.id === "new"}
                      onClick={() => {
                        if (val.id !== "new") {
                          navigate(`${CHAT}/${val.id}`);
                          saveRecentChat(val.id);
                          getConversationHistory(val.id);
                          if (clientWidth < 1060) {
                            dispatch(setRecentDialogsDrawerState(false));
                            dispatch(setBurgerOpen(false));
                          }
                        }
                      }}
                      onRemove={val.id !== "new" ? () => handleRemoveChat(val.id) : null}
                      onEdit={val.id !== "new" ? name => handleRenameChat(val.id, name) : null}
                    />
                  )}
                </AnimatedListItem>
              ))}
              {conversations?.totalItems > conversations?.data?.length && (
                <Button
                  className={`load-more-btn ${isNextPageLoading ? "loading" : ""}`}
                  onClick={() => getConversations({ nextPage: true })}
                >
                  {isNextPageLoading ? <LinearProgress /> : "Load more"}
                </Button>
              )}
            </>
          )}
        </div>
      </PageSideBar>
      <PageContent>
        <AIChat
          onSubmit={sendMessage}
          currentChatHistory={currentChatHistory}
          isLoading={isChatLoading}
        />
      </PageContent>
    </div>
  )
};

export default Chat;
