import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { downloadFile } from "helpers/common";
import { searchTypes } from "utils/searchFilters";
import { setDrawerState } from "redux/actions/detailsDrawer";
import { SearchProfileDetails } from "services/SearchDetails";
import { AdvancedSearchService } from "services/AdvancedSearchService";

import SimpleSearchItem from "shared/SimpleSearchItem";
import TagsManagerPopover from "shared/TagsManagerPopover";
import DetailsSectionTitle from "shared/DetailsSectionTitle";

import TagIcon from "assets/iconComponents/TagIcon";
import WorkIcon from "assets/iconComponents/WorkIcon";
import ExportIcon from "assets/iconComponents/ExportIcon";

import "./styles.scss"

const Employee = ({ isLoading }) => {
  const dispatch = useDispatch();
  const drawerData = useSelector(state => state.detailsDrawer);
  const [dataLoading, setDataLoading] = useState(true);
  const [openTagPopover, setOpenTagPopover] = useState(null);
  const [activeItemId, setActiveItemId] = useState(null);

  const handleGetEmployee = (page = 1, name) => {
    setDataLoading(true);
    SearchProfileDetails.getCompanyEmployee(drawerData?.id, page, name)
      .then(data => {
        dispatch(setDrawerState({
          data: {
            ...drawerData.data,
            employeeData: data?.data?.map(val => ({
              ...val,
              companyId: drawerData.data?.companyId,
              companyName: drawerData.data?.companyName
            }))
          }
        }))
      })
      .catch(e => console.log(e))
      .finally(() => setDataLoading(false));
  };

  useEffect(() => {
    if (drawerData?.open && drawerData?.id && drawerData?.mainRequestFinished) {
      handleGetEmployee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerData?.open, drawerData?.id, drawerData?.mainRequestFinished]);

  const handleExport = async (item) => {
    try {
      const name = `${item?.prefixName ? `${item?.prefixName} ` : ""}${item?.firstName || ""}${item?.middleName ? ` ${item?.middleName} ` : " "}${drawerData?.data?.contactData?.lastName || ""}${item?.suffixName ? ` ${item?.suffixName}` : ""}`
      const res = await AdvancedSearchService.exportCsv("contacts", [item.contactId]);
      await downloadFile(res, name);
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <div className="employee-wrapper">
      <DetailsSectionTitle label="Company employees" />
      <div className="list-wrapper">
        {(isLoading || dataLoading) ? (
          [0, 1, 2, 3, 4, 5, 6, 7, 8].map(it => <div key={`item-loader-${it}`} className="item-loader"></div>)
        ) : drawerData?.data?.employeeData?.length ? drawerData?.data?.employeeData?.map(val => (
          <SimpleSearchItem
            avatar={val?.avatar}
            key={val?.contactId}
            name={`${val.firstName || ""} ${val.lastName || ""}`}
            icon={<WorkIcon />}
            subtitle={val?.jobTitle || "Job title unknown"}
            onTitleClick={() => dispatch(setDrawerState({ data: {}, id: val?.contactId, mainRequestFinished: false, open: true, isCompany: false }))}
            popoverOptions={[{
              label: "Export",
              value: "export",
              icon: <ExportIcon color="var(--main-text)" />,
              action: () => handleExport(val)
            },
            {
              label: `Tag Contact`,
              value: "tag",
              icon: <TagIcon />,
              action: (buttonRef) => {
                setActiveItemId(val?.contactId);
                setOpenTagPopover(buttonRef.current)
              }
            }]}
          />
        )) : <div className="empty-text">No employee data...</div>}
      </div>
      <TagsManagerPopover
        state={openTagPopover}
        setState={setOpenTagPopover}
        tagType={searchTypes.contact}
        itemId={activeItemId}
      />
    </div>
  )
}

export default Employee;