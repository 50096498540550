import React from "react";

const CircleLogo = (props) => (
  <svg {...props} width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7370_86238)">
      <rect x="0.00170898" y="0.47699" width="41.9965" height="41.9965" rx="20.9983" fill="var(--primary-color)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M-14.2839 -5.80158C-1.56019 4.92303 3.94152 13.3601 3.65504 21.7228C3.36685 30.1356 -2.77508 38.8234 -14.4082 50.0397L-13.2511 51.2397C-1.63636 40.0412 5.00757 30.9318 5.32108 21.7799C5.6363 12.5781 -0.444446 3.68327 -13.2095 -7.07621L-14.2839 -5.80158ZM14.2885 21.5487C14.4694 14.0562 11.36 6.32758 4.26459 -2.50073L5.56396 -3.54504C12.8002 5.4585 16.1488 13.5619 15.955 21.5889C15.7615 29.6049 12.0383 37.3805 5.48712 45.7373L4.17518 44.7089C10.6232 36.4837 14.1074 29.0523 14.2885 21.5487ZM19.1718 0.32409C22.4465 8.07583 24.3275 14.4688 24.4198 20.9871C24.5121 27.4961 22.8212 34.2092 18.816 42.5913L20.3202 43.3101C24.3975 34.7769 26.1837 27.8056 26.0867 20.9634C25.9899 14.1305 24.0153 7.50567 20.7074 -0.32463L19.1718 0.32409ZM30.8994 0.549482C37.3479 15.9051 36.2431 28.5811 30.7275 42.3295L32.2746 42.9502C37.9243 28.8677 39.0829 15.731 32.4364 -0.0959754L30.8994 0.549482Z" fill="var(--primary-inner-color)"/>
    </g>
    <defs>
      <clipPath id="clip0_7370_86238">
        <rect x="0.00170898" y="0.47699" width="41.9965" height="41.9965" rx="20.9983" fill="var(--primary-inner-color)"/>
      </clipPath>
    </defs>
  </svg>
);

export default CircleLogo
