import ContactIcon from "assets/iconComponents/ContactIcon";
import CompanyIcon from "assets/iconComponents/CompanyIcon";

export const searchTypes = {
  contact: "contact",
  company: "company"
};

export const searchTypesOptions = [
  { label: "Contact", value: searchTypes.contact, getIcon: color => <ContactIcon color={color} /> },
  { label: "Business", value: searchTypes.company, getIcon: color => <CompanyIcon color={color} /> }
];

export const classificationCodeOptions = [
  { value: "naics", label: "NAICS" },
  { value: "sic", label: "SIC" }
];

export const businessModelOptions = [
  { value: "B2B", label: "B2B" },
  { value: "B2C", label: "B2C" }
];

export const industryTypeOptions = [
  { value: "any", label: "Any" },
  { value: "primary", label: "Primary" }
];

export const locationOptions = [
  {
    label: "Canada",
    value: "CA",
    children: [
      { label: "Alberta", value: "AB" },
      { label: "British Columbia", value: "BC" },
      { label: "Manitoba", value: "MB" },
      { label: "New Brunswick", value: "NB" },
      { label: "Newfoundland and Labrador", value: "NL" },
      { label: "Northwest Territories", value: "NT" },
      { label: "Nova Scotia", value: "NS" },
      { label: "Nunavut", value: "NU" },
      { label: "Ontario", value: "ON" },
      { label: "Prince Edward Island", value: "PE" },
      { label: "Quebec", value: "QC" },
      { label: "Saskatchewan", value: "SK" },
      { label: "Yukon", value: "YT" }
    ]
  },
  {
    label: "United States",
    value: "US",
    children: [
      { label: "Alabama", value: "AL" },
      { label: "Alaska", value: "AK" },
      { label: "American Samoa", value: "AS" },
      { label: "Arizona", value: "AZ" },
      { label: "Arkansas", value: "AR" },
      { label: "California", value: "CA" },
      { label: "Colorado", value: "CO" },
      { label: "Connecticut", value: "CT" },
      { label: "Delaware", value: "DE" },
      { label: "District of Columbia", value: "DC" },
      { label: "Micronesia", value: "FM" },
      { label: "Florida", value: "FL" },
      { label: "Georgia", value: "GA" },
      { label: "Guam", value: "GU" },
      { label: "Hawaii", value: "HI" },
      { label: "Idaho", value: "ID" },
      { label: "Illinois", value: "IL" },
      { label: "Indiana", value: "IN" },
      { label: "Iowa", value: "IA" },
      { label: "Kansas", value: "KS" },
      { label: "Kentucky", value: "KY" },
      { label: "Louisiana", value: "LA" },
      { label: "Maine", value: "ME" },
      { label: "Marshall Islands", value: "MH" },
      { label: "Maryland", value: "MD" },
      { label: "Massachusetts", value: "MA" },
      { label: "Michigan", value: "MI" },
      { label: "Minnesota", value: "MN" },
      { label: "Mississippi", value: "MS" },
      { label: "Missouri", value: "MO" },
      { label: "Montana", value: "MT" },
      { label: "Nebraska", value: "NE" },
      { label: "Nevada", value: "NV" },
      { label: "New Hampshire", value: "NH" },
      { label: "New Jersey", value: "NJ" },
      { label: "New Mexico", value: "NM" },
      { label: "New York", value: "NY" },
      { label: "North Carolina", value: "NC" },
      { label: "North Dakota", value: "ND" },
      { label: "Northern Mariana Islands", value: "MP" },
      { label: "Ohio", value: "OH" },
      { label: "Oklahoma", value: "OK" },
      { label: "Oregon", value: "OR" },
      { label: "Palau", value: "PW" },
      { label: "Pennsylvania", value: "PA" },
      { label: "Puerto Rico", value: "PR" },
      { label: "Rhode Island", value: "RI" },
      { label: "South Carolina", value: "SC" },
      { label: "South Dakota", value: "SD" },
      { label: "Tennessee", value: "TN" },
      { label: "Texas", value: "TX" },
      { label: "Utah", value: "UT" },
      { label: "Vermont", value: "VT" },
      { label: "Virgin Islands", value: "VI" },
      { label: "Virginia", value: "VA" },
      { label: "Washington", value: "WA" },
      { label: "West Virginia", value: "WV" },
      { label: "Wisconsin", value: "WI" },
      { label: "Wyoming", value: "WY" },
      { label: "Armed Forces Americas", value: "AA" },
      { label: "Armed Forces Europe, Canada, Africa and Middle East", value: "AE" },
      { label: "Armed Forces Pacific", value: "AP" }
    ]
  }
];

export const initialSearchRequestData = {
  searchType: searchTypesOptions[0].value,
  sortDir: "desc", // asc, desc
  sortBy: "updatedAt", // updatedAt, createdAt
  filter: {
    contact: {
      isCurrentEmployment: true,
      contactNameOrEmail: "",
      jobTitle: null,
      departments: [],
      managementLevels: [],
      tags: []
    },
    company: {
      companyName: "",
      industryType: "any",
      industryKeywords: [],
      companyKeywords: [],
      industryCodeType: classificationCodeOptions[0].value,
      industryCode: "",
      employeeCount: { min: 0, max: 0 },
      businessModels: [],
      industries: [],
      tags: []
    },
    location: {
      locationString: "",
      districts: [],
      postalCode: "",
      radius: null,
      streetAddress: "",
      suite: ""
    }
  }
};
