import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import Loader from "shared/Loader";

import { authorizeAsync } from "redux/actions/auth";
import { getMeSelector } from "redux/selectors/auth";

import "./styles.scss";

const Auth = ({ domain, clientId, audience, me, authorizeActionAsync }) => {
  useEffect(() => {
    authorizeActionAsync(domain, clientId, audience);
  }, [audience, authorizeActionAsync, clientId, domain]);

  return me.isAuthLoading ? (
    <div className="auth-guard-container">
      <Loader size={100} />
    </div>
  ) : (
    <Outlet />
  );
};

const mapStateToProps = createStructuredSelector({
  me: getMeSelector
});

const mapDispatchToProps = dispatch => ({
  authorizeActionAsync: (domain, clientId, audience) => {
    dispatch(
      authorizeAsync.request({
        domain,
        clientId,
        audience
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
