import { DataSysAuth0Client } from "security/DataSysAuth0Client";
import { identity, pickBy } from "lodash";
import { HttpService } from "./HttpService";
import config from "../config";

export class ApiService {
  static async _requestAsync({
    url,
    method = "GET",
    data = {},
    headers = {},
    responseType = "json",
    params,
    signal
  }) {
    const accessToken = await DataSysAuth0Client.getTokenSilentlyAsync();

    return HttpService.requestAsync({
      url: config.api.endpoint(url),
      method,
      data,
      responseType,
      signal,
      params: {
        ...(pickBy(params, x => identity(x)) || {})
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        ...headers
      }
    });
  }
}

