import { applyMiddleware, combineReducers, createStore } from "redux";
import { reducer as formReducer } from "redux-form";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "@redux-devtools/extension";

import filters from "redux/reducers/filters";
import chat from "redux/reducers/chat";
import detailsDrawerReducer from "redux/reducers/detailsDrawer";
import auth from "redux/reducers/auth";
import ui from "redux/reducers/ui";

import rootSagas from "redux/sagas/index";

const sagaMiddleware = createSagaMiddleware();

const combinedReducers = combineReducers({
  ui,
  chat,
  filters,
  form: formReducer,
  me: auth,
  detailsDrawer: detailsDrawerReducer
});

const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSagas);

export default store;
