import { useState, useEffect } from "react";

export const useAnimateItemHook = (delay, translate = 20) => {
  const [styles, setStyles] = useState({
    opacity: 0,
    transform: `translateY(${translate}px)`,
    transition: "opacity .3s, transform .3s"
  });

  useEffect(() => {
    setTimeout(() => {
      setStyles({
        ...styles,
        opacity: 1,
        transform: "translateY(0)"
      })
    }, delay);
    // eslint-disable-next-line
  }, []);

  return [styles];
}
